@charset "UTF-8";@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Poppins:100,200,300,400,500,600,700,800,900&display=swap";/*!* Bootstrap v4.4.1 (https://getbootstrap.com/)
* Copyright 2011-2019 The Bootstrap Authors
* Copyright 2011-2019 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)*/
:root {
    --blue:#2c7be5;--indigo:#727cf5;--purple:#6b5eae;--pink:#ff679b;--red:#e63757;--orange:#fd7e14;--yellow:#f5803e;--green:#00d27a;--teal:#02a8b5;--cyan:#27bcfd;--white:#fff;--gray:#748194;--gray-dark:#4d5969;--primary:#2c7be5;--secondary:#748194;--success:#00d27a;--info:#27bcfd;--warning:#f5803e;--danger:#e63757;--light:#f9fafd;--dark:#0b1727;--breakpoint-xs:0;--breakpoint-sm:576px;--breakpoint-md:768px;--breakpoint-lg:992px;--breakpoint-xl:1200px;--breakpoint-xxl:1540px;--font-family-sans-serif:"Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";--font-family-monospace:"SFMono-Regular",Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace}

*,::after,::before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

article,aside,figcaption,figure,footer,header,hgroup,main,nav,section {
    display: block
}

body {
    margin: 0;
    font-family: open sans,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5e6e82;
    text-align: left;
    background-color: #edf2f9!important;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0!important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,ol,ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,ol ul,ul ol,ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #2c7be5;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #1657af;
    text-decoration: underline
}

a:not([href]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none
}

code,kbd,pre,samp {
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,liberation mono,courier new,monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg {
    overflow: hidden;
    vertical-align: middle
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #9da9bb;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
    box-shadow: 0 0 0 0.2rem rgb(44 123 229 / 25%)!important;
}

button,input,optgroup,select,textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,input {
    overflow: visible
}

button,select {
    text-transform: none
}

select {
    word-wrap: normal
}

[type=button],[type=reset],[type=submit],button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled),button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],input[type=datetime-local],input[type=month],input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none!important
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    margin-bottom: .5rem;
    font-family: Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
    font-weight: 500;
    line-height: 1.2;
    color: #344050
}

.h1,h1 {
    font-size: 2.48832rem
}

.h2,h2 {
    font-size: 2.0736rem
}

.h3,h3 {
    font-size: 1.428rem
}

.h4,h4 {
    font-size: 1.44rem
}

.h5,h5 {
    font-size: 1.2rem
}

.h6,h6 {
    font-size: .83333rem
}

.lead {
    font-size: 1.2rem;
    font-weight: 400
}

.display-1 {
    font-size: 4.29982rem;
    font-weight: 900;
    line-height: 1
}

.display-2 {
    font-size: 3.58318rem;
    font-weight: 900;
    line-height: 1
}

.display-3 {
    font-size: 2.98598rem;
    font-weight: 900;
    line-height: 1
}

.display-4 {
    font-size: 2.48832rem;
    font-weight: 900;
    line-height: 1
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1)
}

.small,small {
    font-size: 75%;
    font-weight: 400
}

.mark,mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.2rem
}

.blockquote-footer {
    display: block;
    font-size: 75%;
    color: #9da9bb
}

.blockquote-footer::before {
    content: "\2014\00A0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 3px solid #fff;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #748194
}

code {
    font-size: 87.5%;
    color: #ff679b;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #344050;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700
}

pre {
    display: block;
    font-size: 87.5%;
    color: #344050
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media(min-width: 576px) {
    .container {
        max-width:540px
    }
}

@media(min-width: 768px) {
    .container {
        max-width:720px
    }
}

@media(min-width: 992px) {
    .container {
        max-width:960px
    }
}

@media(min-width: 1200px) {
    .container {
        max-width:1140px
    }
}

@media(min-width: 1540px) {
    .container {
        max-width:1480px
    }
}

.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media(min-width: 576px) {
    .container,.container-sm {
        max-width:540px
    }
}

@media(min-width: 768px) {
    .container,.container-md,.container-sm {
        max-width:720px
    }
}

@media(min-width: 992px) {
    .container,.container-lg,.container-md,.container-sm {
        max-width:960px
    }
}

@media(min-width: 1200px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl {
        max-width:1140px
    }
}

@media(min-width: 1540px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
        max-width:1480px
    }
}

.row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto,.col-xxl,.col-xxl-1,.col-xxl-10,.col-xxl-11,.col-xxl-12,.col-xxl-2,.col-xxl-3,.col-xxl-4,.col-xxl-5,.col-xxl-6,.col-xxl-7,.col-xxl-8,.col-xxl-9,.col-xxl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%
}

.row-cols-1>* {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%
}

.row-cols-2>* {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%
}

.row-cols-3>* {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.row-cols-4>* {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%
}

.row-cols-5>* {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%
}

.row-cols-6>* {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    -webkit-box-ordinal-group: 0;
    order: -1
}

.order-last {
    -webkit-box-ordinal-group: 14;
    order: 13
}

.order-0 {
    -webkit-box-ordinal-group: 1;
    order: 0
}

.order-1 {
    -webkit-box-ordinal-group: 2;
    order: 1
}

.order-2 {
    -webkit-box-ordinal-group: 3;
    order: 2
}

.order-3 {
    -webkit-box-ordinal-group: 4;
    order: 3
}

.order-4 {
    -webkit-box-ordinal-group: 5;
    order: 4
}

.order-5 {
    -webkit-box-ordinal-group: 6;
    order: 5
}

.order-6 {
    -webkit-box-ordinal-group: 7;
    order: 6
}

.order-7 {
    -webkit-box-ordinal-group: 8;
    order: 7
}

.order-8 {
    -webkit-box-ordinal-group: 9;
    order: 8
}

.order-9 {
    -webkit-box-ordinal-group: 10;
    order: 9
}

.order-10 {
    -webkit-box-ordinal-group: 11;
    order: 10
}

.order-11 {
    -webkit-box-ordinal-group: 12;
    order: 11
}

.order-12 {
    -webkit-box-ordinal-group: 13;
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media(min-width: 576px) {
    .col-sm {
        flex-basis:0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-sm-1>* {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-sm-2>* {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-sm-3>* {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-sm-4>* {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-sm-5>* {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-sm-6>* {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0;
        order: -1
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 14;
        order: 13
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        order: 1
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        order: 2
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        order: 3
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        order: 4
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        order: 5
    }

    .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        order: 6
    }

    .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        order: 7
    }

    .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        order: 8
    }

    .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        order: 9
    }

    .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        order: 10
    }

    .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        order: 11
    }

    .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media(min-width: 768px) {
    .col-md {
        flex-basis:0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-md-1>* {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-md-2>* {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-md-3>* {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-md-4>* {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-md-5>* {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-md-6>* {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0;
        order: -1
    }

    .order-md-last {
        -webkit-box-ordinal-group: 14;
        order: 13
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        order: 1
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3;
        order: 2
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4;
        order: 3
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5;
        order: 4
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6;
        order: 5
    }

    .order-md-6 {
        -webkit-box-ordinal-group: 7;
        order: 6
    }

    .order-md-7 {
        -webkit-box-ordinal-group: 8;
        order: 7
    }

    .order-md-8 {
        -webkit-box-ordinal-group: 9;
        order: 8
    }

    .order-md-9 {
        -webkit-box-ordinal-group: 10;
        order: 9
    }

    .order-md-10 {
        -webkit-box-ordinal-group: 11;
        order: 10
    }

    .order-md-11 {
        -webkit-box-ordinal-group: 12;
        order: 11
    }

    .order-md-12 {
        -webkit-box-ordinal-group: 13;
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media(min-width: 992px) {
    .col-lg {
        flex-basis:0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-lg-1>* {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-lg-2>* {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-lg-3>* {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-lg-4>* {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-lg-5>* {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-lg-6>* {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0;
        order: -1
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 14;
        order: 13
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        order: 1
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        order: 2
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        order: 3
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        order: 4
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        order: 5
    }

    .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        order: 6
    }

    .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        order: 7
    }

    .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        order: 8
    }

    .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        order: 9
    }

    .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        order: 10
    }

    .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        order: 11
    }

    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media(min-width: 1200px) {
    .col-xl {
        flex-basis:0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-xl-1>* {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xl-2>* {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xl-3>* {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-xl-4>* {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xl-5>* {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xl-6>* {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0;
        order: -1
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 14;
        order: 13
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        order: 1
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        order: 2
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        order: 3
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        order: 4
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        order: 5
    }

    .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        order: 6
    }

    .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        order: 7
    }

    .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        order: 8
    }

    .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        order: 9
    }

    .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        order: 10
    }

    .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        order: 11
    }

    .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

@media(min-width: 1540px) {
    .col-xxl {
        flex-basis:0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-xxl-1>* {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xxl-2>* {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xxl-3>* {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-xxl-4>* {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xxl-5>* {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xxl-6>* {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xxl-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xxl-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xxl-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xxl-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xxl-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xxl-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xxl-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xxl-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xxl-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xxl-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xxl-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xxl-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xxl-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xxl-first {
        -webkit-box-ordinal-group: 0;
        order: -1
    }

    .order-xxl-last {
        -webkit-box-ordinal-group: 14;
        order: 13
    }

    .order-xxl-0 {
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .order-xxl-1 {
        -webkit-box-ordinal-group: 2;
        order: 1
    }

    .order-xxl-2 {
        -webkit-box-ordinal-group: 3;
        order: 2
    }

    .order-xxl-3 {
        -webkit-box-ordinal-group: 4;
        order: 3
    }

    .order-xxl-4 {
        -webkit-box-ordinal-group: 5;
        order: 4
    }

    .order-xxl-5 {
        -webkit-box-ordinal-group: 6;
        order: 5
    }

    .order-xxl-6 {
        -webkit-box-ordinal-group: 7;
        order: 6
    }

    .order-xxl-7 {
        -webkit-box-ordinal-group: 8;
        order: 7
    }

    .order-xxl-8 {
        -webkit-box-ordinal-group: 9;
        order: 8
    }

    .order-xxl-9 {
        -webkit-box-ordinal-group: 10;
        order: 9
    }

    .order-xxl-10 {
        -webkit-box-ordinal-group: 11;
        order: 10
    }

    .order-xxl-11 {
        -webkit-box-ordinal-group: 12;
        order: 11
    }

    .order-xxl-12 {
        -webkit-box-ordinal-group: 13;
        order: 12
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333%
    }

    .offset-xxl-2 {
        margin-left: 16.66667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333%
    }

    .offset-xxl-5 {
        margin-left: 41.66667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333%
    }

    .offset-xxl-8 {
        margin-left: 66.66667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333%
    }

    .offset-xxl-11 {
        margin-left: 91.66667%
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #5e6e82
}

.table td,.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #edf2f9
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #edf2f9
}

.table tbody+tbody {
    border-top: 2px solid #edf2f9
}

.table-sm td,.table-sm th {
    padding: .3rem
}

.table-bordered {
    border: 1px solid #edf2f9
}

.table-bordered td,.table-bordered th {
    border: 1px solid #edf2f9
}

.table-bordered thead td,.table-bordered thead th {
    border-bottom-width: 2px
}

.table-borderless tbody+tbody,.table-borderless td,.table-borderless th,.table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #f9fafd
}

.table-hover tbody tr:hover {
    color: #5e6e82;
    background-color: rgba(0,0,0,.075)
}

.table-primary,.table-primary>td,.table-primary>th {
    background-color: #c4daf8
}

.table-primary tbody+tbody,.table-primary td,.table-primary th,.table-primary thead th {
    border-color: #91baf1
}

.table-hover .table-primary:hover {
    background-color: #adccf5
}

.table-hover .table-primary:hover>td,.table-hover .table-primary:hover>th {
    background-color: #adccf5
}

.table-secondary,.table-secondary>td,.table-secondary>th {
    background-color: #d8dce1
}

.table-secondary tbody+tbody,.table-secondary td,.table-secondary th,.table-secondary thead th {
    border-color: #b7bdc7
}

.table-hover .table-secondary:hover {
    background-color: #cacfd6
}

.table-hover .table-secondary:hover>td,.table-hover .table-secondary:hover>th {
    background-color: #cacfd6
}

.table-success,.table-success>td,.table-success>th {
    background-color: #b8f2da
}

.table-success tbody+tbody,.table-success td,.table-success th,.table-success thead th {
    border-color: #7ae8ba
}

.table-hover .table-success:hover {
    background-color: #a2eecf
}

.table-hover .table-success:hover>td,.table-hover .table-success:hover>th {
    background-color: #a2eecf
}

.table-info,.table-info>td,.table-info>th {
    background-color: #c3ecfe
}

.table-info tbody+tbody,.table-info td,.table-info th,.table-info thead th {
    border-color: #8fdcfe
}

.table-hover .table-info:hover {
    background-color: #aae4fe
}

.table-hover .table-info:hover>td,.table-hover .table-info:hover>th {
    background-color: #aae4fe
}

.table-warning,.table-warning>td,.table-warning>th {
    background-color: #fcdbc9
}

.table-warning tbody+tbody,.table-warning td,.table-warning th,.table-warning thead th {
    border-color: #fabd9b
}

.table-hover .table-warning:hover {
    background-color: #fbcbb1
}

.table-hover .table-warning:hover>td,.table-hover .table-warning:hover>th {
    background-color: #fbcbb1
}

.table-danger,.table-danger>td,.table-danger>th {
    background-color: #f8c7d0
}

.table-danger tbody+tbody,.table-danger td,.table-danger th,.table-danger thead th {
    border-color: #f297a8
}

.table-hover .table-danger:hover {
    background-color: #f5b0bd
}

.table-hover .table-danger:hover>td,.table-hover .table-danger:hover>th {
    background-color: #f5b0bd
}

.table-light,.table-light>td,.table-light>th {
    background-color: #fdfefe
}

.table-light tbody+tbody,.table-light td,.table-light th,.table-light thead th {
    border-color: #fcfcfe
}

.table-hover .table-light:hover {
    background-color: #ecf6f6
}

.table-hover .table-light:hover>td,.table-hover .table-light:hover>th {
    background-color: #ecf6f6
}

.table-dark,.table-dark>td,.table-dark>th {
    background-color: #bbbec3
}

.table-dark tbody+tbody,.table-dark td,.table-dark th,.table-dark thead th {
    border-color: #80868f
}

.table-hover .table-dark:hover {
    background-color: #adb1b7
}

.table-hover .table-dark:hover>td,.table-hover .table-dark:hover>th {
    background-color: #adb1b7
}

.table-active,.table-active>td,.table-active>th {
    background-color: rgba(0,0,0,.075)
}

.table-hover .table-active:hover {
    background-color: rgba(0,0,0,.075)
}

.table-hover .table-active:hover>td,.table-hover .table-active:hover>th {
    background-color: rgba(0,0,0,.075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #232e3c;
    border-color: #314154
}

.table .thead-light th {
    color: #0b1727;
    background-color: #edf2f9;
    border-color: #edf2f9
}

.table-dark {
    color: #fff;
    background-color: #232e3c
}

.table-dark td,.table-dark th,.table-dark thead th {
    border-color: #314154
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(255,255,255,.05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255,255,255,.075)
}

@media(max-width: 575.98px) {
    .table-responsive-sm {
        display:block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media(max-width: 767.98px) {
    .table-responsive-md {
        display:block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media(max-width: 991.98px) {
    .table-responsive-lg {
        display:block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media(max-width: 1199.98px) {
    .table-responsive-xl {
        display:block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

@media(max-width: 1539.98px) {
    .table-responsive-xxl {
        display:block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xxl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .625rem + 2px);
    padding: .3125rem 1rem;
    font-size: 13.5px;
    font-weight: 400;
    line-height: 1.5;
    color: #344050;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8e2ef;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #344050
}

.form-control:focus {
    color: #344050;
    background-color: #fff;
    border-color: #9ec2f3;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(44,123,229,.25)
}

.form-control::-webkit-input-placeholder {
    color: #748194;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #748194;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #748194;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #748194;
    opacity: 1
}

.form-control::placeholder {
    color: #748194;
    opacity: 1
}

.form-control:disabled,.form-control[readonly], .form-control.d-table:disabled >div>div[class$=control]{
    /* background-color: #edf2f9; */
    background-color: hsl(0,0%,95%);
    border-color: hsl(0,0%,90%);
    color: #344050;
    opacity: 1
}
.form-control.d-table >div>div div[class$=singleValue] {color: #344050;}

select.form-control:focus::-ms-value {
    color: #344050;
    background-color: #fff
}

.form-control-file,.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.3125rem + 1px);
    padding-bottom: calc(.3125rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}
.small-label label {font-size: 12px;}

.col-form-label-lg {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.1875rem + 1px);
    padding-bottom: calc(.1875rem + 1px);
    font-size: .875rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .3125rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #5e6e82;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.5em + .375rem + 2px);
    padding: .1875rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.form-control-lg {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.25rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

select.form-control[multiple],select.form-control[size] {
    height: auto
}

textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .34375rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label,.form-check-input[disabled]~.form-check-label {
    color: #9da9bb
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 75%;
    color: #00d27a
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .83333rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(0,210,122,.9);
    border-radius: .25rem
}

.is-valid~.valid-feedback,.is-valid~.valid-tooltip,.was-validated :valid~.valid-feedback,.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,.was-validated .form-control:valid {
    border-color: #00d27a;
    padding-right: calc(1.5em + .625rem);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMwMGQyN2EnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .15625rem) center;
    background-size: calc(.75em + .3125rem) calc(.75em + .3125rem)
}

.form-control.is-valid:focus,.was-validated .form-control:valid:focus {
    border-color: #00d27a;
    box-shadow: 0 0 0 .2rem rgba(0,210,122,.25)
}

.was-validated textarea.form-control:valid,textarea.form-control.is-valid {
    padding-right: calc(1.5em + .625rem);
    background-position: top calc(.375em + .15625rem) right calc(.375em + .15625rem)
}

.custom-select.is-valid,.was-validated .custom-select:valid {
    border-color: #00d27a;
    padding-right: calc(.75em + 2.46875rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234d5969' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300d27a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(.75em + .3125rem) calc(.75em + .3125rem)
}

.custom-select.is-valid:focus,.was-validated .custom-select:valid:focus {
    border-color: #00d27a;
    box-shadow: 0 0 0 .2rem rgba(0,210,122,.25)
}

.form-check-input.is-valid~.form-check-label,.was-validated .form-check-input:valid~.form-check-label {
    color: #00d27a
}

.form-check-input.is-valid~.valid-feedback,.form-check-input.is-valid~.valid-tooltip,.was-validated .form-check-input:valid~.valid-feedback,.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,.was-validated .custom-control-input:valid~.custom-control-label {
    color: #00d27a
}

.custom-control-input.is-valid~.custom-control-label::before,.was-validated .custom-control-input:valid~.custom-control-label::before {
    border-color: #00d27a
}

.custom-control-input.is-valid:checked~.custom-control-label::before,.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #06ff97;
    background-color: #06ff97
}

.custom-control-input.is-valid:focus~.custom-control-label::before,.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(0,210,122,.25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #00d27a
}

.custom-file-input.is-valid~.custom-file-label,.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #00d27a
}

.custom-file-input.is-valid:focus~.custom-file-label,.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #00d27a;
    box-shadow: 0 0 0 .2rem rgba(0,210,122,.25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 75%;
    color: #e63757
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .83333rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(230,55,87,.9);
    border-radius: .25rem
}

.is-invalid~.invalid-feedback,.is-invalid~.invalid-tooltip,.was-validated :invalid~.invalid-feedback,.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,.was-validated .form-control:invalid {
    border-color: #e63757;
    padding-right: calc(1.5em + .625rem);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgZmlsbD0nbm9uZScgc3Ryb2tlPScjZTYzNzU3JyB2aWV3Qm94PScwIDAgMTIgMTInPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2U2Mzc1Nycgc3Ryb2tlPSdub25lJy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .15625rem) center;
    background-size: calc(.75em + .3125rem) calc(.75em + .3125rem)
}

.form-control.is-invalid:focus,.was-validated .form-control:invalid:focus {
    border-color: #e63757;
    box-shadow: 0 0 0 .2rem rgba(230,55,87,.25)
}

.was-validated textarea.form-control:invalid,textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .625rem);
    background-position: top calc(.375em + .15625rem) right calc(.375em + .15625rem)
}

.custom-select.is-invalid,.was-validated .custom-select:invalid {
    border-color: #e63757;
    padding-right: calc(.75em + 2.46875rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234d5969' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e63757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e63757' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(.75em + .3125rem) calc(.75em + .3125rem)
}

.custom-select.is-invalid:focus,.was-validated .custom-select:invalid:focus {
    border-color: #e63757;
    box-shadow: 0 0 0 .2rem rgba(230,55,87,.25)
}

.form-check-input.is-invalid~.form-check-label,.was-validated .form-check-input:invalid~.form-check-label {
    color: #e63757
}

.form-check-input.is-invalid~.invalid-feedback,.form-check-input.is-invalid~.invalid-tooltip,.was-validated .form-check-input:invalid~.invalid-feedback,.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #e63757
}

.custom-control-input.is-invalid~.custom-control-label::before,.was-validated .custom-control-input:invalid~.custom-control-label::before {
    border-color: #e63757
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
    border-color: #ec647d;
    background-color: #ec647d
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(230,55,87,.25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #e63757
}

.custom-file-input.is-invalid~.custom-file-label,.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #e63757
}

.custom-file-input.is-invalid:focus~.custom-file-label,.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #e63757;
    box-shadow: 0 0 0 .2rem rgba(230,55,87,.25)
}

.form-inline {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-align: center;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media(min-width: 576px) {
    .form-inline label {
        display:-webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: -webkit-box;
        display: flex;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .custom-select,.form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 500;
    color: #5e6e82;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .3125rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .btn {
        -webkit-transition: none;
        transition: none
    }
}

.btn:hover {
    color: #5e6e82;
    text-decoration: none
}

.btn.focus,.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(44,123,229,.25)
}

.btn.disabled,.btn:disabled {
    opacity: .65
}

a.btn.disabled,fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary,.navbar-vertical:not(.navbar-vibrant) .btn-purchase {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5
}

.btn-primary:hover,.navbar-vertical:not(.navbar-vibrant) .btn-purchase:hover {
    color: #fff;
    background-color: #1a68d1;
    border-color: #1862c6
}

.btn-primary.focus,.btn-primary:focus,.navbar-vertical:not(.navbar-vibrant) .btn-purchase:focus,.navbar-vertical:not(.navbar-vibrant) .focus.btn-purchase {
    color: #fff;
    background-color: #1a68d1;
    border-color: #1862c6;
    box-shadow: 0 0 0 0 rgba(76,143,233,.5)
}

.btn-primary.disabled,.btn-primary:disabled,.navbar-vertical:not(.navbar-vibrant) .btn-purchase:disabled,.navbar-vertical:not(.navbar-vibrant) .disabled.btn-purchase {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5
}

.btn-primary:not(:disabled):not(.disabled).active,.btn-primary:not(:disabled):not(.disabled):active,.navbar-vertical:not(.navbar-vibrant) .btn-purchase:not(:disabled):not(.disabled).active,.navbar-vertical:not(.navbar-vibrant) .btn-purchase:not(:disabled):not(.disabled):active,.navbar-vertical:not(.navbar-vibrant) .show>.dropdown-toggle.btn-purchase,.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1862c6;
    border-color: #175dba
}

.btn-primary:not(:disabled):not(.disabled).active:focus,.btn-primary:not(:disabled):not(.disabled):active:focus,.navbar-vertical:not(.navbar-vibrant) .btn-purchase:not(:disabled):not(.disabled).active:focus,.navbar-vertical:not(.navbar-vibrant) .btn-purchase:not(:disabled):not(.disabled):active:focus,.navbar-vertical:not(.navbar-vibrant) .show>.dropdown-toggle.btn-purchase:focus,.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(76,143,233,.5)
}

.btn-secondary {
    color: #fff;
    background-color: #748194;
    border-color: #748194
}

.btn-secondary:hover {
    color: #fff;
    background-color: #626e80;
    border-color: #5d6878
}

.btn-secondary.focus,.btn-secondary:focus {
    color: #fff;
    background-color: #626e80;
    border-color: #5d6878;
    box-shadow: 0 0 0 0 rgba(137,148,164,.5)
}

.btn-secondary.disabled,.btn-secondary:disabled {
    color: #fff;
    background-color: #748194;
    border-color: #748194
}

.btn-secondary:not(:disabled):not(.disabled).active,.btn-secondary:not(:disabled):not(.disabled):active,.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #5d6878;
    border-color: #576271
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,.btn-secondary:not(:disabled):not(.disabled):active:focus,.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(137,148,164,.5)
}

.btn-success {
    color: #fff;
    background-color: #00d27a;
    border-color: #00d27a
}

.btn-success:hover {
    color: #fff;
    background-color: #00ac64;
    border-color: #009f5c
}

.btn-success.focus,.btn-success:focus {
    color: #fff;
    background-color: #00ac64;
    border-color: #009f5c;
    box-shadow: 0 0 0 0 rgba(38,217,142,.5)
}

.btn-success.disabled,.btn-success:disabled {
    color: #fff;
    background-color: #00d27a;
    border-color: #00d27a
}

.btn-success:not(:disabled):not(.disabled).active,.btn-success:not(:disabled):not(.disabled):active,.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #009f5c;
    border-color: #009255
}

.btn-success:not(:disabled):not(.disabled).active:focus,.btn-success:not(:disabled):not(.disabled):active:focus,.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(38,217,142,.5)
}

.btn-info {
    color: #fff;
    background-color: #27bcfd;
    border-color: #27bcfd
}

.btn-info:hover {
    color: #fff;
    background-color: #02b0fb;
    border-color: #02a7ef
}

.btn-info.focus,.btn-info:focus {
    color: #fff;
    background-color: #02b0fb;
    border-color: #02a7ef;
    box-shadow: 0 0 0 0 rgba(71,198,253,.5)
}

.btn-info.disabled,.btn-info:disabled {
    color: #fff;
    background-color: #27bcfd;
    border-color: #27bcfd
}

.btn-info:not(:disabled):not(.disabled).active,.btn-info:not(:disabled):not(.disabled):active,.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #02a7ef;
    border-color: #029ee2
}

.btn-info:not(:disabled):not(.disabled).active:focus,.btn-info:not(:disabled):not(.disabled):active:focus,.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(71,198,253,.5)
}

.btn-warning {
    color: #fff;
    background-color: #f5803e;
    border-color: #f5803e
}

.btn-warning:hover {
    color: #fff;
    background-color: #f3681a;
    border-color: #f2600e
}

.btn-warning.focus,.btn-warning:focus {
    color: #fff;
    background-color: #f3681a;
    border-color: #f2600e;
    box-shadow: 0 0 0 0 rgba(247,147,91,.5)
}

.btn-warning.disabled,.btn-warning:disabled {
    color: #fff;
    background-color: #f5803e;
    border-color: #f5803e
}

.btn-warning:not(:disabled):not(.disabled).active,.btn-warning:not(:disabled):not(.disabled):active,.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #f2600e;
    border-color: #e75b0c
}

.btn-warning:not(:disabled):not(.disabled).active:focus,.btn-warning:not(:disabled):not(.disabled):active:focus,.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(247,147,91,.5)
}

.btn-danger {
    color: #fff;
    background-color: #e63757;
    border-color: #e63757
}

.btn-danger:hover {
    color: #fff;
    background-color: #db1b3f;
    border-color: #d01a3b
}

.btn-danger.focus,.btn-danger:focus {
    color: #fff;
    background-color: #db1b3f;
    border-color: #d01a3b;
    box-shadow: 0 0 0 0 rgba(234,85,112,.5)
}

.btn-danger.disabled,.btn-danger:disabled {
    color: #fff;
    background-color: #e63757;
    border-color: #e63757
}

.btn-danger:not(:disabled):not(.disabled).active,.btn-danger:not(:disabled):not(.disabled):active,.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #d01a3b;
    border-color: #c51938
}

.btn-danger:not(:disabled):not(.disabled).active:focus,.btn-danger:not(:disabled):not(.disabled):active:focus,.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(234,85,112,.5)
}

.btn-light,.navbar-vertical.navbar-vibrant .btn-purchase {
    color: #4d5969;
    background-color: #f9fafd;
    border-color: #f9fafd
}

.btn-light:hover,.navbar-vertical.navbar-vibrant .btn-purchase:hover {
    color: #4d5969;
    background-color: #dce2f3;
    border-color: #d3daf0
}

.btn-light.focus,.btn-light:focus,.navbar-vertical.navbar-vibrant .btn-purchase:focus,.navbar-vertical.navbar-vibrant .focus.btn-purchase {
    color: #4d5969;
    background-color: #dce2f3;
    border-color: #d3daf0;
    box-shadow: 0 0 0 0 rgba(223,226,231,.5)
}

.btn-light.disabled,.btn-light:disabled,.navbar-vertical.navbar-vibrant .btn-purchase:disabled,.navbar-vertical.navbar-vibrant .disabled.btn-purchase {
    color: #4d5969;
    background-color: #f9fafd;
    border-color: #f9fafd
}

.btn-light:not(:disabled):not(.disabled).active,.btn-light:not(:disabled):not(.disabled):active,.navbar-vertical.navbar-vibrant .btn-purchase:not(:disabled):not(.disabled).active,.navbar-vertical.navbar-vibrant .btn-purchase:not(:disabled):not(.disabled):active,.navbar-vertical.navbar-vibrant .show>.dropdown-toggle.btn-purchase,.show>.btn-light.dropdown-toggle {
    color: #4d5969;
    background-color: #d3daf0;
    border-color: #c9d2ed
}

.btn-light:not(:disabled):not(.disabled).active:focus,.btn-light:not(:disabled):not(.disabled):active:focus,.navbar-vertical.navbar-vibrant .btn-purchase:not(:disabled):not(.disabled).active:focus,.navbar-vertical.navbar-vibrant .btn-purchase:not(:disabled):not(.disabled):active:focus,.navbar-vertical.navbar-vibrant .show>.dropdown-toggle.btn-purchase:focus,.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(223,226,231,.5)
}

.btn-dark {
    color: #fff;
    background-color: #0b1727;
    border-color: #0b1727
}

.btn-dark:hover {
    color: #fff;
    background-color: #030509;
    border-color: #000
}

.btn-dark.focus,.btn-dark:focus {
    color: #fff;
    background-color: #030509;
    border-color: #000;
    box-shadow: 0 0 0 0 rgba(48,58,71,.5)
}

.btn-dark.disabled,.btn-dark:disabled {
    color: #fff;
    background-color: #0b1727;
    border-color: #0b1727
}

.btn-dark:not(:disabled):not(.disabled).active,.btn-dark:not(:disabled):not(.disabled):active,.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-dark:not(:disabled):not(.disabled).active:focus,.btn-dark:not(:disabled):not(.disabled):active:focus,.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(48,58,71,.5)
}

.btn-outline-primary {
    color: #2c7be5;
    border-color: #2c7be5
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5
}

.btn-outline-primary.focus,.btn-outline-primary:focus {
    box-shadow: 0 0 0 0 rgba(44,123,229,.5)
}

.btn-outline-primary.disabled,.btn-outline-primary:disabled {
    color: #2c7be5;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,.btn-outline-primary:not(:disabled):not(.disabled):active,.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(44,123,229,.5)
}

.btn-outline-secondary {
    color: #748194;
    border-color: #748194
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #748194;
    border-color: #748194
}

.btn-outline-secondary.focus,.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0 rgba(116,129,148,.5)
}

.btn-outline-secondary.disabled,.btn-outline-secondary:disabled {
    color: #748194;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,.btn-outline-secondary:not(:disabled):not(.disabled):active,.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #748194;
    border-color: #748194
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(116,129,148,.5)
}

.btn-outline-success {
    color: #00d27a;
    border-color: #00d27a
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #00d27a;
    border-color: #00d27a
}

.btn-outline-success.focus,.btn-outline-success:focus {
    box-shadow: 0 0 0 0 rgba(0,210,122,.5)
}

.btn-outline-success.disabled,.btn-outline-success:disabled {
    color: #00d27a;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,.btn-outline-success:not(:disabled):not(.disabled):active,.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00d27a;
    border-color: #00d27a
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,.btn-outline-success:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0,210,122,.5)
}

.btn-outline-info {
    color: #27bcfd;
    border-color: #27bcfd
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #27bcfd;
    border-color: #27bcfd
}

.btn-outline-info.focus,.btn-outline-info:focus {
    box-shadow: 0 0 0 0 rgba(39,188,253,.5)
}

.btn-outline-info.disabled,.btn-outline-info:disabled {
    color: #27bcfd;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,.btn-outline-info:not(:disabled):not(.disabled):active,.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #27bcfd;
    border-color: #27bcfd
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,.btn-outline-info:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(39,188,253,.5)
}

.btn-outline-warning {
    color: #f5803e;
    border-color: #f5803e
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #f5803e;
    border-color: #f5803e
}

.btn-outline-warning.focus,.btn-outline-warning:focus {
    box-shadow: 0 0 0 0 rgba(245,128,62,.5)
}

.btn-outline-warning.disabled,.btn-outline-warning:disabled {
    color: #f5803e;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,.btn-outline-warning:not(:disabled):not(.disabled):active,.show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f5803e;
    border-color: #f5803e
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,.btn-outline-warning:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(245,128,62,.5)
}

.btn-outline-danger {
    color: #e63757;
    border-color: #e63757
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #e63757;
    border-color: #e63757
}

.btn-outline-danger.focus,.btn-outline-danger:focus {
    box-shadow: 0 0 0 0 rgba(230,55,87,.5)
}

.btn-outline-danger.disabled,.btn-outline-danger:disabled {
    color: #e63757;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,.btn-outline-danger:not(:disabled):not(.disabled):active,.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e63757;
    border-color: #e63757
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,.btn-outline-danger:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(230,55,87,.5)
}

.btn-outline-light {
    color: #f9fafd;
    border-color: #f9fafd
}

.btn-outline-light:hover {
    color: #4d5969;
    background-color: #f9fafd;
    border-color: #f9fafd
}

.btn-outline-light.focus,.btn-outline-light:focus {
    box-shadow: 0 0 0 0 rgba(249,250,253,.5)
}

.btn-outline-light.disabled,.btn-outline-light:disabled {
    color: #f9fafd;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,.btn-outline-light:not(:disabled):not(.disabled):active,.show>.btn-outline-light.dropdown-toggle {
    color: #4d5969;
    background-color: #f9fafd;
    border-color: #f9fafd
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,.btn-outline-light:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(249,250,253,.5)
}

.btn-outline-dark {
    color: #0b1727;
    border-color: #0b1727
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #0b1727;
    border-color: #0b1727
}

.btn-outline-dark.focus,.btn-outline-dark:focus {
    box-shadow: 0 0 0 0 rgba(11,23,39,.5)
}

.btn-outline-dark.disabled,.btn-outline-dark:disabled {
    color: #0b1727;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,.btn-outline-dark:not(:disabled):not(.disabled):active,.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #0b1727;
    border-color: #0b1727
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,.btn-outline-dark:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(11,23,39,.5)
}

.btn-link {
    font-weight: 400;
    color: #2c7be5;
    text-decoration: none
}

.btn-link:hover {
    color: #1657af;
    text-decoration: underline
}

.btn-link.focus,.btn-link:focus {
    text-decoration: underline;
    box-shadow: none
}

.btn-link.disabled,.btn-link:disabled {
    color: #748194;
    pointer-events: none
}

.btn-group-lg>.btn,.btn-lg {
    padding: .375rem 1.25rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-group-sm>.btn,.btn-sm {
    padding: .1875rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,input[type=reset].btn-block,input[type=submit].btn-block {
    width: 100%
}

.fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear
}

@media(prefers-reduced-motion:reduce) {
    .fade {
        -webkit-transition: none;
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    transition: height .35s ease
}

@media(prefers-reduced-motion:reduce) {
    .collapsing {
        -webkit-transition: none;
        transition: none
    }
}

.dropdown,.dropleft,.dropright,.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 1rem 0;
    margin: .125rem 0 0;
    font-size: .8rem;
    color: #5e6e82;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f9fafd;
    border-radius: .25rem
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

@media(min-width: 576px) {
    .dropdown-menu-sm-left {
        right:auto;
        left: 0
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media(min-width: 768px) {
    .dropdown-menu-md-left {
        right:auto;
        left: 0
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media(min-width: 992px) {
    .dropdown-menu-lg-left {
        right:auto;
        left: 0
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media(min-width: 1200px) {
    .dropdown-menu-xl-left {
        right:auto;
        left: 0
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

@media(min-width: 1540px) {
    .dropdown-menu-xxl-left {
        right:auto;
        left: 0
    }

    .dropdown-menu-xxl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropleft .dropdown-toggle::after {
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],.dropdown-menu[x-placement^=left],.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #edf2f9
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #5e6e82;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,.dropdown-item:hover {
    color: #344050;
    text-decoration: none;
    background-color: #edf2f9
}

.dropdown-item.active,.dropdown-item:active {
    color: #232e3c;
    text-decoration: none;
    background-color: #d8e2ef
}

.dropdown-item.disabled,.dropdown-item:disabled {
    color: #9da9bb;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: 1rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #748194;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1rem;
    color: #5e6e82
}

.btn-group,.btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,.btn-group>.btn {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto
}

.btn-group-vertical>.btn:hover,.btn-group>.btn:hover {
    z-index: 1
}

.btn-group-vertical>.btn.active,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn:focus,.btn-group>.btn.active,.btn-group>.btn:active,.btn-group>.btn:focus {
    z-index: 1
}

.btn-toolbar {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,.btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.dropdown-toggle-split::after,.dropright .dropdown-toggle-split::after,.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,.btn-sm+.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,.btn-lg+.dropdown-toggle-split {
    padding-right: .9375rem;
    padding-left: .9375rem
}

.btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center
}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],.btn-group-toggle>.btn input[type=radio],.btn-group-toggle>.btn-group>.btn input[type=checkbox],.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,.input-group>.custom-select,.input-group>.form-control,.input-group>.form-control-plaintext {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0
}

.input-group>.custom-file+.custom-file,.input-group>.custom-file+.custom-select,.input-group>.custom-file+.form-control,.input-group>.custom-select+.custom-file,.input-group>.custom-select+.custom-select,.input-group>.custom-select+.form-control,.input-group>.form-control+.custom-file,.input-group>.form-control+.custom-select,.input-group>.form-control+.form-control,.input-group>.form-control-plaintext+.custom-file,.input-group>.form-control-plaintext+.custom-select,.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,.input-group>.custom-select:focus,.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.custom-select:not(:last-child),.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,.input-group-prepend {
    display: -webkit-box;
    display: flex
}

.input-group-append .btn,.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus,.input-group-prepend .btn:focus {
    z-index: 3
}

.input-group-append .btn+.btn,.input-group-append .btn+.input-group-text,.input-group-append .input-group-text+.btn,.input-group-append .input-group-text+.input-group-text,.input-group-prepend .btn+.btn,.input-group-prepend .btn+.input-group-text,.input-group-prepend .input-group-text+.btn,.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: .3125rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #344050;
    text-align: center;
    white-space: nowrap;
    background-color: #edf2f9;
    border: 1px solid #d8e2ef;
    border-radius: .25rem
}

.input-group-text input[type=checkbox],.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg>.custom-select,.input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em + .75rem + 2px)
}

.input-group-lg>.custom-select,.input-group-lg>.form-control,.input-group-lg>.input-group-append>.btn,.input-group-lg>.input-group-append>.input-group-text,.input-group-lg>.input-group-prepend>.btn,.input-group-lg>.input-group-prepend>.input-group-text {
    padding: .375rem 1.25rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.input-group-sm>.custom-select,.input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em + .375rem + 2px)
}

.input-group-sm>.custom-select,.input-group-sm>.form-control,.input-group-sm>.input-group-append>.btn,.input-group-sm>.input-group-append>.input-group-text,.input-group-sm>.input-group-prepend>.btn,.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .1875rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-lg>.custom-select,.input-group-sm>.custom-select {
    padding-right: 2rem
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),.input-group>.input-group-append:not(:last-child)>.btn,.input-group>.input-group-append:not(:last-child)>.input-group-text,.input-group>.input-group-prepend>.btn,.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,.input-group>.input-group-append>.input-group-text,.input-group>.input-group-prepend:first-child>.btn:not(:first-child),.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),.input-group>.input-group-prepend:not(:first-child)>.btn,.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    /* padding-left: 1.5rem */
}

.custom-control-inline {
    display: -webkit-inline-box;
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #2c7be5;
    background-color: #2c7be5
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(44,123,229,.25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #9ec2f3
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #cbdef9;
    border-color: #cbdef9
}

.custom-control-input:disabled~.custom-control-label,.custom-control-input[disabled]~.custom-control-label {
    color: #748194
}

.custom-control-input:disabled~.custom-control-label::before,.custom-control-input[disabled]~.custom-control-label::before {
    background-color: #edf2f9
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top
}

.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #9da9bb solid 1px
}

.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label::before {
    border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J002LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNmwyLjk3NCAyLjk5TDggMi4xOTN6Jy8+PC9zdmc+)
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #2c7be5;
    background-color: #2c7be5
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0JyBoZWlnaHQ9JzQnIHZpZXdCb3g9JzAgMCA0IDQnPjxwYXRoIHN0cm9rZT0nI2ZmZicgZD0nTTAgMmg0Jy8+PC9zdmc+)
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(44,123,229,.5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(44,123,229,.5)
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgdmlld0JveD0nLTQgLTQgOCA4Jz48Y2lyY2xlIHI9JzMnIGZpbGw9JyNmZmYnLz48L3N2Zz4=)
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(44,123,229,.5)
}

/* .custom-switch {
    padding-left: 2.25rem
} */

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem
}

.custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #9da9bb;
    border-radius: .5rem;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(44,123,229,.5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .625rem + 2px);
    padding: .3125rem 2rem .3125rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #344050;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234d5969' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
    border: 1px solid #d8e2ef;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: #9ec2f3;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(44,123,229,.25)
}

.custom-select:focus::-ms-value {
    color: #344050;
    background-color: #fff
}

.custom-select[multiple],.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none
}

.custom-select:disabled {
    color: #748194;
    background-color: #edf2f9
}

.custom-select::-ms-expand {
    display: none
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #344050
}

.custom-select-sm {
    height: calc(1.5em + .375rem + 2px);
    padding-top: .1875rem;
    padding-bottom: .1875rem;
    padding-left: .75rem;
    font-size: .875rem
}

.custom-select-lg {
    height: calc(1.5em + .75rem + 2px);
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 1.25rem;
    font-size: 1.25rem
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .625rem + 2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .625rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-label {
    border-color: #9ec2f3;
    box-shadow: 0 0 0 .2rem rgba(44,123,229,.25)
}

.custom-file-input:disabled~.custom-file-label,.custom-file-input[disabled]~.custom-file-label {
    background-color: #edf2f9
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse)
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .625rem + 2px);
    padding: .3125rem 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #344050;
    background-color: #fff;
    border: 1px solid #d8e2ef;
    border-radius: .25rem
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .625rem);
    padding: .3125rem 1rem;
    line-height: 1.5;
    color: #344050;
    content: "Browse";
    background-color: #edf2f9;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-range:focus {
    outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #edf2f9,0 0 0 .2rem rgba(44,123,229,.25)
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #edf2f9,0 0 0 .2rem rgba(44,123,229,.25)
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #edf2f9,0 0 0 .2rem rgba(44,123,229,.25)
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #2c7be5;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #cbdef9
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d8e2ef;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2c7be5;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #cbdef9
}

.custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d8e2ef;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;
    background-color: #2c7be5;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .custom-range::-ms-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.custom-range::-ms-thumb:active {
    background-color: #cbdef9
}

.custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem
}

.custom-range::-ms-fill-lower {
    background-color: #d8e2ef;
    border-radius: 1rem
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #d8e2ef;
    border-radius: 1rem
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #9da9bb
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #9da9bb
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #9da9bb
}

.custom-control-label::before,.custom-file-label,.custom-select {
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .custom-control-label::before,.custom-file-label,.custom-select {
        -webkit-transition: none;
        transition: none
    }
}

.nav {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #748194;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #d8e2ef
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,.nav-tabs .nav-link:hover {
    border-color: #edf2f9 #edf2f9 #d8e2ef
}

.nav-tabs .nav-link.disabled {
    color: #748194;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active {
    color: #5e6e82;
    background-color: #edf2f9;
    border-color: #d8e2ef #d8e2ef #edf2f9
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #2c7be5
}

.nav-fill .nav-item {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem
}

.navbar .container,.navbar .container-fluid,.navbar .container-lg,.navbar .container-md,.navbar .container-sm,.navbar .container-xl,.navbar .container-xxl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    margin-right: 1rem;
    font-size: 1.728rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus,.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

@media(max-width: 575.98px) {
    .navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid,.navbar-expand-sm>.container-lg,.navbar-expand-sm>.container-md,.navbar-expand-sm>.container-sm,.navbar-expand-sm>.container-xl,.navbar-expand-sm>.container-xxl {
        padding-right:0;
        padding-left: 0
    }
}

@media(min-width: 576px) {
    .navbar-expand-sm {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid,.navbar-expand-sm>.container-lg,.navbar-expand-sm>.container-md,.navbar-expand-sm>.container-sm,.navbar-expand-sm>.container-xl,.navbar-expand-sm>.container-xxl {
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: -webkit-box!important;
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media(max-width: 767.98px) {
    .navbar-expand-md>.container,.navbar-expand-md>.container-fluid,.navbar-expand-md>.container-lg,.navbar-expand-md>.container-md,.navbar-expand-md>.container-sm,.navbar-expand-md>.container-xl,.navbar-expand-md>.container-xxl {
        padding-right:0;
        padding-left: 0
    }
}

@media(min-width: 768px) {
    .navbar-expand-md {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md>.container,.navbar-expand-md>.container-fluid,.navbar-expand-md>.container-lg,.navbar-expand-md>.container-md,.navbar-expand-md>.container-sm,.navbar-expand-md>.container-xl,.navbar-expand-md>.container-xxl {
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: -webkit-box!important;
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media(max-width: 991.98px) {
    .navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid,.navbar-expand-lg>.container-lg,.navbar-expand-lg>.container-md,.navbar-expand-lg>.container-sm,.navbar-expand-lg>.container-xl,.navbar-expand-lg>.container-xxl {
        padding-right:0;
        padding-left: 0
    }
}

@media(min-width: 992px) {
    .navbar-expand-lg {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid,.navbar-expand-lg>.container-lg,.navbar-expand-lg>.container-md,.navbar-expand-lg>.container-sm,.navbar-expand-lg>.container-xl,.navbar-expand-lg>.container-xxl {
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: -webkit-box!important;
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media(max-width: 1199.98px) {
    .navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid,.navbar-expand-xl>.container-lg,.navbar-expand-xl>.container-md,.navbar-expand-xl>.container-sm,.navbar-expand-xl>.container-xl,.navbar-expand-xl>.container-xxl {
        padding-right:0;
        padding-left: 0
    }
}

@media(min-width: 1200px) {
    .navbar-expand-xl {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid,.navbar-expand-xl>.container-lg,.navbar-expand-xl>.container-md,.navbar-expand-xl>.container-sm,.navbar-expand-xl>.container-xl,.navbar-expand-xl>.container-xxl {
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: -webkit-box!important;
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

@media(max-width: 1539.98px) {
    .navbar-expand-xxl>.container,.navbar-expand-xxl>.container-fluid,.navbar-expand-xxl>.container-lg,.navbar-expand-xxl>.container-md,.navbar-expand-xxl>.container-sm,.navbar-expand-xxl>.container-xl,.navbar-expand-xxl>.container-xxl {
        padding-right:0;
        padding-left: 0
    }
}

@media(min-width: 1540px) {
    .navbar-expand-xxl {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xxl>.container,.navbar-expand-xxl>.container-fluid,.navbar-expand-xxl>.container-lg,.navbar-expand-xxl>.container-md,.navbar-expand-xxl>.container-sm,.navbar-expand-xxl>.container-xl,.navbar-expand-xxl>.container-xxl {
        flex-wrap: nowrap
    }

    .navbar-expand-xxl .navbar-collapse {
        display: -webkit-box!important;
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start
}

.navbar-expand>.container,.navbar-expand>.container-fluid,.navbar-expand>.container-lg,.navbar-expand>.container-md,.navbar-expand>.container-sm,.navbar-expand>.container-xl,.navbar-expand>.container-xxl {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand>.container,.navbar-expand>.container-fluid,.navbar-expand>.container-lg,.navbar-expand>.container-md,.navbar-expand>.container-sm,.navbar-expand>.container-xl,.navbar-expand>.container-xxl {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: -webkit-box!important;
    display: flex!important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: #2c7be5
}

.navbar-light .navbar-brand:focus,.navbar-light .navbar-brand:hover {
    color: #2c7be5
}

/* .navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,.55)
} */

.navbar-light .navbar-nav .nav-link:focus,.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0,0,0,.9)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0,0,0,.25)
}

.navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .show>.nav-link {
    color: #2c7be5
}

.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.55);
    border-color: rgba(0,0,0,.1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPSdyZ2JhKDAsIDAsIDAsIDAuNTUpJyBzdHJva2Utd2lkdGg9JzInIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBkPSdNMCA2aDMwTTAgMTRoMzBNMCAyMmgzMCcvPjwvc3ZnPg==)
}

.navbar-light .navbar-text {
    color: rgba(0,0,0,.55)
}

.navbar-light .navbar-text a {
    color: #2c7be5
}

.navbar-light .navbar-text a:focus,.navbar-light .navbar-text a:hover {
    color: #2c7be5
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,.7)
}

.navbar-dark .navbar-nav .nav-link:focus,.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255,255,255,.8)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255,255,255,.25)
}

.navbar-dark .navbar-nav .active>.nav-link,.navbar-dark .navbar-nav .nav-link.active,.navbar-dark .navbar-nav .nav-link.show,.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255,255,255,.7);
    border-color: rgba(255,255,255,.1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSczMCcgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDMwIDMwJz48cGF0aCBzdHJva2U9J3JnYmEoMjU1LCAyNTUsIDI1NSwgMC43KScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbWl0ZXJsaW1pdD0nMTAnIHN0cm9rZS13aWR0aD0nMicgZD0nTTQgN2gyMk00IDE1aDIyTTQgMjNoMjInLz48L3N2Zz4=)
}

.navbar-dark .navbar-text {
    color: rgba(255,255,255,.7)
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:focus,.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #edf2f9;
    border-radius: .375rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.card-body {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem
}

.card-title {
    margin-bottom: 1rem
}

.card-subtitle {
    margin-top: -.5rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: 1rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0 solid #edf2f9
}

.card-header:first-child {
    border-radius: calc(.375rem - 0px) calc(.375rem - 0px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: 1rem 1.25rem;
    background-color: #fff;
    border-top: 0 solid #edf2f9
}

.card-footer:last-child {
    border-radius: 0 0 calc(.375rem - 0px) calc(.375rem - 0px)
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -1rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img,.card-img-bottom,.card-img-top {
    flex-shrink: 0;
    width: 100%
}

.card-img,.card-img-top {
    border-top-left-radius: calc(.375rem - 0px);
    border-top-right-radius: calc(.375rem - 0px)
}

.card-img,.card-img-bottom {
    border-bottom-right-radius: calc(.375rem - 0px);
    border-bottom-left-radius: calc(.375rem - 0px)
}

.card-deck .card {
    margin-bottom: .5rem
}

@media(min-width: 576px) {
    .card-deck {
        display:-webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        margin-right: -.5rem;
        margin-left: -.5rem
    }

    .card-deck .card {
        -webkit-box-flex: 1;
        flex: 1 0 0%;
        margin-right: .5rem;
        margin-bottom: 0;
        margin-left: .5rem
    }
}

.card-group>.card {
    margin-bottom: 15px
}

@media(min-width: 576px) {
    .card-group {
        display:-webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap
    }

    .card-group>.card {
        -webkit-box-flex: 1;
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,.card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,.card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,.card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,.card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: 1rem
}

@media(min-width: 576px) {
    .card-columns {
        -webkit-column-count:3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion>.card {
    overflow: hidden
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: 0
}

.breadcrumb {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #748194;
    content: "»"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: #748194
}

.pagination {
    display: -webkit-box;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #000;
    background-color: #fff;
    border: 1px solid #d8e2ef
}

.page-link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: #000;
    border-color: #000
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(44,123,229,.25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #000;
    border-color: #000
}

.page-item.disabled .page-link {
    color: #748194;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #d8e2ef
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .35556em .71111em;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .badge {
        -webkit-transition: none;
        transition: none
    }
}

a.badge:focus,a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .71111em;
    padding-left: .71111em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #2c7be5
}

a.badge-primary:focus,a.badge-primary:hover {
    color: #fff;
    background-color: #1862c6
}

a.badge-primary.focus,a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(44,123,229,.5)
}

.badge-secondary {
    color: #fff;
    background-color: #748194
}

a.badge-secondary:focus,a.badge-secondary:hover {
    color: #fff;
    background-color: #5d6878
}

a.badge-secondary.focus,a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(116,129,148,.5)
}

.badge-success {
    color: #fff;
    background-color: #00d27a
}

a.badge-success:focus,a.badge-success:hover {
    color: #fff;
    background-color: #009f5c
}

a.badge-success.focus,a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0,210,122,.5)
}

.badge-info {
    color: #fff;
    background-color: #27bcfd
}

a.badge-info:focus,a.badge-info:hover {
    color: #fff;
    background-color: #02a7ef
}

a.badge-info.focus,a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(39,188,253,.5)
}

.badge-warning {
    color: #fff;
    background-color: #f5803e
}

a.badge-warning:focus,a.badge-warning:hover {
    color: #fff;
    background-color: #f2600e
}

a.badge-warning.focus,a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(245,128,62,.5)
}

.badge-danger {
    color: #fff;
    background-color: #e63757
}

a.badge-danger:focus,a.badge-danger:hover {
    color: #fff;
    background-color: #d01a3b
}

a.badge-danger.focus,a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(230,55,87,.5)
}

.badge-light {
    color: #4d5969;
    background-color: #f9fafd
}

a.badge-light:focus,a.badge-light:hover {
    color: #4d5969;
    background-color: #d3daf0
}

a.badge-light.focus,a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(249,250,253,.5)
}

.badge-dark {
    color: #fff;
    background-color: #0b1727
}

a.badge-dark:focus,a.badge-dark:hover {
    color: #fff;
    background-color: #000
}

a.badge-dark.focus,a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(11,23,39,.5)
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #edf2f9;
    border-radius: .3rem
}

@media(min-width: 576px) {
    .jumbotron {
        padding:4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 4rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #174077;
    background-color: #d5e5fa;
    border-color: #c4daf8
}

.alert-primary hr {
    border-top-color: #adccf5
}

.alert-primary .alert-link {
    color: #0f294c
}

.alert-secondary {
    color: #3c434d;
    background-color: #e3e6ea;
    border-color: #d8dce1
}

.alert-secondary hr {
    border-top-color: #cacfd6
}

.alert-secondary .alert-link {
    color: #262a30
}

.alert-success {
    color: #006d3f;
    background-color: #ccf6e4;
    border-color: #b8f2da
}

.alert-success hr {
    border-top-color: #a2eecf
}

.alert-success .alert-link {
    color: #003a22
}

.alert-info {
    color: #146284;
    background-color: #d4f2ff;
    border-color: #c3ecfe
}

.alert-info hr {
    border-top-color: #aae4fe
}

.alert-info .alert-link {
    color: #0d4158
}

.alert-warning {
    color: #7f4320;
    background-color: #fde6d8;
    border-color: #fcdbc9
}

.alert-warning hr {
    border-top-color: #fbcbb1
}

.alert-warning .alert-link {
    color: #562e16
}

.alert-danger {
    color: #781d2d;
    background-color: #fad7dd;
    border-color: #f8c7d0
}

.alert-danger hr {
    border-top-color: #f5b0bd
}

.alert-danger .alert-link {
    color: #4f131e
}

.alert-light {
    color: #818284;
    background-color: #fefeff;
    border-color: #fdfefe
}

.alert-light hr {
    border-top-color: #ecf6f6
}

.alert-light .alert-link {
    color: #68696a
}

.alert-dark {
    color: #060c14;
    background-color: #ced1d4;
    border-color: #bbbec3
}

.alert-dark hr {
    border-top-color: #adb1b7
}

.alert-dark .alert-link {
    color: #000
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    display: -webkit-box;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #edf2f9;
    border-radius: .25rem
}

.progress-bar {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #2c7be5;
    -webkit-transition: width .6s ease;
    transition: width .6s ease
}

@media(prefers-reduced-motion:reduce) {
    .progress-bar {
        -webkit-transition: none;
        transition: none
    }
}

.progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
}

@media(prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.media {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start
}

.media-body {
    -webkit-box-flex: 1;
    flex: 1
}

.list-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #5e6e82;
    text-align: inherit
}

.list-group-item-action:focus,.list-group-item-action:hover {
    z-index: 1;
    color: #5e6e82;
    text-decoration: none;
    background-color: #f9fafd
}

.list-group-item-action:active {
    color: #5e6e82;
    background-color: #edf2f9
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    background-color: #fff;
    border: 1px solid #d8e2ef
}

.list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.list-group-item.disabled,.list-group-item:disabled {
    color: #748194;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row
}

.list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0
}

.list-group-horizontal .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media(min-width: 576px) {
    .list-group-horizontal-sm {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 768px) {
    .list-group-horizontal-md {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 992px) {
    .list-group-horizontal-lg {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 1200px) {
    .list-group-horizontal-xl {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 1540px) {
    .list-group-horizontal-xxl {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        flex-direction: row
    }

    .list-group-horizontal-xxl .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xxl .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #174077;
    background-color: #c4daf8
}

.list-group-item-primary.list-group-item-action:focus,.list-group-item-primary.list-group-item-action:hover {
    color: #174077;
    background-color: #adccf5
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #174077;
    border-color: #174077
}

.list-group-item-secondary {
    color: #3c434d;
    background-color: #d8dce1
}

.list-group-item-secondary.list-group-item-action:focus,.list-group-item-secondary.list-group-item-action:hover {
    color: #3c434d;
    background-color: #cacfd6
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3c434d;
    border-color: #3c434d
}

.list-group-item-success {
    color: #006d3f;
    background-color: #b8f2da
}

.list-group-item-success.list-group-item-action:focus,.list-group-item-success.list-group-item-action:hover {
    color: #006d3f;
    background-color: #a2eecf
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #006d3f;
    border-color: #006d3f
}

.list-group-item-info {
    color: #146284;
    background-color: #c3ecfe
}

.list-group-item-info.list-group-item-action:focus,.list-group-item-info.list-group-item-action:hover {
    color: #146284;
    background-color: #aae4fe
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #146284;
    border-color: #146284
}

.list-group-item-warning {
    color: #7f4320;
    background-color: #fcdbc9
}

.list-group-item-warning.list-group-item-action:focus,.list-group-item-warning.list-group-item-action:hover {
    color: #7f4320;
    background-color: #fbcbb1
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7f4320;
    border-color: #7f4320
}

.list-group-item-danger {
    color: #781d2d;
    background-color: #f8c7d0
}

.list-group-item-danger.list-group-item-action:focus,.list-group-item-danger.list-group-item-action:hover {
    color: #781d2d;
    background-color: #f5b0bd
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #781d2d;
    border-color: #781d2d
}

.list-group-item-light {
    color: #818284;
    background-color: #fdfefe
}

.list-group-item-light.list-group-item-action:focus,.list-group-item-light.list-group-item-action:hover {
    color: #818284;
    background-color: #ecf6f6
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818284;
    border-color: #818284
}

.list-group-item-dark {
    color: #060c14;
    background-color: #bbbec3
}

.list-group-item-dark.list-group-item-action:focus,.list-group-item-dark.list-group-item-action:hover {
    color: #060c14;
    background-color: #adb1b7
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #060c14;
    border-color: #060c14
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:hover {
    color: #000;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,.close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

a.close.disabled {
    pointer-events: none
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 .25rem .75rem rgba(0,0,0,.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .25rem
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: .25rem .75rem;
    color: #748194;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05)
}

.toast-body {
    padding: .75rem
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px)
}

@media(prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    display: -webkit-box;
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-footer,.modal-dialog-scrollable .modal-header {
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .375rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #d8e2ef;
    border-top-left-radius: calc(.375rem - 1px);
    border-top-right-radius: calc(.375rem - 1px)
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #d8e2ef;
    border-bottom-right-radius: calc(.375rem - 1px);
    border-bottom-left-radius: calc(.375rem - 1px)
}

.modal-footer>* {
    margin: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media(min-width: 576px) {
    .modal-dialog {
        max-width:500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media(min-width: 992px) {
    .modal-lg,.modal-xl {
        max-width:800px
    }
}

@media(min-width: 1200px) {
    .modal-xl {
        max-width:1140px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: open sans,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .83333rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top],.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right],.bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,.bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom],.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left],.bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,.bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,.bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: open sans,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem
}

.popover .arrow::after,.popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top],.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,.bs-popover-top>.arrow {
    bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=top]>.arrow::before,.bs-popover-top>.arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0,0,0,.25)
}

.bs-popover-auto[x-placement^=top]>.arrow::after,.bs-popover-top>.arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,.bs-popover-right>.arrow {
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=right]>.arrow::before,.bs-popover-right>.arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0,0,0,.25)
}

.bs-popover-auto[x-placement^=right]>.arrow::after,.bs-popover-right>.arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,.bs-popover-bottom>.arrow {
    top: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::before,.bs-popover-bottom>.arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem;
    border-bottom-color: rgba(0,0,0,.25)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after,.bs-popover-bottom>.arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,.bs-popover-left>.arrow {
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=left]>.arrow::before,.bs-popover-left>.arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0,0,0,.25)
}

.bs-popover-auto[x-placement^=left]>.arrow::after,.bs-popover-left>.arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #344050;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .5rem .75rem;
    color: #5e6e82
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .8s ease-in-out;
    transition: -webkit-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
    transition: transform .8s ease-in-out,-webkit-transform .8s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .carousel-item {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-item-next,.carousel-item-prev,.carousel-item.active {
    display: block
}

.active.carousel-item-right,.carousel-item-next:not(.carousel-item-left) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.active.carousel-item-left,.carousel-item-prev:not(.carousel-item-right) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left,.carousel-fade .carousel-item-prev.carousel-item-right,.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-left,.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s .8s;
    transition: opacity 0s .8s
}

@media(prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-left,.carousel-fade .active.carousel-item-right {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-control-next,.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    -webkit-transition: opacity .15s ease;
    transition: opacity .15s ease
}

@media(prefers-reduced-motion:reduce) {
    .carousel-control-next,.carousel-control-prev {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-control-next:focus,.carousel-control-next:hover,.carousel-control-prev:focus,.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmYnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGQ9J001LjI1IDBsLTQgNCA0IDQgMS41LTEuNUw0LjI1IDRsMi41LTIuNUw1LjI1IDB6Jy8+PC9zdmc+)
}

.carousel-control-next-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmYnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGQ9J00yLjc1IDBsLTEuNSAxLjVMMy43NSA0bC0yLjUgMi41TDIuNzUgOGw0LTQtNC00eicvPjwvc3ZnPg==)
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    box-sizing: content-box;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity .6s ease;
    transition: opacity .6s ease
}

@media(prefers-reduced-motion:reduce) {
    .carousel-indicators li {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
    width: 1.35rem;
    height: 1.35rem;
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
    width: 1.35rem;
    height: 1.35rem
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.bg-primary {
    background-color: #2c7be5!important
}

a.bg-primary:focus,a.bg-primary:hover,button.bg-primary:focus,button.bg-primary:hover {
    background-color: #1862c6!important
}

.bg-secondary {
    background-color: #748194!important
}

a.bg-secondary:focus,a.bg-secondary:hover,button.bg-secondary:focus,button.bg-secondary:hover {
    background-color: #5d6878!important
}

.bg-success {
    background-color: #00d27a!important
}

a.bg-success:focus,a.bg-success:hover,button.bg-success:focus,button.bg-success:hover {
    background-color: #009f5c!important
}

.bg-info {
    background-color: #27bcfd!important
}

a.bg-info:focus,a.bg-info:hover,button.bg-info:focus,button.bg-info:hover {
    background-color: #02a7ef!important
}

.bg-warning {
    background-color: #f5803e!important
}

a.bg-warning:focus,a.bg-warning:hover,button.bg-warning:focus,button.bg-warning:hover {
    background-color: #f2600e!important
}

.bg-danger {
    background-color: #e63757!important
}

a.bg-danger:focus,a.bg-danger:hover,button.bg-danger:focus,button.bg-danger:hover {
    background-color: #d01a3b!important
}

.bg-light {
    background-color: #f9fafd!important
}

a.bg-light:focus,a.bg-light:hover,button.bg-light:focus,button.bg-light:hover {
    background-color: #d3daf0!important
}

.bg-dark {
    background-color: #0b1727!important
}

a.bg-dark:focus,a.bg-dark:hover,button.bg-dark:focus,button.bg-dark:hover {
    background-color: #000!important
}

.bg-white {
    background-color: #fff!important
}

.bg-transparent {
    background-color: transparent!important
}

.border {
    border: 1px solid #d8e2ef!important
}

.border-top {
    border-top: 1px solid #d8e2ef!important
}

.border-right {
    border-right: 1px solid #d8e2ef!important
}

.border-bottom {
    border-bottom: 1px solid #d8e2ef!important
}

.border-left {
    border-left: 1px solid #d8e2ef!important
}

.border-0 {
    border: 0!important
}

.border-top-0 {
    border-top: 0!important
}

.border-right-0 {
    border-right: 0!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-left-0 {
    border-left: 0!important
}

.border-primary {
    border-color: #2c7be5!important
}

.border-secondary {
    border-color: #748194!important
}

.border-success {
    border-color: #00d27a!important
}

.border-info {
    border-color: #27bcfd!important
}

.border-warning {
    border-color: #f5803e!important
}

.border-danger {
    border-color: #e63757!important
}

.border-light {
    border-color: #f9fafd!important
}

.border-dark {
    border-color: #0b1727!important
}

.border-white {
    border-color: #fff!important
}

.rounded-sm {
    border-radius: .2rem!important
}

.rounded {
    border-radius: .25rem!important
}

.rounded-top {
    border-top-left-radius: .25rem!important;
    border-top-right-radius: .25rem!important
}

.rounded-right {
    border-top-right-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-left {
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-lg {
    border-radius: .3rem!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-pill {
    border-radius: 50rem!important
}

.rounded-0 {
    border-radius: 0!important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: -webkit-box!important;
    display: flex!important
}

.d-inline-flex {
    display: -webkit-inline-box!important;
    display: inline-flex!important
}

@media(min-width: 576px) {
    .d-sm-none {
        display:none!important
    }

    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: -webkit-box!important;
        display: flex!important
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box!important;
        display: inline-flex!important
    }
}

@media(min-width: 768px) {
    .d-md-none {
        display:none!important
    }

    .d-md-inline {
        display: inline!important
    }

    .d-md-inline-block {
        display: inline-block!important
    }

    .d-md-block {
        display: block!important
    }

    .d-md-table {
        display: table!important
    }

    .d-md-table-row {
        display: table-row!important
    }

    .d-md-table-cell {
        display: table-cell!important
    }

    .d-md-flex {
        display: -webkit-box!important;
        display: flex!important
    }

    .d-md-inline-flex {
        display: -webkit-inline-box!important;
        display: inline-flex!important
    }
}

@media(min-width: 992px) {
    .d-lg-none {
        display:none!important
    }

    .d-lg-inline {
        display: inline!important
    }

    .d-lg-inline-block {
        display: inline-block!important
    }

    .d-lg-block {
        display: block!important
    }

    .d-lg-table {
        display: table!important
    }

    .d-lg-table-row {
        display: table-row!important
    }

    .d-lg-table-cell {
        display: table-cell!important
    }

    .d-lg-flex {
        display: -webkit-box!important;
        display: flex!important
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box!important;
        display: inline-flex!important
    }
}

@media(min-width: 1200px) {
    .d-xl-none {
        display:none!important
    }

    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: -webkit-box!important;
        display: flex!important
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box!important;
        display: inline-flex!important
    }
}

@media(min-width: 1540px) {
    .d-xxl-none {
        display:none!important
    }

    .d-xxl-inline {
        display: inline!important
    }

    .d-xxl-inline-block {
        display: inline-block!important
    }

    .d-xxl-block {
        display: block!important
    }

    .d-xxl-table {
        display: table!important
    }

    .d-xxl-table-row {
        display: table-row!important
    }

    .d-xxl-table-cell {
        display: table-cell!important
    }

    .d-xxl-flex {
        display: -webkit-box!important;
        display: flex!important
    }

    .d-xxl-inline-flex {
        display: -webkit-inline-box!important;
        display: inline-flex!important
    }
}

@media print {
    .d-print-none {
        display: none!important
    }

    .d-print-inline {
        display: inline!important
    }

    .d-print-inline-block {
        display: inline-block!important
    }

    .d-print-block {
        display: block!important
    }

    .d-print-table {
        display: table!important
    }

    .d-print-table-row {
        display: table-row!important
    }

    .d-print-table-cell {
        display: table-cell!important
    }

    .d-print-flex {
        display: -webkit-box!important;
        display: flex!important
    }

    .d-print-inline-flex {
        display: -webkit-inline-box!important;
        display: inline-flex!important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,.embed-responsive embed,.embed-responsive iframe,.embed-responsive object,.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    flex-direction: row!important
}

.flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    flex-direction: column!important
}

.flex-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    flex-direction: column-reverse!important
}

.flex-wrap {
    flex-wrap: wrap!important
}

.flex-nowrap {
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse!important
}

.flex-fill {
    -webkit-box-flex: 1!important;
    flex: 1 1 auto!important
}

.flex-grow-0 {
    -webkit-box-flex: 0!important;
    flex-grow: 0!important
}

.flex-grow-1 {
    -webkit-box-flex: 1!important;
    flex-grow: 1!important
}

.flex-shrink-0 {
    flex-shrink: 0!important
}

.flex-shrink-1 {
    flex-shrink: 1!important
}

.justify-content-start {
    -webkit-box-pack: start!important;
    justify-content: flex-start!important
}

.justify-content-end {
    -webkit-box-pack: end!important;
    justify-content: flex-end!important
}

.justify-content-center {
    -webkit-box-pack: center!important;
    justify-content: center!important
}

.justify-content-between {
    -webkit-box-pack: justify!important;
    justify-content: space-between!important
}

.justify-content-around {
    justify-content: space-around!important
}

.align-items-start {
    -webkit-box-align: start!important;
    align-items: flex-start!important
}

.align-items-end {
    -webkit-box-align: end!important;
    align-items: flex-end!important
}

.align-items-center {
    -webkit-box-align: center!important;
    align-items: center!important
}

.align-items-baseline {
    -webkit-box-align: baseline!important;
    align-items: baseline!important
}

.align-items-stretch {
    -webkit-box-align: stretch!important;
    align-items: stretch!important
}

.align-content-start {
    align-content: flex-start!important
}

.align-content-end {
    align-content: flex-end!important
}

.align-content-center {
    align-content: center!important
}

.align-content-between {
    align-content: space-between!important
}

.align-content-around {
    align-content: space-around!important
}

.align-content-stretch {
    align-content: stretch!important
}

.align-self-auto {
    align-self: auto!important
}

.align-self-start {
    align-self: flex-start!important
}

.align-self-end {
    align-self: flex-end!important
}

.align-self-center {
    align-self: center!important
}

.align-self-baseline {
    align-self: baseline!important
}

.align-self-stretch {
    align-self: stretch!important
}

@media(min-width: 576px) {
    .flex-sm-row {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: normal!important;
        flex-direction: row!important
    }

    .flex-sm-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        flex-direction: column!important
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-sm-wrap {
        flex-wrap: wrap!important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .flex-sm-fill {
        -webkit-box-flex: 1!important;
        flex: 1 1 auto!important
    }

    .flex-sm-grow-0 {
        -webkit-box-flex: 0!important;
        flex-grow: 0!important
    }

    .flex-sm-grow-1 {
        -webkit-box-flex: 1!important;
        flex-grow: 1!important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1!important
    }

    .justify-content-sm-start {
        -webkit-box-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-sm-end {
        -webkit-box-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-sm-center {
        -webkit-box-pack: center!important;
        justify-content: center!important
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-sm-around {
        justify-content: space-around!important
    }

    .align-items-sm-start {
        -webkit-box-align: start!important;
        align-items: flex-start!important
    }

    .align-items-sm-end {
        -webkit-box-align: end!important;
        align-items: flex-end!important
    }

    .align-items-sm-center {
        -webkit-box-align: center!important;
        align-items: center!important
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-sm-start {
        align-content: flex-start!important
    }

    .align-content-sm-end {
        align-content: flex-end!important
    }

    .align-content-sm-center {
        align-content: center!important
    }

    .align-content-sm-between {
        align-content: space-between!important
    }

    .align-content-sm-around {
        align-content: space-around!important
    }

    .align-content-sm-stretch {
        align-content: stretch!important
    }

    .align-self-sm-auto {
        align-self: auto!important
    }

    .align-self-sm-start {
        align-self: flex-start!important
    }

    .align-self-sm-end {
        align-self: flex-end!important
    }

    .align-self-sm-center {
        align-self: center!important
    }

    .align-self-sm-baseline {
        align-self: baseline!important
    }

    .align-self-sm-stretch {
        align-self: stretch!important
    }
}

@media(min-width: 768px) {
    .flex-md-row {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: normal!important;
        flex-direction: row!important
    }

    .flex-md-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        flex-direction: column!important
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-md-wrap {
        flex-wrap: wrap!important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .flex-md-fill {
        -webkit-box-flex: 1!important;
        flex: 1 1 auto!important
    }

    .flex-md-grow-0 {
        -webkit-box-flex: 0!important;
        flex-grow: 0!important
    }

    .flex-md-grow-1 {
        -webkit-box-flex: 1!important;
        flex-grow: 1!important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1!important
    }

    .justify-content-md-start {
        -webkit-box-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-md-end {
        -webkit-box-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-md-center {
        -webkit-box-pack: center!important;
        justify-content: center!important
    }

    .justify-content-md-between {
        -webkit-box-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-md-around {
        justify-content: space-around!important
    }

    .align-items-md-start {
        -webkit-box-align: start!important;
        align-items: flex-start!important
    }

    .align-items-md-end {
        -webkit-box-align: end!important;
        align-items: flex-end!important
    }

    .align-items-md-center {
        -webkit-box-align: center!important;
        align-items: center!important
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-md-start {
        align-content: flex-start!important
    }

    .align-content-md-end {
        align-content: flex-end!important
    }

    .align-content-md-center {
        align-content: center!important
    }

    .align-content-md-between {
        align-content: space-between!important
    }

    .align-content-md-around {
        align-content: space-around!important
    }

    .align-content-md-stretch {
        align-content: stretch!important
    }

    .align-self-md-auto {
        align-self: auto!important
    }

    .align-self-md-start {
        align-self: flex-start!important
    }

    .align-self-md-end {
        align-self: flex-end!important
    }

    .align-self-md-center {
        align-self: center!important
    }

    .align-self-md-baseline {
        align-self: baseline!important
    }

    .align-self-md-stretch {
        align-self: stretch!important
    }
}

@media(min-width: 992px) {
    .flex-lg-row {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: normal!important;
        flex-direction: row!important
    }

    .flex-lg-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        flex-direction: column!important
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-lg-wrap {
        flex-wrap: wrap!important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .flex-lg-fill {
        -webkit-box-flex: 1!important;
        flex: 1 1 auto!important
    }

    .flex-lg-grow-0 {
        -webkit-box-flex: 0!important;
        flex-grow: 0!important
    }

    .flex-lg-grow-1 {
        -webkit-box-flex: 1!important;
        flex-grow: 1!important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1!important
    }

    .justify-content-lg-start {
        -webkit-box-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-lg-end {
        -webkit-box-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-lg-center {
        -webkit-box-pack: center!important;
        justify-content: center!important
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-lg-around {
        justify-content: space-around!important
    }

    .align-items-lg-start {
        -webkit-box-align: start!important;
        align-items: flex-start!important
    }

    .align-items-lg-end {
        -webkit-box-align: end!important;
        align-items: flex-end!important
    }

    .align-items-lg-center {
        -webkit-box-align: center!important;
        align-items: center!important
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-lg-start {
        align-content: flex-start!important
    }

    .align-content-lg-end {
        align-content: flex-end!important
    }

    .align-content-lg-center {
        align-content: center!important
    }

    .align-content-lg-between {
        align-content: space-between!important
    }

    .align-content-lg-around {
        align-content: space-around!important
    }

    .align-content-lg-stretch {
        align-content: stretch!important
    }

    .align-self-lg-auto {
        align-self: auto!important
    }

    .align-self-lg-start {
        align-self: flex-start!important
    }

    .align-self-lg-end {
        align-self: flex-end!important
    }

    .align-self-lg-center {
        align-self: center!important
    }

    .align-self-lg-baseline {
        align-self: baseline!important
    }

    .align-self-lg-stretch {
        align-self: stretch!important
    }
}

@media(min-width: 1200px) {
    .flex-xl-row {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: normal!important;
        flex-direction: row!important
    }

    .flex-xl-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        flex-direction: column!important
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xl-wrap {
        flex-wrap: wrap!important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .flex-xl-fill {
        -webkit-box-flex: 1!important;
        flex: 1 1 auto!important
    }

    .flex-xl-grow-0 {
        -webkit-box-flex: 0!important;
        flex-grow: 0!important
    }

    .flex-xl-grow-1 {
        -webkit-box-flex: 1!important;
        flex-grow: 1!important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1!important
    }

    .justify-content-xl-start {
        -webkit-box-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xl-end {
        -webkit-box-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xl-center {
        -webkit-box-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xl-around {
        justify-content: space-around!important
    }

    .align-items-xl-start {
        -webkit-box-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xl-end {
        -webkit-box-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xl-center {
        -webkit-box-align: center!important;
        align-items: center!important
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xl-start {
        align-content: flex-start!important
    }

    .align-content-xl-end {
        align-content: flex-end!important
    }

    .align-content-xl-center {
        align-content: center!important
    }

    .align-content-xl-between {
        align-content: space-between!important
    }

    .align-content-xl-around {
        align-content: space-around!important
    }

    .align-content-xl-stretch {
        align-content: stretch!important
    }

    .align-self-xl-auto {
        align-self: auto!important
    }

    .align-self-xl-start {
        align-self: flex-start!important
    }

    .align-self-xl-end {
        align-self: flex-end!important
    }

    .align-self-xl-center {
        align-self: center!important
    }

    .align-self-xl-baseline {
        align-self: baseline!important
    }

    .align-self-xl-stretch {
        align-self: stretch!important
    }
}

@media(min-width: 1540px) {
    .flex-xxl-row {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: normal!important;
        flex-direction: row!important
    }

    .flex-xxl-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        flex-direction: column!important
    }

    .flex-xxl-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xxl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap!important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .flex-xxl-fill {
        -webkit-box-flex: 1!important;
        flex: 1 1 auto!important
    }

    .flex-xxl-grow-0 {
        -webkit-box-flex: 0!important;
        flex-grow: 0!important
    }

    .flex-xxl-grow-1 {
        -webkit-box-flex: 1!important;
        flex-grow: 1!important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1!important
    }

    .justify-content-xxl-start {
        -webkit-box-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xxl-end {
        -webkit-box-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xxl-center {
        -webkit-box-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xxl-between {
        -webkit-box-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xxl-around {
        justify-content: space-around!important
    }

    .align-items-xxl-start {
        -webkit-box-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xxl-end {
        -webkit-box-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xxl-center {
        -webkit-box-align: center!important;
        align-items: center!important
    }

    .align-items-xxl-baseline {
        -webkit-box-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xxl-stretch {
        -webkit-box-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xxl-start {
        align-content: flex-start!important
    }

    .align-content-xxl-end {
        align-content: flex-end!important
    }

    .align-content-xxl-center {
        align-content: center!important
    }

    .align-content-xxl-between {
        align-content: space-between!important
    }

    .align-content-xxl-around {
        align-content: space-around!important
    }

    .align-content-xxl-stretch {
        align-content: stretch!important
    }

    .align-self-xxl-auto {
        align-self: auto!important
    }

    .align-self-xxl-start {
        align-self: flex-start!important
    }

    .align-self-xxl-end {
        align-self: flex-end!important
    }

    .align-self-xxl-center {
        align-self: center!important
    }

    .align-self-xxl-baseline {
        align-self: baseline!important
    }

    .align-self-xxl-stretch {
        align-self: stretch!important
    }
}

.float-left {
    float: left!important
}

.float-right {
    float: right!important
}

.float-none {
    float: none!important
}

@media(min-width: 576px) {
    .float-sm-left {
        float:left!important
    }

    .float-sm-right {
        float: right!important
    }

    .float-sm-none {
        float: none!important
    }
}

@media(min-width: 768px) {
    .float-md-left {
        float:left!important
    }

    .float-md-right {
        float: right!important
    }

    .float-md-none {
        float: none!important
    }
}

@media(min-width: 992px) {
    .float-lg-left {
        float:left!important
    }

    .float-lg-right {
        float: right!important
    }

    .float-lg-none {
        float: none!important
    }
}

@media(min-width: 1200px) {
    .float-xl-left {
        float:left!important
    }

    .float-xl-right {
        float: right!important
    }

    .float-xl-none {
        float: none!important
    }
}

@media(min-width: 1540px) {
    .float-xxl-left {
        float:left!important
    }

    .float-xxl-right {
        float: right!important
    }

    .float-xxl-none {
        float: none!important
    }
}

.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: -webkit-sticky!important;
    position: sticky!important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

@supports((position: -webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position:-webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important
}

.shadow {
    box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)!important
}

.shadow-lg {
    box-shadow: 0 1rem 4rem rgba(0,0,0,.175)!important
}

.shadow-none {
    box-shadow: none!important
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.w-auto {
    width: auto!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.h-auto {
    height: auto!important
}

.mw-100 {
    max-width: 100%!important
}

.mh-100 {
    max-height: 100%!important
}

.min-vw-100 {
    min-width: 100vw!important
}

.min-vh-100 {
    min-height: 100vh!important
}

.vw-100 {
    width: 100vw!important
}

.vh-100 {
    height: 100vh!important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent
}

.m-0 {
    margin: 0!important
}

.mt-0,.my-0 {
    margin-top: 0!important
}

.mr-0,.mx-0 {
    margin-right: 0!important
}

.mb-0,.my-0 {
    margin-bottom: 0!important
}

.ml-0,.mx-0 {
    margin-left: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1,.my-1 {
    margin-top: .25rem!important
}

.mr-1,.mx-1 {
    margin-right: .25rem!important
}

.mb-1,.my-1 {
    margin-bottom: .25rem!important
}

.ml-1,.mx-1 {
    margin-left: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2,.my-2 {
    margin-top: .5rem!important
}

.mr-2,.mx-2 {
    margin-right: .5rem!important
}

.mb-2,.my-2 {
    margin-bottom: .5rem!important
}

.ml-2,.mx-2 {
    margin-left: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.mt-3,.my-3 {
    margin-top: 1rem!important
}

.mr-3,.mx-3 {
    margin-right: 1rem!important
}

.mb-3,.my-3 {
    margin-bottom: 1rem!important
}

.ml-3,.mx-3 {
    margin-left: 1rem!important
}

.m-4 {
    margin: 1.8rem!important
}

.mt-4,.my-4 {
    margin-top: 1.8rem!important
}

.mr-4,.mx-4 {
    margin-right: 1.8rem!important
}

.mb-4,.my-4 {
    margin-bottom: 1.8rem!important
}

.ml-4,.mx-4 {
    margin-left: 1.8rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5,.my-5 {
    margin-top: 3rem!important
}

.mr-5,.mx-5 {
    margin-right: 3rem!important
}

.mb-5,.my-5 {
    margin-bottom: 3rem!important
}

.ml-5,.mx-5 {
    margin-left: 3rem!important
}

.m-6 {
    margin: 4rem!important
}

.mt-6,.my-6 {
    margin-top: 4rem!important
}

.mr-6,.mx-6 {
    margin-right: 4rem!important
}

.mb-6,.my-6 {
    margin-bottom: 4rem!important
}

.ml-6,.mx-6 {
    margin-left: 4rem!important
}

.m-7 {
    margin: 5rem!important
}

.mt-7,.my-7 {
    margin-top: 5rem!important
}

.mr-7,.mx-7 {
    margin-right: 5rem!important
}

.mb-7,.my-7 {
    margin-bottom: 5rem!important
}

.ml-7,.mx-7 {
    margin-left: 5rem!important
}

.m-8 {
    margin: 7.5rem!important
}

.mt-8,.my-8 {
    margin-top: 7.5rem!important
}

.mr-8,.mx-8 {
    margin-right: 7.5rem!important
}

.mb-8,.my-8 {
    margin-bottom: 7.5rem!important
}

.ml-8,.mx-8 {
    margin-left: 7.5rem!important
}

.m-9 {
    margin: 10rem!important
}

.mt-9,.my-9 {
    margin-top: 10rem!important
}

.mr-9,.mx-9 {
    margin-right: 10rem!important
}

.mb-9,.my-9 {
    margin-bottom: 10rem!important
}

.ml-9,.mx-9 {
    margin-left: 10rem!important
}

.m-10 {
    margin: 12.5rem!important
}

.mt-10,.my-10 {
    margin-top: 12.5rem!important
}

.mr-10,.mx-10 {
    margin-right: 12.5rem!important
}

.mb-10,.my-10 {
    margin-bottom: 12.5rem!important
}

.ml-10,.mx-10 {
    margin-left: 12.5rem!important
}

.m-11 {
    margin: 15rem!important
}

.mt-11,.my-11 {
    margin-top: 15rem!important
}

.mr-11,.mx-11 {
    margin-right: 15rem!important
}

.mb-11,.my-11 {
    margin-bottom: 15rem!important
}

.ml-11,.mx-11 {
    margin-left: 15rem!important
}

.p-0 {
    padding: 0!important
}

.pt-0,.py-0 {
    padding-top: 0!important
}

.pr-0,.px-0 {
    padding-right: 0!important
}

.pb-0,.py-0 {
    padding-bottom: 0!important
}

.pl-0,.px-0 {
    padding-left: 0!important
}

.p-1 {
    padding: .25rem!important
}

.pt-1,.py-1 {
    padding-top: .25rem!important
}

.pr-1,.px-1 {
    padding-right: .25rem!important
}

.pb-1,.py-1 {
    padding-bottom: .25rem!important
}

.pl-1,.px-1 {
    padding-left: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.pt-2,.py-2 {
    padding-top: .5rem!important
}

.pr-2,.px-2 {
    padding-right: .5rem!important
}

.pb-2,.py-2 {
    padding-bottom: .5rem!important
}

.pl-2,.px-2 {
    padding-left: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.pt-3,.py-3 {
    padding-top: 1rem!important
}

.pr-3,.px-3 {
    padding-right: 1rem!important
}

.pb-3,.py-3 {
    padding-bottom: 1rem!important
}

.pl-3,.px-3 {
    padding-left: 1rem!important
}

.p-4 {
    padding: 1.8rem!important
}

.pt-4,.py-4 {
    padding-top: 1.8rem!important
}

.pr-4,.px-4 {
    padding-right: 1.8rem!important
}

.pb-4,.py-4 {
    padding-bottom: 1.8rem!important
}

.pl-4,.px-4 {
    padding-left: 1.8rem!important
}

.p-5 {
    padding: 3rem!important
}

.pt-5,.py-5 {
    padding-top: 3rem!important
}

.pr-5,.px-5 {
    padding-right: 3rem!important
}

.pb-5,.py-5 {
    padding-bottom: 3rem!important
}

.pl-5,.px-5 {
    padding-left: 3rem!important
}

.p-6 {
    padding: 4rem!important
}

.pt-6,.py-6 {
    padding-top: 4rem!important
}

.pr-6,.px-6 {
    padding-right: 4rem!important
}

.pb-6,.py-6 {
    padding-bottom: 4rem!important
}

.pl-6,.px-6 {
    padding-left: 4rem!important
}

.p-7 {
    padding: 5rem!important
}

.pt-7,.py-7 {
    padding-top: 5rem!important
}

.pr-7,.px-7 {
    padding-right: 5rem!important
}

.pb-7,.py-7 {
    padding-bottom: 5rem!important
}

.pl-7,.px-7 {
    padding-left: 5rem!important
}

.p-8 {
    padding: 7.5rem!important
}

.pt-8,.py-8 {
    padding-top: 7.5rem!important
}

.pr-8,.px-8 {
    padding-right: 7.5rem!important
}

.pb-8,.py-8 {
    padding-bottom: 7.5rem!important
}

.pl-8,.px-8 {
    padding-left: 7.5rem!important
}

.p-9 {
    padding: 10rem!important
}

.pt-9,.py-9 {
    padding-top: 10rem!important
}

.pr-9,.px-9 {
    padding-right: 10rem!important
}

.pb-9,.py-9 {
    padding-bottom: 10rem!important
}

.pl-9,.px-9 {
    padding-left: 10rem!important
}

.p-10 {
    padding: 12.5rem!important
}

.pt-10,.py-10 {
    padding-top: 12.5rem!important
}

.pr-10,.px-10 {
    padding-right: 12.5rem!important
}

.pb-10,.py-10 {
    padding-bottom: 12.5rem!important
}

.pl-10,.px-10 {
    padding-left: 12.5rem!important
}

.p-11 {
    padding: 15rem!important
}

.pt-11,.py-11 {
    padding-top: 15rem!important
}

.pr-11,.px-11 {
    padding-right: 15rem!important
}

.pb-11,.py-11 {
    padding-bottom: 15rem!important
}

.pl-11,.px-11 {
    padding-left: 15rem!important
}

.m-n1 {
    margin: -.25rem!important
}

.mt-n1,.my-n1 {
    margin-top: -.25rem!important
}

.mr-n1,.mx-n1 {
    margin-right: -.25rem!important
}

.mb-n1,.my-n1 {
    margin-bottom: -.25rem!important
}

.ml-n1,.mx-n1 {
    margin-left: -.25rem!important
}

.m-n2 {
    margin: -.5rem!important
}

.mt-n2,.my-n2 {
    margin-top: -.5rem!important
}

.mr-n2,.mx-n2 {
    margin-right: -.5rem!important
}

.mb-n2,.my-n2 {
    margin-bottom: -.5rem!important
}

.ml-n2,.mx-n2 {
    margin-left: -.5rem!important
}

.m-n3 {
    margin: -1rem!important
}

.mt-n3,.my-n3 {
    margin-top: -1rem!important
}

.mr-n3,.mx-n3 {
    margin-right: -1rem!important
}

.mb-n3,.my-n3 {
    margin-bottom: -1rem!important
}

.ml-n3,.mx-n3 {
    margin-left: -1rem!important
}

.m-n4 {
    margin: -1.8rem!important
}

.mt-n4,.my-n4 {
    margin-top: -1.8rem!important
}

.mr-n4,.mx-n4 {
    margin-right: -1.8rem!important
}

.mb-n4,.my-n4 {
    margin-bottom: -1.8rem!important
}

.ml-n4,.mx-n4 {
    margin-left: -1.8rem!important
}

.m-n5 {
    margin: -3rem!important
}

.mt-n5,.my-n5 {
    margin-top: -3rem!important
}

.mr-n5,.mx-n5 {
    margin-right: -3rem!important
}

.mb-n5,.my-n5 {
    margin-bottom: -3rem!important
}

.ml-n5,.mx-n5 {
    margin-left: -3rem!important
}

.m-n6 {
    margin: -4rem!important
}

.mt-n6,.my-n6 {
    margin-top: -4rem!important
}

.mr-n6,.mx-n6 {
    margin-right: -4rem!important
}

.mb-n6,.my-n6 {
    margin-bottom: -4rem!important
}

.ml-n6,.mx-n6 {
    margin-left: -4rem!important
}

.m-n7 {
    margin: -5rem!important
}

.mt-n7,.my-n7 {
    margin-top: -5rem!important
}

.mr-n7,.mx-n7 {
    margin-right: -5rem!important
}

.mb-n7,.my-n7 {
    margin-bottom: -5rem!important
}

.ml-n7,.mx-n7 {
    margin-left: -5rem!important
}

.m-n8 {
    margin: -7.5rem!important
}

.mt-n8,.my-n8 {
    margin-top: -7.5rem!important
}

.mr-n8,.mx-n8 {
    margin-right: -7.5rem!important
}

.mb-n8,.my-n8 {
    margin-bottom: -7.5rem!important
}

.ml-n8,.mx-n8 {
    margin-left: -7.5rem!important
}

.m-n9 {
    margin: -10rem!important
}

.mt-n9,.my-n9 {
    margin-top: -10rem!important
}

.mr-n9,.mx-n9 {
    margin-right: -10rem!important
}

.mb-n9,.my-n9 {
    margin-bottom: -10rem!important
}

.ml-n9,.mx-n9 {
    margin-left: -10rem!important
}

.m-n10 {
    margin: -12.5rem!important
}

.mt-n10,.my-n10 {
    margin-top: -12.5rem!important
}

.mr-n10,.mx-n10 {
    margin-right: -12.5rem!important
}

.mb-n10,.my-n10 {
    margin-bottom: -12.5rem!important
}

.ml-n10,.mx-n10 {
    margin-left: -12.5rem!important
}

.m-n11 {
    margin: -15rem!important
}

.mt-n11,.my-n11 {
    margin-top: -15rem!important
}

.mr-n11,.mx-n11 {
    margin-right: -15rem!important
}

.mb-n11,.my-n11 {
    margin-bottom: -15rem!important
}

.ml-n11,.mx-n11 {
    margin-left: -15rem!important
}

.m-auto {
    margin: auto!important
}

.mt-auto,.my-auto {
    margin-top: auto!important
}

.mr-auto,.mx-auto {
    margin-right: auto!important
}

.mb-auto,.my-auto {
    margin-bottom: auto!important
}

.ml-auto,.mx-auto {
    margin-left: auto!important
}

@media(min-width: 576px) {
    .m-sm-0 {
        margin:0!important
    }

    .mt-sm-0,.my-sm-0 {
        margin-top: 0!important
    }

    .mr-sm-0,.mx-sm-0 {
        margin-right: 0!important
    }

    .mb-sm-0,.my-sm-0 {
        margin-bottom: 0!important
    }

    .ml-sm-0,.mx-sm-0 {
        margin-left: 0!important
    }

    .m-sm-1 {
        margin: .25rem!important
    }

    .mt-sm-1,.my-sm-1 {
        margin-top: .25rem!important
    }

    .mr-sm-1,.mx-sm-1 {
        margin-right: .25rem!important
    }

    .mb-sm-1,.my-sm-1 {
        margin-bottom: .25rem!important
    }

    .ml-sm-1,.mx-sm-1 {
        margin-left: .25rem!important
    }

    .m-sm-2 {
        margin: .5rem!important
    }

    .mt-sm-2,.my-sm-2 {
        margin-top: .5rem!important
    }

    .mr-sm-2,.mx-sm-2 {
        margin-right: .5rem!important
    }

    .mb-sm-2,.my-sm-2 {
        margin-bottom: .5rem!important
    }

    .ml-sm-2,.mx-sm-2 {
        margin-left: .5rem!important
    }

    .m-sm-3 {
        margin: 1rem!important
    }

    .mt-sm-3,.my-sm-3 {
        margin-top: 1rem!important
    }

    .mr-sm-3,.mx-sm-3 {
        margin-right: 1rem!important
    }

    .mb-sm-3,.my-sm-3 {
        margin-bottom: 1rem!important
    }

    .ml-sm-3,.mx-sm-3 {
        margin-left: 1rem!important
    }

    .m-sm-4 {
        margin: 1.8rem!important
    }

    .mt-sm-4,.my-sm-4 {
        margin-top: 1.8rem!important
    }

    .mr-sm-4,.mx-sm-4 {
        margin-right: 1.8rem!important
    }

    .mb-sm-4,.my-sm-4 {
        margin-bottom: 1.8rem!important
    }

    .ml-sm-4,.mx-sm-4 {
        margin-left: 1.8rem!important
    }

    .m-sm-5 {
        margin: 3rem!important
    }

    .mt-sm-5,.my-sm-5 {
        margin-top: 3rem!important
    }

    .mr-sm-5,.mx-sm-5 {
        margin-right: 3rem!important
    }

    .mb-sm-5,.my-sm-5 {
        margin-bottom: 3rem!important
    }

    .ml-sm-5,.mx-sm-5 {
        margin-left: 3rem!important
    }

    .m-sm-6 {
        margin: 4rem!important
    }

    .mt-sm-6,.my-sm-6 {
        margin-top: 4rem!important
    }

    .mr-sm-6,.mx-sm-6 {
        margin-right: 4rem!important
    }

    .mb-sm-6,.my-sm-6 {
        margin-bottom: 4rem!important
    }

    .ml-sm-6,.mx-sm-6 {
        margin-left: 4rem!important
    }

    .m-sm-7 {
        margin: 5rem!important
    }

    .mt-sm-7,.my-sm-7 {
        margin-top: 5rem!important
    }

    .mr-sm-7,.mx-sm-7 {
        margin-right: 5rem!important
    }

    .mb-sm-7,.my-sm-7 {
        margin-bottom: 5rem!important
    }

    .ml-sm-7,.mx-sm-7 {
        margin-left: 5rem!important
    }

    .m-sm-8 {
        margin: 7.5rem!important
    }

    .mt-sm-8,.my-sm-8 {
        margin-top: 7.5rem!important
    }

    .mr-sm-8,.mx-sm-8 {
        margin-right: 7.5rem!important
    }

    .mb-sm-8,.my-sm-8 {
        margin-bottom: 7.5rem!important
    }

    .ml-sm-8,.mx-sm-8 {
        margin-left: 7.5rem!important
    }

    .m-sm-9 {
        margin: 10rem!important
    }

    .mt-sm-9,.my-sm-9 {
        margin-top: 10rem!important
    }

    .mr-sm-9,.mx-sm-9 {
        margin-right: 10rem!important
    }

    .mb-sm-9,.my-sm-9 {
        margin-bottom: 10rem!important
    }

    .ml-sm-9,.mx-sm-9 {
        margin-left: 10rem!important
    }

    .m-sm-10 {
        margin: 12.5rem!important
    }

    .mt-sm-10,.my-sm-10 {
        margin-top: 12.5rem!important
    }

    .mr-sm-10,.mx-sm-10 {
        margin-right: 12.5rem!important
    }

    .mb-sm-10,.my-sm-10 {
        margin-bottom: 12.5rem!important
    }

    .ml-sm-10,.mx-sm-10 {
        margin-left: 12.5rem!important
    }

    .m-sm-11 {
        margin: 15rem!important
    }

    .mt-sm-11,.my-sm-11 {
        margin-top: 15rem!important
    }

    .mr-sm-11,.mx-sm-11 {
        margin-right: 15rem!important
    }

    .mb-sm-11,.my-sm-11 {
        margin-bottom: 15rem!important
    }

    .ml-sm-11,.mx-sm-11 {
        margin-left: 15rem!important
    }

    .p-sm-0 {
        padding: 0!important
    }

    .pt-sm-0,.py-sm-0 {
        padding-top: 0!important
    }

    .pr-sm-0,.px-sm-0 {
        padding-right: 0!important
    }

    .pb-sm-0,.py-sm-0 {
        padding-bottom: 0!important
    }

    .pl-sm-0,.px-sm-0 {
        padding-left: 0!important
    }

    .p-sm-1 {
        padding: .25rem!important
    }

    .pt-sm-1,.py-sm-1 {
        padding-top: .25rem!important
    }

    .pr-sm-1,.px-sm-1 {
        padding-right: .25rem!important
    }

    .pb-sm-1,.py-sm-1 {
        padding-bottom: .25rem!important
    }

    .pl-sm-1,.px-sm-1 {
        padding-left: .25rem!important
    }

    .p-sm-2 {
        padding: .5rem!important
    }

    .pt-sm-2,.py-sm-2 {
        padding-top: .5rem!important
    }

    .pr-sm-2,.px-sm-2 {
        padding-right: .5rem!important
    }

    .pb-sm-2,.py-sm-2 {
        padding-bottom: .5rem!important
    }

    .pl-sm-2,.px-sm-2 {
        padding-left: .5rem!important
    }

    .p-sm-3 {
        padding: 1rem!important
    }

    .pt-sm-3,.py-sm-3 {
        padding-top: 1rem!important
    }

    .pr-sm-3,.px-sm-3 {
        padding-right: 1rem!important
    }

    .pb-sm-3,.py-sm-3 {
        padding-bottom: 1rem!important
    }

    .pl-sm-3,.px-sm-3 {
        padding-left: 1rem!important
    }

    .p-sm-4 {
        padding: 1.8rem!important
    }

    .pt-sm-4,.py-sm-4 {
        padding-top: 1.8rem!important
    }

    .pr-sm-4,.px-sm-4 {
        padding-right: 1.8rem!important
    }

    .pb-sm-4,.py-sm-4 {
        padding-bottom: 1.8rem!important
    }

    .pl-sm-4,.px-sm-4 {
        padding-left: 1.8rem!important
    }

    .p-sm-5 {
        padding: 3rem!important
    }

    .pt-sm-5,.py-sm-5 {
        padding-top: 3rem!important
    }

    .pr-sm-5,.px-sm-5 {
        padding-right: 3rem!important
    }

    .pb-sm-5,.py-sm-5 {
        padding-bottom: 3rem!important
    }

    .pl-sm-5,.px-sm-5 {
        padding-left: 3rem!important
    }

    .p-sm-6 {
        padding: 4rem!important
    }

    .pt-sm-6,.py-sm-6 {
        padding-top: 4rem!important
    }

    .pr-sm-6,.px-sm-6 {
        padding-right: 4rem!important
    }

    .pb-sm-6,.py-sm-6 {
        padding-bottom: 4rem!important
    }

    .pl-sm-6,.px-sm-6 {
        padding-left: 4rem!important
    }

    .p-sm-7 {
        padding: 5rem!important
    }

    .pt-sm-7,.py-sm-7 {
        padding-top: 5rem!important
    }

    .pr-sm-7,.px-sm-7 {
        padding-right: 5rem!important
    }

    .pb-sm-7,.py-sm-7 {
        padding-bottom: 5rem!important
    }

    .pl-sm-7,.px-sm-7 {
        padding-left: 5rem!important
    }

    .p-sm-8 {
        padding: 7.5rem!important
    }

    .pt-sm-8,.py-sm-8 {
        padding-top: 7.5rem!important
    }

    .pr-sm-8,.px-sm-8 {
        padding-right: 7.5rem!important
    }

    .pb-sm-8,.py-sm-8 {
        padding-bottom: 7.5rem!important
    }

    .pl-sm-8,.px-sm-8 {
        padding-left: 7.5rem!important
    }

    .p-sm-9 {
        padding: 10rem!important
    }

    .pt-sm-9,.py-sm-9 {
        padding-top: 10rem!important
    }

    .pr-sm-9,.px-sm-9 {
        padding-right: 10rem!important
    }

    .pb-sm-9,.py-sm-9 {
        padding-bottom: 10rem!important
    }

    .pl-sm-9,.px-sm-9 {
        padding-left: 10rem!important
    }

    .p-sm-10 {
        padding: 12.5rem!important
    }

    .pt-sm-10,.py-sm-10 {
        padding-top: 12.5rem!important
    }

    .pr-sm-10,.px-sm-10 {
        padding-right: 12.5rem!important
    }

    .pb-sm-10,.py-sm-10 {
        padding-bottom: 12.5rem!important
    }

    .pl-sm-10,.px-sm-10 {
        padding-left: 12.5rem!important
    }

    .p-sm-11 {
        padding: 15rem!important
    }

    .pt-sm-11,.py-sm-11 {
        padding-top: 15rem!important
    }

    .pr-sm-11,.px-sm-11 {
        padding-right: 15rem!important
    }

    .pb-sm-11,.py-sm-11 {
        padding-bottom: 15rem!important
    }

    .pl-sm-11,.px-sm-11 {
        padding-left: 15rem!important
    }

    .m-sm-n1 {
        margin: -.25rem!important
    }

    .mt-sm-n1,.my-sm-n1 {
        margin-top: -.25rem!important
    }

    .mr-sm-n1,.mx-sm-n1 {
        margin-right: -.25rem!important
    }

    .mb-sm-n1,.my-sm-n1 {
        margin-bottom: -.25rem!important
    }

    .ml-sm-n1,.mx-sm-n1 {
        margin-left: -.25rem!important
    }

    .m-sm-n2 {
        margin: -.5rem!important
    }

    .mt-sm-n2,.my-sm-n2 {
        margin-top: -.5rem!important
    }

    .mr-sm-n2,.mx-sm-n2 {
        margin-right: -.5rem!important
    }

    .mb-sm-n2,.my-sm-n2 {
        margin-bottom: -.5rem!important
    }

    .ml-sm-n2,.mx-sm-n2 {
        margin-left: -.5rem!important
    }

    .m-sm-n3 {
        margin: -1rem!important
    }

    .mt-sm-n3,.my-sm-n3 {
        margin-top: -1rem!important
    }

    .mr-sm-n3,.mx-sm-n3 {
        margin-right: -1rem!important
    }

    .mb-sm-n3,.my-sm-n3 {
        margin-bottom: -1rem!important
    }

    .ml-sm-n3,.mx-sm-n3 {
        margin-left: -1rem!important
    }

    .m-sm-n4 {
        margin: -1.8rem!important
    }

    .mt-sm-n4,.my-sm-n4 {
        margin-top: -1.8rem!important
    }

    .mr-sm-n4,.mx-sm-n4 {
        margin-right: -1.8rem!important
    }

    .mb-sm-n4,.my-sm-n4 {
        margin-bottom: -1.8rem!important
    }

    .ml-sm-n4,.mx-sm-n4 {
        margin-left: -1.8rem!important
    }

    .m-sm-n5 {
        margin: -3rem!important
    }

    .mt-sm-n5,.my-sm-n5 {
        margin-top: -3rem!important
    }

    .mr-sm-n5,.mx-sm-n5 {
        margin-right: -3rem!important
    }

    .mb-sm-n5,.my-sm-n5 {
        margin-bottom: -3rem!important
    }

    .ml-sm-n5,.mx-sm-n5 {
        margin-left: -3rem!important
    }

    .m-sm-n6 {
        margin: -4rem!important
    }

    .mt-sm-n6,.my-sm-n6 {
        margin-top: -4rem!important
    }

    .mr-sm-n6,.mx-sm-n6 {
        margin-right: -4rem!important
    }

    .mb-sm-n6,.my-sm-n6 {
        margin-bottom: -4rem!important
    }

    .ml-sm-n6,.mx-sm-n6 {
        margin-left: -4rem!important
    }

    .m-sm-n7 {
        margin: -5rem!important
    }

    .mt-sm-n7,.my-sm-n7 {
        margin-top: -5rem!important
    }

    .mr-sm-n7,.mx-sm-n7 {
        margin-right: -5rem!important
    }

    .mb-sm-n7,.my-sm-n7 {
        margin-bottom: -5rem!important
    }

    .ml-sm-n7,.mx-sm-n7 {
        margin-left: -5rem!important
    }

    .m-sm-n8 {
        margin: -7.5rem!important
    }

    .mt-sm-n8,.my-sm-n8 {
        margin-top: -7.5rem!important
    }

    .mr-sm-n8,.mx-sm-n8 {
        margin-right: -7.5rem!important
    }

    .mb-sm-n8,.my-sm-n8 {
        margin-bottom: -7.5rem!important
    }

    .ml-sm-n8,.mx-sm-n8 {
        margin-left: -7.5rem!important
    }

    .m-sm-n9 {
        margin: -10rem!important
    }

    .mt-sm-n9,.my-sm-n9 {
        margin-top: -10rem!important
    }

    .mr-sm-n9,.mx-sm-n9 {
        margin-right: -10rem!important
    }

    .mb-sm-n9,.my-sm-n9 {
        margin-bottom: -10rem!important
    }

    .ml-sm-n9,.mx-sm-n9 {
        margin-left: -10rem!important
    }

    .m-sm-n10 {
        margin: -12.5rem!important
    }

    .mt-sm-n10,.my-sm-n10 {
        margin-top: -12.5rem!important
    }

    .mr-sm-n10,.mx-sm-n10 {
        margin-right: -12.5rem!important
    }

    .mb-sm-n10,.my-sm-n10 {
        margin-bottom: -12.5rem!important
    }

    .ml-sm-n10,.mx-sm-n10 {
        margin-left: -12.5rem!important
    }

    .m-sm-n11 {
        margin: -15rem!important
    }

    .mt-sm-n11,.my-sm-n11 {
        margin-top: -15rem!important
    }

    .mr-sm-n11,.mx-sm-n11 {
        margin-right: -15rem!important
    }

    .mb-sm-n11,.my-sm-n11 {
        margin-bottom: -15rem!important
    }

    .ml-sm-n11,.mx-sm-n11 {
        margin-left: -15rem!important
    }

    .m-sm-auto {
        margin: auto!important
    }

    .mt-sm-auto,.my-sm-auto {
        margin-top: auto!important
    }

    .mr-sm-auto,.mx-sm-auto {
        margin-right: auto!important
    }

    .mb-sm-auto,.my-sm-auto {
        margin-bottom: auto!important
    }

    .ml-sm-auto,.mx-sm-auto {
        margin-left: auto!important
    }
}

@media(min-width: 768px) {
    .m-md-0 {
        margin:0!important
    }

    .mt-md-0,.my-md-0 {
        margin-top: 0!important
    }

    .mr-md-0,.mx-md-0 {
        margin-right: 0!important
    }

    .mb-md-0,.my-md-0 {
        margin-bottom: 0!important
    }

    .ml-md-0,.mx-md-0 {
        margin-left: 0!important
    }

    .m-md-1 {
        margin: .25rem!important
    }

    .mt-md-1,.my-md-1 {
        margin-top: .25rem!important
    }

    .mr-md-1,.mx-md-1 {
        margin-right: .25rem!important
    }

    .mb-md-1,.my-md-1 {
        margin-bottom: .25rem!important
    }

    .ml-md-1,.mx-md-1 {
        margin-left: .25rem!important
    }

    .m-md-2 {
        margin: .5rem!important
    }

    .mt-md-2,.my-md-2 {
        margin-top: .5rem!important
    }

    .mr-md-2,.mx-md-2 {
        margin-right: .5rem!important
    }

    .mb-md-2,.my-md-2 {
        margin-bottom: .5rem!important
    }

    .ml-md-2,.mx-md-2 {
        margin-left: .5rem!important
    }

    .m-md-3 {
        margin: 1rem!important
    }

    .mt-md-3,.my-md-3 {
        margin-top: 1rem!important
    }

    .mr-md-3,.mx-md-3 {
        margin-right: 1rem!important
    }

    .mb-md-3,.my-md-3 {
        margin-bottom: 1rem!important
    }

    .ml-md-3,.mx-md-3 {
        margin-left: 1rem!important
    }

    .m-md-4 {
        margin: 1.8rem!important
    }

    .mt-md-4,.my-md-4 {
        margin-top: 1.8rem!important
    }

    .mr-md-4,.mx-md-4 {
        margin-right: 1.8rem!important
    }

    .mb-md-4,.my-md-4 {
        margin-bottom: 1.8rem!important
    }

    .ml-md-4,.mx-md-4 {
        margin-left: 1.8rem!important
    }

    .m-md-5 {
        margin: 3rem!important
    }

    .mt-md-5,.my-md-5 {
        margin-top: 3rem!important
    }

    .mr-md-5,.mx-md-5 {
        margin-right: 3rem!important
    }

    .mb-md-5,.my-md-5 {
        margin-bottom: 3rem!important
    }

    .ml-md-5,.mx-md-5 {
        margin-left: 3rem!important
    }

    .m-md-6 {
        margin: 4rem!important
    }

    .mt-md-6,.my-md-6 {
        margin-top: 4rem!important
    }

    .mr-md-6,.mx-md-6 {
        margin-right: 4rem!important
    }

    .mb-md-6,.my-md-6 {
        margin-bottom: 4rem!important
    }

    .ml-md-6,.mx-md-6 {
        margin-left: 4rem!important
    }

    .m-md-7 {
        margin: 5rem!important
    }

    .mt-md-7,.my-md-7 {
        margin-top: 5rem!important
    }

    .mr-md-7,.mx-md-7 {
        margin-right: 5rem!important
    }

    .mb-md-7,.my-md-7 {
        margin-bottom: 5rem!important
    }

    .ml-md-7,.mx-md-7 {
        margin-left: 5rem!important
    }

    .m-md-8 {
        margin: 7.5rem!important
    }

    .mt-md-8,.my-md-8 {
        margin-top: 7.5rem!important
    }

    .mr-md-8,.mx-md-8 {
        margin-right: 7.5rem!important
    }

    .mb-md-8,.my-md-8 {
        margin-bottom: 7.5rem!important
    }

    .ml-md-8,.mx-md-8 {
        margin-left: 7.5rem!important
    }

    .m-md-9 {
        margin: 10rem!important
    }

    .mt-md-9,.my-md-9 {
        margin-top: 10rem!important
    }

    .mr-md-9,.mx-md-9 {
        margin-right: 10rem!important
    }

    .mb-md-9,.my-md-9 {
        margin-bottom: 10rem!important
    }

    .ml-md-9,.mx-md-9 {
        margin-left: 10rem!important
    }

    .m-md-10 {
        margin: 12.5rem!important
    }

    .mt-md-10,.my-md-10 {
        margin-top: 12.5rem!important
    }

    .mr-md-10,.mx-md-10 {
        margin-right: 12.5rem!important
    }

    .mb-md-10,.my-md-10 {
        margin-bottom: 12.5rem!important
    }

    .ml-md-10,.mx-md-10 {
        margin-left: 12.5rem!important
    }

    .m-md-11 {
        margin: 15rem!important
    }

    .mt-md-11,.my-md-11 {
        margin-top: 15rem!important
    }

    .mr-md-11,.mx-md-11 {
        margin-right: 15rem!important
    }

    .mb-md-11,.my-md-11 {
        margin-bottom: 15rem!important
    }

    .ml-md-11,.mx-md-11 {
        margin-left: 15rem!important
    }

    .p-md-0 {
        padding: 0!important
    }

    .pt-md-0,.py-md-0 {
        padding-top: 0!important
    }

    .pr-md-0,.px-md-0 {
        padding-right: 0!important
    }

    .pb-md-0,.py-md-0 {
        padding-bottom: 0!important
    }

    .pl-md-0,.px-md-0 {
        padding-left: 0!important
    }

    .p-md-1 {
        padding: .25rem!important
    }

    .pt-md-1,.py-md-1 {
        padding-top: .25rem!important
    }

    .pr-md-1,.px-md-1 {
        padding-right: .25rem!important
    }

    .pb-md-1,.py-md-1 {
        padding-bottom: .25rem!important
    }

    .pl-md-1,.px-md-1 {
        padding-left: .25rem!important
    }

    .p-md-2 {
        padding: .5rem!important
    }

    .pt-md-2,.py-md-2 {
        padding-top: .5rem!important
    }

    .pr-md-2,.px-md-2 {
        padding-right: .5rem!important
    }

    .pb-md-2,.py-md-2 {
        padding-bottom: .5rem!important
    }

    .pl-md-2,.px-md-2 {
        padding-left: .5rem!important
    }

    .p-md-3 {
        padding: 1rem!important
    }

    .pt-md-3,.py-md-3 {
        padding-top: 1rem!important
    }

    .pr-md-3,.px-md-3 {
        padding-right: 1rem!important
    }

    .pb-md-3,.py-md-3 {
        padding-bottom: 1rem!important
    }

    .pl-md-3,.px-md-3 {
        padding-left: 1rem!important
    }

    .p-md-4 {
        padding: 1.8rem!important
    }

    .pt-md-4,.py-md-4 {
        padding-top: 1.8rem!important
    }

    .pr-md-4,.px-md-4 {
        padding-right: 1.8rem!important
    }

    .pb-md-4,.py-md-4 {
        padding-bottom: 1.8rem!important
    }

    .pl-md-4,.px-md-4 {
        padding-left: 1.8rem!important
    }

    .p-md-5 {
        padding: 3rem!important
    }

    .pt-md-5,.py-md-5 {
        padding-top: 3rem!important
    }

    .pr-md-5,.px-md-5 {
        padding-right: 3rem!important
    }

    .pb-md-5,.py-md-5 {
        padding-bottom: 3rem!important
    }

    .pl-md-5,.px-md-5 {
        padding-left: 3rem!important
    }

    .p-md-6 {
        padding: 4rem!important
    }

    .pt-md-6,.py-md-6 {
        padding-top: 4rem!important
    }

    .pr-md-6,.px-md-6 {
        padding-right: 4rem!important
    }

    .pb-md-6,.py-md-6 {
        padding-bottom: 4rem!important
    }

    .pl-md-6,.px-md-6 {
        padding-left: 4rem!important
    }

    .p-md-7 {
        padding: 5rem!important
    }

    .pt-md-7,.py-md-7 {
        padding-top: 5rem!important
    }

    .pr-md-7,.px-md-7 {
        padding-right: 5rem!important
    }

    .pb-md-7,.py-md-7 {
        padding-bottom: 5rem!important
    }

    .pl-md-7,.px-md-7 {
        padding-left: 5rem!important
    }

    .p-md-8 {
        padding: 7.5rem!important
    }

    .pt-md-8,.py-md-8 {
        padding-top: 7.5rem!important
    }

    .pr-md-8,.px-md-8 {
        padding-right: 7.5rem!important
    }

    .pb-md-8,.py-md-8 {
        padding-bottom: 7.5rem!important
    }

    .pl-md-8,.px-md-8 {
        padding-left: 7.5rem!important
    }

    .p-md-9 {
        padding: 10rem!important
    }

    .pt-md-9,.py-md-9 {
        padding-top: 10rem!important
    }

    .pr-md-9,.px-md-9 {
        padding-right: 10rem!important
    }

    .pb-md-9,.py-md-9 {
        padding-bottom: 10rem!important
    }

    .pl-md-9,.px-md-9 {
        padding-left: 10rem!important
    }

    .p-md-10 {
        padding: 12.5rem!important
    }

    .pt-md-10,.py-md-10 {
        padding-top: 12.5rem!important
    }

    .pr-md-10,.px-md-10 {
        padding-right: 12.5rem!important
    }

    .pb-md-10,.py-md-10 {
        padding-bottom: 12.5rem!important
    }

    .pl-md-10,.px-md-10 {
        padding-left: 12.5rem!important
    }

    .p-md-11 {
        padding: 15rem!important
    }

    .pt-md-11,.py-md-11 {
        padding-top: 15rem!important
    }

    .pr-md-11,.px-md-11 {
        padding-right: 15rem!important
    }

    .pb-md-11,.py-md-11 {
        padding-bottom: 15rem!important
    }

    .pl-md-11,.px-md-11 {
        padding-left: 15rem!important
    }

    .m-md-n1 {
        margin: -.25rem!important
    }

    .mt-md-n1,.my-md-n1 {
        margin-top: -.25rem!important
    }

    .mr-md-n1,.mx-md-n1 {
        margin-right: -.25rem!important
    }

    .mb-md-n1,.my-md-n1 {
        margin-bottom: -.25rem!important
    }

    .ml-md-n1,.mx-md-n1 {
        margin-left: -.25rem!important
    }

    .m-md-n2 {
        margin: -.5rem!important
    }

    .mt-md-n2,.my-md-n2 {
        margin-top: -.5rem!important
    }

    .mr-md-n2,.mx-md-n2 {
        margin-right: -.5rem!important
    }

    .mb-md-n2,.my-md-n2 {
        margin-bottom: -.5rem!important
    }

    .ml-md-n2,.mx-md-n2 {
        margin-left: -.5rem!important
    }

    .m-md-n3 {
        margin: -1rem!important
    }

    .mt-md-n3,.my-md-n3 {
        margin-top: -1rem!important
    }

    .mr-md-n3,.mx-md-n3 {
        margin-right: -1rem!important
    }

    .mb-md-n3,.my-md-n3 {
        margin-bottom: -1rem!important
    }

    .ml-md-n3,.mx-md-n3 {
        margin-left: -1rem!important
    }

    .m-md-n4 {
        margin: -1.8rem!important
    }

    .mt-md-n4,.my-md-n4 {
        margin-top: -1.8rem!important
    }

    .mr-md-n4,.mx-md-n4 {
        margin-right: -1.8rem!important
    }

    .mb-md-n4,.my-md-n4 {
        margin-bottom: -1.8rem!important
    }

    .ml-md-n4,.mx-md-n4 {
        margin-left: -1.8rem!important
    }

    .m-md-n5 {
        margin: -3rem!important
    }

    .mt-md-n5,.my-md-n5 {
        margin-top: -3rem!important
    }

    .mr-md-n5,.mx-md-n5 {
        margin-right: -3rem!important
    }

    .mb-md-n5,.my-md-n5 {
        margin-bottom: -3rem!important
    }

    .ml-md-n5,.mx-md-n5 {
        margin-left: -3rem!important
    }

    .m-md-n6 {
        margin: -4rem!important
    }

    .mt-md-n6,.my-md-n6 {
        margin-top: -4rem!important
    }

    .mr-md-n6,.mx-md-n6 {
        margin-right: -4rem!important
    }

    .mb-md-n6,.my-md-n6 {
        margin-bottom: -4rem!important
    }

    .ml-md-n6,.mx-md-n6 {
        margin-left: -4rem!important
    }

    .m-md-n7 {
        margin: -5rem!important
    }

    .mt-md-n7,.my-md-n7 {
        margin-top: -5rem!important
    }

    .mr-md-n7,.mx-md-n7 {
        margin-right: -5rem!important
    }

    .mb-md-n7,.my-md-n7 {
        margin-bottom: -5rem!important
    }

    .ml-md-n7,.mx-md-n7 {
        margin-left: -5rem!important
    }

    .m-md-n8 {
        margin: -7.5rem!important
    }

    .mt-md-n8,.my-md-n8 {
        margin-top: -7.5rem!important
    }

    .mr-md-n8,.mx-md-n8 {
        margin-right: -7.5rem!important
    }

    .mb-md-n8,.my-md-n8 {
        margin-bottom: -7.5rem!important
    }

    .ml-md-n8,.mx-md-n8 {
        margin-left: -7.5rem!important
    }

    .m-md-n9 {
        margin: -10rem!important
    }

    .mt-md-n9,.my-md-n9 {
        margin-top: -10rem!important
    }

    .mr-md-n9,.mx-md-n9 {
        margin-right: -10rem!important
    }

    .mb-md-n9,.my-md-n9 {
        margin-bottom: -10rem!important
    }

    .ml-md-n9,.mx-md-n9 {
        margin-left: -10rem!important
    }

    .m-md-n10 {
        margin: -12.5rem!important
    }

    .mt-md-n10,.my-md-n10 {
        margin-top: -12.5rem!important
    }

    .mr-md-n10,.mx-md-n10 {
        margin-right: -12.5rem!important
    }

    .mb-md-n10,.my-md-n10 {
        margin-bottom: -12.5rem!important
    }

    .ml-md-n10,.mx-md-n10 {
        margin-left: -12.5rem!important
    }

    .m-md-n11 {
        margin: -15rem!important
    }

    .mt-md-n11,.my-md-n11 {
        margin-top: -15rem!important
    }

    .mr-md-n11,.mx-md-n11 {
        margin-right: -15rem!important
    }

    .mb-md-n11,.my-md-n11 {
        margin-bottom: -15rem!important
    }

    .ml-md-n11,.mx-md-n11 {
        margin-left: -15rem!important
    }

    .m-md-auto {
        margin: auto!important
    }

    .mt-md-auto,.my-md-auto {
        margin-top: auto!important
    }

    .mr-md-auto,.mx-md-auto {
        margin-right: auto!important
    }

    .mb-md-auto,.my-md-auto {
        margin-bottom: auto!important
    }

    .ml-md-auto,.mx-md-auto {
        margin-left: auto!important
    }
}

@media(min-width: 992px) {
    .m-lg-0 {
        margin:0!important
    }

    .mt-lg-0,.my-lg-0 {
        margin-top: 0!important
    }

    .mr-lg-0,.mx-lg-0 {
        margin-right: 0!important
    }

    .mb-lg-0,.my-lg-0 {
        margin-bottom: 0!important
    }

    .ml-lg-0,.mx-lg-0 {
        margin-left: 0!important
    }

    .m-lg-1 {
        margin: .25rem!important
    }

    .mt-lg-1,.my-lg-1 {
        margin-top: .25rem!important
    }

    .mr-lg-1,.mx-lg-1 {
        margin-right: .25rem!important
    }

    .mb-lg-1,.my-lg-1 {
        margin-bottom: .25rem!important
    }

    .ml-lg-1,.mx-lg-1 {
        margin-left: .25rem!important
    }

    .m-lg-2 {
        margin: .5rem!important
    }

    .mt-lg-2,.my-lg-2 {
        margin-top: .5rem!important
    }

    .mr-lg-2,.mx-lg-2 {
        margin-right: .5rem!important
    }

    .mb-lg-2,.my-lg-2 {
        margin-bottom: .5rem!important
    }

    .ml-lg-2,.mx-lg-2 {
        margin-left: .5rem!important
    }

    .m-lg-3 {
        margin: 1rem!important
    }

    .mt-lg-3,.my-lg-3 {
        margin-top: 1rem!important
    }

    .mr-lg-3,.mx-lg-3 {
        margin-right: 1rem!important
    }

    .mb-lg-3,.my-lg-3 {
        margin-bottom: 1rem!important
    }

    .ml-lg-3,.mx-lg-3 {
        margin-left: 1rem!important
    }

    .m-lg-4 {
        margin: 1.8rem!important
    }

    .mt-lg-4,.my-lg-4 {
        margin-top: 1.8rem!important
    }

    .mr-lg-4,.mx-lg-4 {
        margin-right: 1.8rem!important
    }

    .mb-lg-4,.my-lg-4 {
        margin-bottom: 1.8rem!important
    }

    .ml-lg-4,.mx-lg-4 {
        margin-left: 1.8rem!important
    }

    .m-lg-5 {
        margin: 3rem!important
    }

    .mt-lg-5,.my-lg-5 {
        margin-top: 3rem!important
    }

    .mr-lg-5,.mx-lg-5 {
        margin-right: 3rem!important
    }

    .mb-lg-5,.my-lg-5 {
        margin-bottom: 3rem!important
    }

    .ml-lg-5,.mx-lg-5 {
        margin-left: 3rem!important
    }

    .m-lg-6 {
        margin: 4rem!important
    }

    .mt-lg-6,.my-lg-6 {
        margin-top: 4rem!important
    }

    .mr-lg-6,.mx-lg-6 {
        margin-right: 4rem!important
    }

    .mb-lg-6,.my-lg-6 {
        margin-bottom: 4rem!important
    }

    .ml-lg-6,.mx-lg-6 {
        margin-left: 4rem!important
    }

    .m-lg-7 {
        margin: 5rem!important
    }

    .mt-lg-7,.my-lg-7 {
        margin-top: 5rem!important
    }

    .mr-lg-7,.mx-lg-7 {
        margin-right: 5rem!important
    }

    .mb-lg-7,.my-lg-7 {
        margin-bottom: 5rem!important
    }

    .ml-lg-7,.mx-lg-7 {
        margin-left: 5rem!important
    }

    .m-lg-8 {
        margin: 7.5rem!important
    }

    .mt-lg-8,.my-lg-8 {
        margin-top: 7.5rem!important
    }

    .mr-lg-8,.mx-lg-8 {
        margin-right: 7.5rem!important
    }

    .mb-lg-8,.my-lg-8 {
        margin-bottom: 7.5rem!important
    }

    .ml-lg-8,.mx-lg-8 {
        margin-left: 7.5rem!important
    }

    .m-lg-9 {
        margin: 10rem!important
    }

    .mt-lg-9,.my-lg-9 {
        margin-top: 10rem!important
    }

    .mr-lg-9,.mx-lg-9 {
        margin-right: 10rem!important
    }

    .mb-lg-9,.my-lg-9 {
        margin-bottom: 10rem!important
    }

    .ml-lg-9,.mx-lg-9 {
        margin-left: 10rem!important
    }

    .m-lg-10 {
        margin: 12.5rem!important
    }

    .mt-lg-10,.my-lg-10 {
        margin-top: 12.5rem!important
    }

    .mr-lg-10,.mx-lg-10 {
        margin-right: 12.5rem!important
    }

    .mb-lg-10,.my-lg-10 {
        margin-bottom: 12.5rem!important
    }

    .ml-lg-10,.mx-lg-10 {
        margin-left: 12.5rem!important
    }

    .m-lg-11 {
        margin: 15rem!important
    }

    .mt-lg-11,.my-lg-11 {
        margin-top: 15rem!important
    }

    .mr-lg-11,.mx-lg-11 {
        margin-right: 15rem!important
    }

    .mb-lg-11,.my-lg-11 {
        margin-bottom: 15rem!important
    }

    .ml-lg-11,.mx-lg-11 {
        margin-left: 15rem!important
    }

    .p-lg-0 {
        padding: 0!important
    }

    .pt-lg-0,.py-lg-0 {
        padding-top: 0!important
    }

    .pr-lg-0,.px-lg-0 {
        padding-right: 0!important
    }

    .pb-lg-0,.py-lg-0 {
        padding-bottom: 0!important
    }

    .pl-lg-0,.px-lg-0 {
        padding-left: 0!important
    }

    .p-lg-1 {
        padding: .25rem!important
    }

    .pt-lg-1,.py-lg-1 {
        padding-top: .25rem!important
    }

    .pr-lg-1,.px-lg-1 {
        padding-right: .25rem!important
    }

    .pb-lg-1,.py-lg-1 {
        padding-bottom: .25rem!important
    }

    .pl-lg-1,.px-lg-1 {
        padding-left: .25rem!important
    }

    .p-lg-2 {
        padding: .5rem!important
    }

    .pt-lg-2,.py-lg-2 {
        padding-top: .5rem!important
    }

    .pr-lg-2,.px-lg-2 {
        padding-right: .5rem!important
    }

    .pb-lg-2,.py-lg-2 {
        padding-bottom: .5rem!important
    }

    .pl-lg-2,.px-lg-2 {
        padding-left: .5rem!important
    }

    .p-lg-3 {
        padding: 1rem!important
    }

    .pt-lg-3,.py-lg-3 {
        padding-top: 1rem!important
    }

    .pr-lg-3,.px-lg-3 {
        padding-right: 1rem!important
    }

    .pb-lg-3,.py-lg-3 {
        padding-bottom: 1rem!important
    }

    .pl-lg-3,.px-lg-3 {
        padding-left: 1rem!important
    }

    .p-lg-4 {
        padding: 1.8rem!important
    }

    .pt-lg-4,.py-lg-4 {
        padding-top: 1.8rem!important
    }

    .pr-lg-4,.px-lg-4 {
        padding-right: 1.8rem!important
    }

    .pb-lg-4,.py-lg-4 {
        padding-bottom: 1.8rem!important
    }

    .pl-lg-4,.px-lg-4 {
        padding-left: 1.8rem!important
    }

    .p-lg-5 {
        padding: 3rem!important
    }

    .pt-lg-5,.py-lg-5 {
        padding-top: 3rem!important
    }

    .pr-lg-5,.px-lg-5 {
        padding-right: 3rem!important
    }

    .pb-lg-5,.py-lg-5 {
        padding-bottom: 3rem!important
    }

    .pl-lg-5,.px-lg-5 {
        padding-left: 3rem!important
    }

    .p-lg-6 {
        padding: 4rem!important
    }

    .pt-lg-6,.py-lg-6 {
        padding-top: 4rem!important
    }

    .pr-lg-6,.px-lg-6 {
        padding-right: 4rem!important
    }

    .pb-lg-6,.py-lg-6 {
        padding-bottom: 4rem!important
    }

    .pl-lg-6,.px-lg-6 {
        padding-left: 4rem!important
    }

    .p-lg-7 {
        padding: 5rem!important
    }

    .pt-lg-7,.py-lg-7 {
        padding-top: 5rem!important
    }

    .pr-lg-7,.px-lg-7 {
        padding-right: 5rem!important
    }

    .pb-lg-7,.py-lg-7 {
        padding-bottom: 5rem!important
    }

    .pl-lg-7,.px-lg-7 {
        padding-left: 5rem!important
    }

    .p-lg-8 {
        padding: 7.5rem!important
    }

    .pt-lg-8,.py-lg-8 {
        padding-top: 7.5rem!important
    }

    .pr-lg-8,.px-lg-8 {
        padding-right: 7.5rem!important
    }

    .pb-lg-8,.py-lg-8 {
        padding-bottom: 7.5rem!important
    }

    .pl-lg-8,.px-lg-8 {
        padding-left: 7.5rem!important
    }

    .p-lg-9 {
        padding: 10rem!important
    }

    .pt-lg-9,.py-lg-9 {
        padding-top: 10rem!important
    }

    .pr-lg-9,.px-lg-9 {
        padding-right: 10rem!important
    }

    .pb-lg-9,.py-lg-9 {
        padding-bottom: 10rem!important
    }

    .pl-lg-9,.px-lg-9 {
        padding-left: 10rem!important
    }

    .p-lg-10 {
        padding: 12.5rem!important
    }

    .pt-lg-10,.py-lg-10 {
        padding-top: 12.5rem!important
    }

    .pr-lg-10,.px-lg-10 {
        padding-right: 12.5rem!important
    }

    .pb-lg-10,.py-lg-10 {
        padding-bottom: 12.5rem!important
    }

    .pl-lg-10,.px-lg-10 {
        padding-left: 12.5rem!important
    }

    .p-lg-11 {
        padding: 15rem!important
    }

    .pt-lg-11,.py-lg-11 {
        padding-top: 15rem!important
    }

    .pr-lg-11,.px-lg-11 {
        padding-right: 15rem!important
    }

    .pb-lg-11,.py-lg-11 {
        padding-bottom: 15rem!important
    }

    .pl-lg-11,.px-lg-11 {
        padding-left: 15rem!important
    }

    .m-lg-n1 {
        margin: -.25rem!important
    }

    .mt-lg-n1,.my-lg-n1 {
        margin-top: -.25rem!important
    }

    .mr-lg-n1,.mx-lg-n1 {
        margin-right: -.25rem!important
    }

    .mb-lg-n1,.my-lg-n1 {
        margin-bottom: -.25rem!important
    }

    .ml-lg-n1,.mx-lg-n1 {
        margin-left: -.25rem!important
    }

    .m-lg-n2 {
        margin: -.5rem!important
    }

    .mt-lg-n2,.my-lg-n2 {
        margin-top: -.5rem!important
    }

    .mr-lg-n2,.mx-lg-n2 {
        margin-right: -.5rem!important
    }

    .mb-lg-n2,.my-lg-n2 {
        margin-bottom: -.5rem!important
    }

    .ml-lg-n2,.mx-lg-n2 {
        margin-left: -.5rem!important
    }

    .m-lg-n3 {
        margin: -1rem!important
    }

    .mt-lg-n3,.my-lg-n3 {
        margin-top: -1rem!important
    }

    .mr-lg-n3,.mx-lg-n3 {
        margin-right: -1rem!important
    }

    .mb-lg-n3,.my-lg-n3 {
        margin-bottom: -1rem!important
    }

    .ml-lg-n3,.mx-lg-n3 {
        margin-left: -1rem!important
    }

    .m-lg-n4 {
        margin: -1.8rem!important
    }

    .mt-lg-n4,.my-lg-n4 {
        margin-top: -1.8rem!important
    }

    .mr-lg-n4,.mx-lg-n4 {
        margin-right: -1.8rem!important
    }

    .mb-lg-n4,.my-lg-n4 {
        margin-bottom: -1.8rem!important
    }

    .ml-lg-n4,.mx-lg-n4 {
        margin-left: -1.8rem!important
    }

    .m-lg-n5 {
        margin: -3rem!important
    }

    .mt-lg-n5,.my-lg-n5 {
        margin-top: -3rem!important
    }

    .mr-lg-n5,.mx-lg-n5 {
        margin-right: -3rem!important
    }

    .mb-lg-n5,.my-lg-n5 {
        margin-bottom: -3rem!important
    }

    .ml-lg-n5,.mx-lg-n5 {
        margin-left: -3rem!important
    }

    .m-lg-n6 {
        margin: -4rem!important
    }

    .mt-lg-n6,.my-lg-n6 {
        margin-top: -4rem!important
    }

    .mr-lg-n6,.mx-lg-n6 {
        margin-right: -4rem!important
    }

    .mb-lg-n6,.my-lg-n6 {
        margin-bottom: -4rem!important
    }

    .ml-lg-n6,.mx-lg-n6 {
        margin-left: -4rem!important
    }

    .m-lg-n7 {
        margin: -5rem!important
    }

    .mt-lg-n7,.my-lg-n7 {
        margin-top: -5rem!important
    }

    .mr-lg-n7,.mx-lg-n7 {
        margin-right: -5rem!important
    }

    .mb-lg-n7,.my-lg-n7 {
        margin-bottom: -5rem!important
    }

    .ml-lg-n7,.mx-lg-n7 {
        margin-left: -5rem!important
    }

    .m-lg-n8 {
        margin: -7.5rem!important
    }

    .mt-lg-n8,.my-lg-n8 {
        margin-top: -7.5rem!important
    }

    .mr-lg-n8,.mx-lg-n8 {
        margin-right: -7.5rem!important
    }

    .mb-lg-n8,.my-lg-n8 {
        margin-bottom: -7.5rem!important
    }

    .ml-lg-n8,.mx-lg-n8 {
        margin-left: -7.5rem!important
    }

    .m-lg-n9 {
        margin: -10rem!important
    }

    .mt-lg-n9,.my-lg-n9 {
        margin-top: -10rem!important
    }

    .mr-lg-n9,.mx-lg-n9 {
        margin-right: -10rem!important
    }

    .mb-lg-n9,.my-lg-n9 {
        margin-bottom: -10rem!important
    }

    .ml-lg-n9,.mx-lg-n9 {
        margin-left: -10rem!important
    }

    .m-lg-n10 {
        margin: -12.5rem!important
    }

    .mt-lg-n10,.my-lg-n10 {
        margin-top: -12.5rem!important
    }

    .mr-lg-n10,.mx-lg-n10 {
        margin-right: -12.5rem!important
    }

    .mb-lg-n10,.my-lg-n10 {
        margin-bottom: -12.5rem!important
    }

    .ml-lg-n10,.mx-lg-n10 {
        margin-left: -12.5rem!important
    }

    .m-lg-n11 {
        margin: -15rem!important
    }

    .mt-lg-n11,.my-lg-n11 {
        margin-top: -15rem!important
    }

    .mr-lg-n11,.mx-lg-n11 {
        margin-right: -15rem!important
    }

    .mb-lg-n11,.my-lg-n11 {
        margin-bottom: -15rem!important
    }

    .ml-lg-n11,.mx-lg-n11 {
        margin-left: -15rem!important
    }

    .m-lg-auto {
        margin: auto!important
    }

    .mt-lg-auto,.my-lg-auto {
        margin-top: auto!important
    }

    .mr-lg-auto,.mx-lg-auto {
        margin-right: auto!important
    }

    .mb-lg-auto,.my-lg-auto {
        margin-bottom: auto!important
    }

    .ml-lg-auto,.mx-lg-auto {
        margin-left: auto!important
    }
}

@media(min-width: 1200px) {
    .m-xl-0 {
        margin:0!important
    }

    .mt-xl-0,.my-xl-0 {
        margin-top: 0!important
    }

    .mr-xl-0,.mx-xl-0 {
        margin-right: 0!important
    }

    .mb-xl-0,.my-xl-0 {
        margin-bottom: 0!important
    }

    .ml-xl-0,.mx-xl-0 {
        margin-left: 0!important
    }

    .m-xl-1 {
        margin: .25rem!important
    }

    .mt-xl-1,.my-xl-1 {
        margin-top: .25rem!important
    }

    .mr-xl-1,.mx-xl-1 {
        margin-right: .25rem!important
    }

    .mb-xl-1,.my-xl-1 {
        margin-bottom: .25rem!important
    }

    .ml-xl-1,.mx-xl-1 {
        margin-left: .25rem!important
    }

    .m-xl-2 {
        margin: .5rem!important
    }

    .mt-xl-2,.my-xl-2 {
        margin-top: .5rem!important
    }

    .mr-xl-2,.mx-xl-2 {
        margin-right: .5rem!important
    }

    .mb-xl-2,.my-xl-2 {
        margin-bottom: .5rem!important
    }

    .ml-xl-2,.mx-xl-2 {
        margin-left: .5rem!important
    }

    .m-xl-3 {
        margin: 1rem!important
    }

    .mt-xl-3,.my-xl-3 {
        margin-top: 1rem!important
    }

    .mr-xl-3,.mx-xl-3 {
        margin-right: 1rem!important
    }

    .mb-xl-3,.my-xl-3 {
        margin-bottom: 1rem!important
    }

    .ml-xl-3,.mx-xl-3 {
        margin-left: 1rem!important
    }

    .m-xl-4 {
        margin: 1.8rem!important
    }

    .mt-xl-4,.my-xl-4 {
        margin-top: 1.8rem!important
    }

    .mr-xl-4,.mx-xl-4 {
        margin-right: 1.8rem!important
    }

    .mb-xl-4,.my-xl-4 {
        margin-bottom: 1.8rem!important
    }

    .ml-xl-4,.mx-xl-4 {
        margin-left: 1.8rem!important
    }

    .m-xl-5 {
        margin: 3rem!important
    }

    .mt-xl-5,.my-xl-5 {
        margin-top: 3rem!important
    }

    .mr-xl-5,.mx-xl-5 {
        margin-right: 3rem!important
    }

    .mb-xl-5,.my-xl-5 {
        margin-bottom: 3rem!important
    }

    .ml-xl-5,.mx-xl-5 {
        margin-left: 3rem!important
    }

    .m-xl-6 {
        margin: 4rem!important
    }

    .mt-xl-6,.my-xl-6 {
        margin-top: 4rem!important
    }

    .mr-xl-6,.mx-xl-6 {
        margin-right: 4rem!important
    }

    .mb-xl-6,.my-xl-6 {
        margin-bottom: 4rem!important
    }

    .ml-xl-6,.mx-xl-6 {
        margin-left: 4rem!important
    }

    .m-xl-7 {
        margin: 5rem!important
    }

    .mt-xl-7,.my-xl-7 {
        margin-top: 5rem!important
    }

    .mr-xl-7,.mx-xl-7 {
        margin-right: 5rem!important
    }

    .mb-xl-7,.my-xl-7 {
        margin-bottom: 5rem!important
    }

    .ml-xl-7,.mx-xl-7 {
        margin-left: 5rem!important
    }

    .m-xl-8 {
        margin: 7.5rem!important
    }

    .mt-xl-8,.my-xl-8 {
        margin-top: 7.5rem!important
    }

    .mr-xl-8,.mx-xl-8 {
        margin-right: 7.5rem!important
    }

    .mb-xl-8,.my-xl-8 {
        margin-bottom: 7.5rem!important
    }

    .ml-xl-8,.mx-xl-8 {
        margin-left: 7.5rem!important
    }

    .m-xl-9 {
        margin: 10rem!important
    }

    .mt-xl-9,.my-xl-9 {
        margin-top: 10rem!important
    }

    .mr-xl-9,.mx-xl-9 {
        margin-right: 10rem!important
    }

    .mb-xl-9,.my-xl-9 {
        margin-bottom: 10rem!important
    }

    .ml-xl-9,.mx-xl-9 {
        margin-left: 10rem!important
    }

    .m-xl-10 {
        margin: 12.5rem!important
    }

    .mt-xl-10,.my-xl-10 {
        margin-top: 12.5rem!important
    }

    .mr-xl-10,.mx-xl-10 {
        margin-right: 12.5rem!important
    }

    .mb-xl-10,.my-xl-10 {
        margin-bottom: 12.5rem!important
    }

    .ml-xl-10,.mx-xl-10 {
        margin-left: 12.5rem!important
    }

    .m-xl-11 {
        margin: 15rem!important
    }

    .mt-xl-11,.my-xl-11 {
        margin-top: 15rem!important
    }

    .mr-xl-11,.mx-xl-11 {
        margin-right: 15rem!important
    }

    .mb-xl-11,.my-xl-11 {
        margin-bottom: 15rem!important
    }

    .ml-xl-11,.mx-xl-11 {
        margin-left: 15rem!important
    }

    .p-xl-0 {
        padding: 0!important
    }

    .pt-xl-0,.py-xl-0 {
        padding-top: 0!important
    }

    .pr-xl-0,.px-xl-0 {
        padding-right: 0!important
    }

    .pb-xl-0,.py-xl-0 {
        padding-bottom: 0!important
    }

    .pl-xl-0,.px-xl-0 {
        padding-left: 0!important
    }

    .p-xl-1 {
        padding: .25rem!important
    }

    .pt-xl-1,.py-xl-1 {
        padding-top: .25rem!important
    }

    .pr-xl-1,.px-xl-1 {
        padding-right: .25rem!important
    }

    .pb-xl-1,.py-xl-1 {
        padding-bottom: .25rem!important
    }

    .pl-xl-1,.px-xl-1 {
        padding-left: .25rem!important
    }

    .p-xl-2 {
        padding: .5rem!important
    }

    .pt-xl-2,.py-xl-2 {
        padding-top: .5rem!important
    }

    .pr-xl-2,.px-xl-2 {
        padding-right: .5rem!important
    }

    .pb-xl-2,.py-xl-2 {
        padding-bottom: .5rem!important
    }

    .pl-xl-2,.px-xl-2 {
        padding-left: .5rem!important
    }

    .p-xl-3 {
        padding: 1rem!important
    }

    .pt-xl-3,.py-xl-3 {
        padding-top: 1rem!important
    }

    .pr-xl-3,.px-xl-3 {
        padding-right: 1rem!important
    }

    .pb-xl-3,.py-xl-3 {
        padding-bottom: 1rem!important
    }

    .pl-xl-3,.px-xl-3 {
        padding-left: 1rem!important
    }

    .p-xl-4 {
        padding: 1.8rem!important
    }

    .pt-xl-4,.py-xl-4 {
        padding-top: 1.8rem!important
    }

    .pr-xl-4,.px-xl-4 {
        padding-right: 1.8rem!important
    }

    .pb-xl-4,.py-xl-4 {
        padding-bottom: 1.8rem!important
    }

    .pl-xl-4,.px-xl-4 {
        padding-left: 1.8rem!important
    }

    .p-xl-5 {
        padding: 3rem!important
    }

    .pt-xl-5,.py-xl-5 {
        padding-top: 3rem!important
    }

    .pr-xl-5,.px-xl-5 {
        padding-right: 3rem!important
    }

    .pb-xl-5,.py-xl-5 {
        padding-bottom: 3rem!important
    }

    .pl-xl-5,.px-xl-5 {
        padding-left: 3rem!important
    }

    .p-xl-6 {
        padding: 4rem!important
    }

    .pt-xl-6,.py-xl-6 {
        padding-top: 4rem!important
    }

    .pr-xl-6,.px-xl-6 {
        padding-right: 4rem!important
    }

    .pb-xl-6,.py-xl-6 {
        padding-bottom: 4rem!important
    }

    .pl-xl-6,.px-xl-6 {
        padding-left: 4rem!important
    }

    .p-xl-7 {
        padding: 5rem!important
    }

    .pt-xl-7,.py-xl-7 {
        padding-top: 5rem!important
    }

    .pr-xl-7,.px-xl-7 {
        padding-right: 5rem!important
    }

    .pb-xl-7,.py-xl-7 {
        padding-bottom: 5rem!important
    }

    .pl-xl-7,.px-xl-7 {
        padding-left: 5rem!important
    }

    .p-xl-8 {
        padding: 7.5rem!important
    }

    .pt-xl-8,.py-xl-8 {
        padding-top: 7.5rem!important
    }

    .pr-xl-8,.px-xl-8 {
        padding-right: 7.5rem!important
    }

    .pb-xl-8,.py-xl-8 {
        padding-bottom: 7.5rem!important
    }

    .pl-xl-8,.px-xl-8 {
        padding-left: 7.5rem!important
    }

    .p-xl-9 {
        padding: 10rem!important
    }

    .pt-xl-9,.py-xl-9 {
        padding-top: 10rem!important
    }

    .pr-xl-9,.px-xl-9 {
        padding-right: 10rem!important
    }

    .pb-xl-9,.py-xl-9 {
        padding-bottom: 10rem!important
    }

    .pl-xl-9,.px-xl-9 {
        padding-left: 10rem!important
    }

    .p-xl-10 {
        padding: 12.5rem!important
    }

    .pt-xl-10,.py-xl-10 {
        padding-top: 12.5rem!important
    }

    .pr-xl-10,.px-xl-10 {
        padding-right: 12.5rem!important
    }

    .pb-xl-10,.py-xl-10 {
        padding-bottom: 12.5rem!important
    }

    .pl-xl-10,.px-xl-10 {
        padding-left: 12.5rem!important
    }

    .p-xl-11 {
        padding: 15rem!important
    }

    .pt-xl-11,.py-xl-11 {
        padding-top: 15rem!important
    }

    .pr-xl-11,.px-xl-11 {
        padding-right: 15rem!important
    }

    .pb-xl-11,.py-xl-11 {
        padding-bottom: 15rem!important
    }

    .pl-xl-11,.px-xl-11 {
        padding-left: 15rem!important
    }

    .m-xl-n1 {
        margin: -.25rem!important
    }

    .mt-xl-n1,.my-xl-n1 {
        margin-top: -.25rem!important
    }

    .mr-xl-n1,.mx-xl-n1 {
        margin-right: -.25rem!important
    }

    .mb-xl-n1,.my-xl-n1 {
        margin-bottom: -.25rem!important
    }

    .ml-xl-n1,.mx-xl-n1 {
        margin-left: -.25rem!important
    }

    .m-xl-n2 {
        margin: -.5rem!important
    }

    .mt-xl-n2,.my-xl-n2 {
        margin-top: -.5rem!important
    }

    .mr-xl-n2,.mx-xl-n2 {
        margin-right: -.5rem!important
    }

    .mb-xl-n2,.my-xl-n2 {
        margin-bottom: -.5rem!important
    }

    .ml-xl-n2,.mx-xl-n2 {
        margin-left: -.5rem!important
    }

    .m-xl-n3 {
        margin: -1rem!important
    }

    .mt-xl-n3,.my-xl-n3 {
        margin-top: -1rem!important
    }

    .mr-xl-n3,.mx-xl-n3 {
        margin-right: -1rem!important
    }

    .mb-xl-n3,.my-xl-n3 {
        margin-bottom: -1rem!important
    }

    .ml-xl-n3,.mx-xl-n3 {
        margin-left: -1rem!important
    }

    .m-xl-n4 {
        margin: -1.8rem!important
    }

    .mt-xl-n4,.my-xl-n4 {
        margin-top: -1.8rem!important
    }

    .mr-xl-n4,.mx-xl-n4 {
        margin-right: -1.8rem!important
    }

    .mb-xl-n4,.my-xl-n4 {
        margin-bottom: -1.8rem!important
    }

    .ml-xl-n4,.mx-xl-n4 {
        margin-left: -1.8rem!important
    }

    .m-xl-n5 {
        margin: -3rem!important
    }

    .mt-xl-n5,.my-xl-n5 {
        margin-top: -3rem!important
    }

    .mr-xl-n5,.mx-xl-n5 {
        margin-right: -3rem!important
    }

    .mb-xl-n5,.my-xl-n5 {
        margin-bottom: -3rem!important
    }

    .ml-xl-n5,.mx-xl-n5 {
        margin-left: -3rem!important
    }

    .m-xl-n6 {
        margin: -4rem!important
    }

    .mt-xl-n6,.my-xl-n6 {
        margin-top: -4rem!important
    }

    .mr-xl-n6,.mx-xl-n6 {
        margin-right: -4rem!important
    }

    .mb-xl-n6,.my-xl-n6 {
        margin-bottom: -4rem!important
    }

    .ml-xl-n6,.mx-xl-n6 {
        margin-left: -4rem!important
    }

    .m-xl-n7 {
        margin: -5rem!important
    }

    .mt-xl-n7,.my-xl-n7 {
        margin-top: -5rem!important
    }

    .mr-xl-n7,.mx-xl-n7 {
        margin-right: -5rem!important
    }

    .mb-xl-n7,.my-xl-n7 {
        margin-bottom: -5rem!important
    }

    .ml-xl-n7,.mx-xl-n7 {
        margin-left: -5rem!important
    }

    .m-xl-n8 {
        margin: -7.5rem!important
    }

    .mt-xl-n8,.my-xl-n8 {
        margin-top: -7.5rem!important
    }

    .mr-xl-n8,.mx-xl-n8 {
        margin-right: -7.5rem!important
    }

    .mb-xl-n8,.my-xl-n8 {
        margin-bottom: -7.5rem!important
    }

    .ml-xl-n8,.mx-xl-n8 {
        margin-left: -7.5rem!important
    }

    .m-xl-n9 {
        margin: -10rem!important
    }

    .mt-xl-n9,.my-xl-n9 {
        margin-top: -10rem!important
    }

    .mr-xl-n9,.mx-xl-n9 {
        margin-right: -10rem!important
    }

    .mb-xl-n9,.my-xl-n9 {
        margin-bottom: -10rem!important
    }

    .ml-xl-n9,.mx-xl-n9 {
        margin-left: -10rem!important
    }

    .m-xl-n10 {
        margin: -12.5rem!important
    }

    .mt-xl-n10,.my-xl-n10 {
        margin-top: -12.5rem!important
    }

    .mr-xl-n10,.mx-xl-n10 {
        margin-right: -12.5rem!important
    }

    .mb-xl-n10,.my-xl-n10 {
        margin-bottom: -12.5rem!important
    }

    .ml-xl-n10,.mx-xl-n10 {
        margin-left: -12.5rem!important
    }

    .m-xl-n11 {
        margin: -15rem!important
    }

    .mt-xl-n11,.my-xl-n11 {
        margin-top: -15rem!important
    }

    .mr-xl-n11,.mx-xl-n11 {
        margin-right: -15rem!important
    }

    .mb-xl-n11,.my-xl-n11 {
        margin-bottom: -15rem!important
    }

    .ml-xl-n11,.mx-xl-n11 {
        margin-left: -15rem!important
    }

    .m-xl-auto {
        margin: auto!important
    }

    .mt-xl-auto,.my-xl-auto {
        margin-top: auto!important
    }

    .mr-xl-auto,.mx-xl-auto {
        margin-right: auto!important
    }

    .mb-xl-auto,.my-xl-auto {
        margin-bottom: auto!important
    }

    .ml-xl-auto,.mx-xl-auto {
        margin-left: auto!important
    }
}

@media(min-width: 1540px) {
    .m-xxl-0 {
        margin:0!important
    }

    .mt-xxl-0,.my-xxl-0 {
        margin-top: 0!important
    }

    .mr-xxl-0,.mx-xxl-0 {
        margin-right: 0!important
    }

    .mb-xxl-0,.my-xxl-0 {
        margin-bottom: 0!important
    }

    .ml-xxl-0,.mx-xxl-0 {
        margin-left: 0!important
    }

    .m-xxl-1 {
        margin: .25rem!important
    }

    .mt-xxl-1,.my-xxl-1 {
        margin-top: .25rem!important
    }

    .mr-xxl-1,.mx-xxl-1 {
        margin-right: .25rem!important
    }

    .mb-xxl-1,.my-xxl-1 {
        margin-bottom: .25rem!important
    }

    .ml-xxl-1,.mx-xxl-1 {
        margin-left: .25rem!important
    }

    .m-xxl-2 {
        margin: .5rem!important
    }

    .mt-xxl-2,.my-xxl-2 {
        margin-top: .5rem!important
    }

    .mr-xxl-2,.mx-xxl-2 {
        margin-right: .5rem!important
    }

    .mb-xxl-2,.my-xxl-2 {
        margin-bottom: .5rem!important
    }

    .ml-xxl-2,.mx-xxl-2 {
        margin-left: .5rem!important
    }

    .m-xxl-3 {
        margin: 1rem!important
    }

    .mt-xxl-3,.my-xxl-3 {
        margin-top: 1rem!important
    }

    .mr-xxl-3,.mx-xxl-3 {
        margin-right: 1rem!important
    }

    .mb-xxl-3,.my-xxl-3 {
        margin-bottom: 1rem!important
    }

    .ml-xxl-3,.mx-xxl-3 {
        margin-left: 1rem!important
    }

    .m-xxl-4 {
        margin: 1.8rem!important
    }

    .mt-xxl-4,.my-xxl-4 {
        margin-top: 1.8rem!important
    }

    .mr-xxl-4,.mx-xxl-4 {
        margin-right: 1.8rem!important
    }

    .mb-xxl-4,.my-xxl-4 {
        margin-bottom: 1.8rem!important
    }

    .ml-xxl-4,.mx-xxl-4 {
        margin-left: 1.8rem!important
    }

    .m-xxl-5 {
        margin: 3rem!important
    }

    .mt-xxl-5,.my-xxl-5 {
        margin-top: 3rem!important
    }

    .mr-xxl-5,.mx-xxl-5 {
        margin-right: 3rem!important
    }

    .mb-xxl-5,.my-xxl-5 {
        margin-bottom: 3rem!important
    }

    .ml-xxl-5,.mx-xxl-5 {
        margin-left: 3rem!important
    }

    .m-xxl-6 {
        margin: 4rem!important
    }

    .mt-xxl-6,.my-xxl-6 {
        margin-top: 4rem!important
    }

    .mr-xxl-6,.mx-xxl-6 {
        margin-right: 4rem!important
    }

    .mb-xxl-6,.my-xxl-6 {
        margin-bottom: 4rem!important
    }

    .ml-xxl-6,.mx-xxl-6 {
        margin-left: 4rem!important
    }

    .m-xxl-7 {
        margin: 5rem!important
    }

    .mt-xxl-7,.my-xxl-7 {
        margin-top: 5rem!important
    }

    .mr-xxl-7,.mx-xxl-7 {
        margin-right: 5rem!important
    }

    .mb-xxl-7,.my-xxl-7 {
        margin-bottom: 5rem!important
    }

    .ml-xxl-7,.mx-xxl-7 {
        margin-left: 5rem!important
    }

    .m-xxl-8 {
        margin: 7.5rem!important
    }

    .mt-xxl-8,.my-xxl-8 {
        margin-top: 7.5rem!important
    }

    .mr-xxl-8,.mx-xxl-8 {
        margin-right: 7.5rem!important
    }

    .mb-xxl-8,.my-xxl-8 {
        margin-bottom: 7.5rem!important
    }

    .ml-xxl-8,.mx-xxl-8 {
        margin-left: 7.5rem!important
    }

    .m-xxl-9 {
        margin: 10rem!important
    }

    .mt-xxl-9,.my-xxl-9 {
        margin-top: 10rem!important
    }

    .mr-xxl-9,.mx-xxl-9 {
        margin-right: 10rem!important
    }

    .mb-xxl-9,.my-xxl-9 {
        margin-bottom: 10rem!important
    }

    .ml-xxl-9,.mx-xxl-9 {
        margin-left: 10rem!important
    }

    .m-xxl-10 {
        margin: 12.5rem!important
    }

    .mt-xxl-10,.my-xxl-10 {
        margin-top: 12.5rem!important
    }

    .mr-xxl-10,.mx-xxl-10 {
        margin-right: 12.5rem!important
    }

    .mb-xxl-10,.my-xxl-10 {
        margin-bottom: 12.5rem!important
    }

    .ml-xxl-10,.mx-xxl-10 {
        margin-left: 12.5rem!important
    }

    .m-xxl-11 {
        margin: 15rem!important
    }

    .mt-xxl-11,.my-xxl-11 {
        margin-top: 15rem!important
    }

    .mr-xxl-11,.mx-xxl-11 {
        margin-right: 15rem!important
    }

    .mb-xxl-11,.my-xxl-11 {
        margin-bottom: 15rem!important
    }

    .ml-xxl-11,.mx-xxl-11 {
        margin-left: 15rem!important
    }

    .p-xxl-0 {
        padding: 0!important
    }

    .pt-xxl-0,.py-xxl-0 {
        padding-top: 0!important
    }

    .pr-xxl-0,.px-xxl-0 {
        padding-right: 0!important
    }

    .pb-xxl-0,.py-xxl-0 {
        padding-bottom: 0!important
    }

    .pl-xxl-0,.px-xxl-0 {
        padding-left: 0!important
    }

    .p-xxl-1 {
        padding: .25rem!important
    }

    .pt-xxl-1,.py-xxl-1 {
        padding-top: .25rem!important
    }

    .pr-xxl-1,.px-xxl-1 {
        padding-right: .25rem!important
    }

    .pb-xxl-1,.py-xxl-1 {
        padding-bottom: .25rem!important
    }

    .pl-xxl-1,.px-xxl-1 {
        padding-left: .25rem!important
    }

    .p-xxl-2 {
        padding: .5rem!important
    }

    .pt-xxl-2,.py-xxl-2 {
        padding-top: .5rem!important
    }

    .pr-xxl-2,.px-xxl-2 {
        padding-right: .5rem!important
    }

    .pb-xxl-2,.py-xxl-2 {
        padding-bottom: .5rem!important
    }

    .pl-xxl-2,.px-xxl-2 {
        padding-left: .5rem!important
    }

    .p-xxl-3 {
        padding: 1rem!important
    }

    .pt-xxl-3,.py-xxl-3 {
        padding-top: 1rem!important
    }

    .pr-xxl-3,.px-xxl-3 {
        padding-right: 1rem!important
    }

    .pb-xxl-3,.py-xxl-3 {
        padding-bottom: 1rem!important
    }

    .pl-xxl-3,.px-xxl-3 {
        padding-left: 1rem!important
    }

    .p-xxl-4 {
        padding: 1.8rem!important
    }

    .pt-xxl-4,.py-xxl-4 {
        padding-top: 1.8rem!important
    }

    .pr-xxl-4,.px-xxl-4 {
        padding-right: 1.8rem!important
    }

    .pb-xxl-4,.py-xxl-4 {
        padding-bottom: 1.8rem!important
    }

    .pl-xxl-4,.px-xxl-4 {
        padding-left: 1.8rem!important
    }

    .p-xxl-5 {
        padding: 3rem!important
    }

    .pt-xxl-5,.py-xxl-5 {
        padding-top: 3rem!important
    }

    .pr-xxl-5,.px-xxl-5 {
        padding-right: 3rem!important
    }

    .pb-xxl-5,.py-xxl-5 {
        padding-bottom: 3rem!important
    }

    .pl-xxl-5,.px-xxl-5 {
        padding-left: 3rem!important
    }

    .p-xxl-6 {
        padding: 4rem!important
    }

    .pt-xxl-6,.py-xxl-6 {
        padding-top: 4rem!important
    }

    .pr-xxl-6,.px-xxl-6 {
        padding-right: 4rem!important
    }

    .pb-xxl-6,.py-xxl-6 {
        padding-bottom: 4rem!important
    }

    .pl-xxl-6,.px-xxl-6 {
        padding-left: 4rem!important
    }

    .p-xxl-7 {
        padding: 5rem!important
    }

    .pt-xxl-7,.py-xxl-7 {
        padding-top: 5rem!important
    }

    .pr-xxl-7,.px-xxl-7 {
        padding-right: 5rem!important
    }

    .pb-xxl-7,.py-xxl-7 {
        padding-bottom: 5rem!important
    }

    .pl-xxl-7,.px-xxl-7 {
        padding-left: 5rem!important
    }

    .p-xxl-8 {
        padding: 7.5rem!important
    }

    .pt-xxl-8,.py-xxl-8 {
        padding-top: 7.5rem!important
    }

    .pr-xxl-8,.px-xxl-8 {
        padding-right: 7.5rem!important
    }

    .pb-xxl-8,.py-xxl-8 {
        padding-bottom: 7.5rem!important
    }

    .pl-xxl-8,.px-xxl-8 {
        padding-left: 7.5rem!important
    }

    .p-xxl-9 {
        padding: 10rem!important
    }

    .pt-xxl-9,.py-xxl-9 {
        padding-top: 10rem!important
    }

    .pr-xxl-9,.px-xxl-9 {
        padding-right: 10rem!important
    }

    .pb-xxl-9,.py-xxl-9 {
        padding-bottom: 10rem!important
    }

    .pl-xxl-9,.px-xxl-9 {
        padding-left: 10rem!important
    }

    .p-xxl-10 {
        padding: 12.5rem!important
    }

    .pt-xxl-10,.py-xxl-10 {
        padding-top: 12.5rem!important
    }

    .pr-xxl-10,.px-xxl-10 {
        padding-right: 12.5rem!important
    }

    .pb-xxl-10,.py-xxl-10 {
        padding-bottom: 12.5rem!important
    }

    .pl-xxl-10,.px-xxl-10 {
        padding-left: 12.5rem!important
    }

    .p-xxl-11 {
        padding: 15rem!important
    }

    .pt-xxl-11,.py-xxl-11 {
        padding-top: 15rem!important
    }

    .pr-xxl-11,.px-xxl-11 {
        padding-right: 15rem!important
    }

    .pb-xxl-11,.py-xxl-11 {
        padding-bottom: 15rem!important
    }

    .pl-xxl-11,.px-xxl-11 {
        padding-left: 15rem!important
    }

    .m-xxl-n1 {
        margin: -.25rem!important
    }

    .mt-xxl-n1,.my-xxl-n1 {
        margin-top: -.25rem!important
    }

    .mr-xxl-n1,.mx-xxl-n1 {
        margin-right: -.25rem!important
    }

    .mb-xxl-n1,.my-xxl-n1 {
        margin-bottom: -.25rem!important
    }

    .ml-xxl-n1,.mx-xxl-n1 {
        margin-left: -.25rem!important
    }

    .m-xxl-n2 {
        margin: -.5rem!important
    }

    .mt-xxl-n2,.my-xxl-n2 {
        margin-top: -.5rem!important
    }

    .mr-xxl-n2,.mx-xxl-n2 {
        margin-right: -.5rem!important
    }

    .mb-xxl-n2,.my-xxl-n2 {
        margin-bottom: -.5rem!important
    }

    .ml-xxl-n2,.mx-xxl-n2 {
        margin-left: -.5rem!important
    }

    .m-xxl-n3 {
        margin: -1rem!important
    }

    .mt-xxl-n3,.my-xxl-n3 {
        margin-top: -1rem!important
    }

    .mr-xxl-n3,.mx-xxl-n3 {
        margin-right: -1rem!important
    }

    .mb-xxl-n3,.my-xxl-n3 {
        margin-bottom: -1rem!important
    }

    .ml-xxl-n3,.mx-xxl-n3 {
        margin-left: -1rem!important
    }

    .m-xxl-n4 {
        margin: -1.8rem!important
    }

    .mt-xxl-n4,.my-xxl-n4 {
        margin-top: -1.8rem!important
    }

    .mr-xxl-n4,.mx-xxl-n4 {
        margin-right: -1.8rem!important
    }

    .mb-xxl-n4,.my-xxl-n4 {
        margin-bottom: -1.8rem!important
    }

    .ml-xxl-n4,.mx-xxl-n4 {
        margin-left: -1.8rem!important
    }

    .m-xxl-n5 {
        margin: -3rem!important
    }

    .mt-xxl-n5,.my-xxl-n5 {
        margin-top: -3rem!important
    }

    .mr-xxl-n5,.mx-xxl-n5 {
        margin-right: -3rem!important
    }

    .mb-xxl-n5,.my-xxl-n5 {
        margin-bottom: -3rem!important
    }

    .ml-xxl-n5,.mx-xxl-n5 {
        margin-left: -3rem!important
    }

    .m-xxl-n6 {
        margin: -4rem!important
    }

    .mt-xxl-n6,.my-xxl-n6 {
        margin-top: -4rem!important
    }

    .mr-xxl-n6,.mx-xxl-n6 {
        margin-right: -4rem!important
    }

    .mb-xxl-n6,.my-xxl-n6 {
        margin-bottom: -4rem!important
    }

    .ml-xxl-n6,.mx-xxl-n6 {
        margin-left: -4rem!important
    }

    .m-xxl-n7 {
        margin: -5rem!important
    }

    .mt-xxl-n7,.my-xxl-n7 {
        margin-top: -5rem!important
    }

    .mr-xxl-n7,.mx-xxl-n7 {
        margin-right: -5rem!important
    }

    .mb-xxl-n7,.my-xxl-n7 {
        margin-bottom: -5rem!important
    }

    .ml-xxl-n7,.mx-xxl-n7 {
        margin-left: -5rem!important
    }

    .m-xxl-n8 {
        margin: -7.5rem!important
    }

    .mt-xxl-n8,.my-xxl-n8 {
        margin-top: -7.5rem!important
    }

    .mr-xxl-n8,.mx-xxl-n8 {
        margin-right: -7.5rem!important
    }

    .mb-xxl-n8,.my-xxl-n8 {
        margin-bottom: -7.5rem!important
    }

    .ml-xxl-n8,.mx-xxl-n8 {
        margin-left: -7.5rem!important
    }

    .m-xxl-n9 {
        margin: -10rem!important
    }

    .mt-xxl-n9,.my-xxl-n9 {
        margin-top: -10rem!important
    }

    .mr-xxl-n9,.mx-xxl-n9 {
        margin-right: -10rem!important
    }

    .mb-xxl-n9,.my-xxl-n9 {
        margin-bottom: -10rem!important
    }

    .ml-xxl-n9,.mx-xxl-n9 {
        margin-left: -10rem!important
    }

    .m-xxl-n10 {
        margin: -12.5rem!important
    }

    .mt-xxl-n10,.my-xxl-n10 {
        margin-top: -12.5rem!important
    }

    .mr-xxl-n10,.mx-xxl-n10 {
        margin-right: -12.5rem!important
    }

    .mb-xxl-n10,.my-xxl-n10 {
        margin-bottom: -12.5rem!important
    }

    .ml-xxl-n10,.mx-xxl-n10 {
        margin-left: -12.5rem!important
    }

    .m-xxl-n11 {
        margin: -15rem!important
    }

    .mt-xxl-n11,.my-xxl-n11 {
        margin-top: -15rem!important
    }

    .mr-xxl-n11,.mx-xxl-n11 {
        margin-right: -15rem!important
    }

    .mb-xxl-n11,.my-xxl-n11 {
        margin-bottom: -15rem!important
    }

    .ml-xxl-n11,.mx-xxl-n11 {
        margin-left: -15rem!important
    }

    .m-xxl-auto {
        margin: auto!important
    }

    .mt-xxl-auto,.my-xxl-auto {
        margin-top: auto!important
    }

    .mr-xxl-auto,.mx-xxl-auto {
        margin-right: auto!important
    }

    .mb-xxl-auto,.my-xxl-auto {
        margin-bottom: auto!important
    }

    .ml-xxl-auto,.mx-xxl-auto {
        margin-left: auto!important
    }
}

.text-monospace {
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,liberation mono,courier new,monospace!important
}

.text-justify {
    text-align: justify!important
}

.text-wrap {
    white-space: normal!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left!important
}

.text-right {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

@media(min-width: 576px) {
    .text-sm-left {
        text-align:left!important
    }

    .text-sm-right {
        text-align: right!important
    }

    .text-sm-center {
        text-align: center!important
    }
}

@media(min-width: 768px) {
    .text-md-left {
        text-align:left!important
    }

    .text-md-right {
        text-align: right!important
    }

    .text-md-center {
        text-align: center!important
    }
}

@media(min-width: 992px) {
    .text-lg-left {
        text-align:left!important
    }

    .text-lg-right {
        text-align: right!important
    }

    .text-lg-center {
        text-align: center!important
    }
}

@media(min-width: 1200px) {
    .text-xl-left {
        text-align:left!important
    }

    .text-xl-right {
        text-align: right!important
    }

    .text-xl-center {
        text-align: center!important
    }
}

@media(min-width: 1540px) {
    .text-xxl-left {
        text-align:left!important
    }

    .text-xxl-right {
        text-align: right!important
    }

    .text-xxl-center {
        text-align: center!important
    }
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.font-weight-light {
    font-weight: 300!important
}

.font-weight-lighter {
    font-weight: lighter!important
}

.font-weight-normal {
    font-weight: 400!important
}

.font-weight-bold {
    font-weight: 700!important
}

.font-weight-bolder {
    font-weight: bolder!important
}

.font-italic {
    font-style: italic!important
}

.text-white {
    color: #fff!important
}

.text-primary {
    color: #2c7be5!important
}

a.text-primary:focus,a.text-primary:hover {
    color: #1657af!important
}

.text-secondary {
    color: #748194!important
}

a.text-secondary:focus,a.text-secondary:hover {
    color: #525b6a!important
}

.text-success {
    color: #00d27a!important
}

a.text-success:focus,a.text-success:hover {
    color: #00864e!important
}

.text-info {
    color: #27bcfd!important
}

a.text-info:focus,a.text-info:hover {
    color: #0295d6!important
}

.text-warning {
    color: #f5803e!important
}

a.text-warning:focus,a.text-warning:hover {
    color: #db560b!important
}

.text-danger {
    color: #e63757!important
}

a.text-danger:focus,a.text-danger:hover {
    color: #b91735!important
}

.text-light {
    color: #f9fafd!important
}

a.text-light:focus,a.text-light:hover {
    color: #c0caea!important
}

.text-dark {
    color: #0b1727!important
}

a.text-dark:focus,a.text-dark:hover {
    color: #000!important
}

.text-body {
    color: #5e6e82!important
}

.text-muted {
    color: #9da9bb!important
}

.text-black-50 {
    color: rgba(0,0,0,.5)!important
}

.text-white-50 {
    color: rgba(255,255,255,.5)!important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none!important
}

.text-break {
    word-break: break-word!important;
    overflow-wrap: break-word!important
}

.text-reset {
    color: inherit!important
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

@media print {
    *,::after,::before {
        text-shadow: none!important;
        box-shadow: none!important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    abbr[title]::after {
        content: " (" attr(title) ")"
    }

    pre {
        white-space: pre-wrap!important
    }

    blockquote,pre {
        border: 1px solid #9da9bb;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,tr {
        page-break-inside: avoid
    }

    h2,h3,p {
        orphans: 3;
        widows: 3
    }

    h2,h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    body {
        min-width: 992px!important
    }

    .container {
        min-width: 992px!important
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse!important
    }

    .table td,.table th {
        background-color: #fff!important
    }

    .table-bordered td,.table-bordered th {
        border: 1px solid #d8e2ef!important
    }

    .table-dark {
        color: inherit
    }

    .table-dark tbody+tbody,.table-dark td,.table-dark th,.table-dark thead th {
        border-color: #edf2f9
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #edf2f9
    }
}

::-webkit-input-placeholder {
    opacity: 1;
    color: #b6c1d2!important
}

::-moz-placeholder {
    opacity: 1;
    color: #b6c1d2!important
}

:-ms-input-placeholder {
    opacity: 1;
    color: #b6c1d2!important
}

::-ms-input-placeholder {
    opacity: 1;
    color: #b6c1d2!important
}

::placeholder {
    opacity: 1;
    color: #b6c1d2!important
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    position: relative
}

code,pre {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

section {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem
}

@media(min-width: 992px) {
    section {
        padding-top:7.5rem;
        padding-bottom: 7.5rem
    }
}

.alert,.badge,.blockquote-footer,.btn,.invalid-feedback,.navbar,.pagination,.valid-feedback,button,input,label,optgroup,select,textarea {
    font-family: Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol
}

pre {
    margin: 0;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0)
}

strong {
    font-weight: 600
}

label {
    font-weight: 500!important
}

.avatar {
    position: relative;
    display: inline-block
}

.avatar .avatar-name {
    background-color: #344050;
    position: absolute;
    text-align: center;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    display: block
}

.avatar .avatar-name>span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0)
}

.avatar .avatar-emoji {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center
}

.avatar .avatar-emoji>span {
    -webkit-transform: translateY(2%);
    transform: translateY(2%);
    display: inline-block
}

.avatar img {
    display: block
}

.avatar .avatar-name,.avatar img {
    width: 100%;
    height: 100%
}

.avatar img {
    object-fit: cover
}

.avatar[class*=status-]:before {
    content: '';
    position: absolute;
    border-radius: 100%;
    z-index: 1
}

.avatar.status-online:before {
    background-color: #00d27a
}

.avatar.status-offline:before {
    background-color: #748194
}

.avatar.status-away:before {
    background-color: #f5803e
}

.avatar.status-do-not-disturb:before {
    background-color: #e63757
}

.avatar .avatar-button {
    background-color: #b6c1d2!important;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border: 1px solid #b6c1d2;
    font-size: 12px
}

.avatar .avatar-button:focus,.avatar .avatar-button:hover {
    background-color: #9da9bb!important
}

.avatar:hover .overlay-icon {
    opacity: 1
}

.avatar-s {
    height: 1.25rem;
    width: 1.25rem
}

.avatar-s .avatar-name {
    font-size: .41667rem
}

.avatar-s .avatar-emoji {
    font-size: 1.25rem
}

.avatar-s[class*=status-]:before {
    border: 1px solid #fff;
    height: 9px;
    width: 9px;
    right: -1px;
    bottom: -1px
}

.avatar-s .rounded-soft {
    border-radius: .20833rem!important
}

.avatar-m {
    height: 1.5rem;
    width: 1.5rem
}

.avatar-m .avatar-name {
    font-size: .5rem
}

.avatar-m .avatar-emoji {
    font-size: 1.5rem
}

.avatar-m[class*=status-]:before {
    border: 1px solid #fff;
    height: 9px;
    width: 9px;
    right: -1px;
    bottom: -1px
}

.avatar-m .rounded-soft {
    border-radius: .25rem!important
}

.avatar-l {
    height: 1.75rem;
    width: 1.75rem
}

.avatar-l .avatar-name {
    font-size: .58333rem
}

.avatar-l .avatar-emoji {
    font-size: 1.75rem
}

.avatar-l[class*=status-]:before {
    border: 1px solid #fff;
    height: 9px;
    width: 9px;
    right: -1px;
    bottom: -1px
}

.avatar-l .rounded-soft {
    border-radius: .29167rem!important
}

.avatar-xl {
    height: 2rem;
    width: 2rem
}

.avatar-xl .avatar-name {
    font-size: .66667rem
}

.avatar-xl .avatar-emoji {
    font-size: 2rem
}

.avatar-xl[class*=status-]:before {
    border: 2px solid #fff;
    height: 13px;
    width: 13px;
    right: -2px;
    bottom: -2px
}

.avatar-xl .rounded-soft {
    border-radius: .33333rem!important
}

.avatar-2xl {
    height: 2.5rem;
    width: 2.5rem
}

.avatar-2xl .avatar-name {
    font-size: .83333rem
}

.avatar-2xl .avatar-emoji {
    font-size: 2.5rem
}

.avatar-2xl[class*=status-]:before {
    border: 2px solid #fff;
    height: 16px;
    width: 16px;
    right: -3px;
    bottom: -3px
}

.avatar-2xl .rounded-soft {
    border-radius: .41667rem!important
}

.avatar-3xl {
    height: 3.5rem;
    width: 3.5rem
}

.avatar-3xl .avatar-name {
    font-size: 1.16667rem
}

.avatar-3xl .avatar-emoji {
    font-size: 3.5rem
}

.avatar-3xl[class*=status-]:before {
    border: 3px solid #fff;
    height: 18px;
    width: 18px;
    right: 0;
    bottom: 0
}

.avatar-3xl .rounded-soft {
    border-radius: .58333rem!important
}

.avatar-4xl {
    height: 6.125rem;
    width: 6.125rem
}

.avatar-4xl .avatar-name {
    font-size: 2.04167rem
}

.avatar-4xl .avatar-emoji {
    font-size: 6.125rem
}

.avatar-4xl[class*=status-]:before {
    border: 3px solid #fff;
    height: 24px;
    width: 24px;
    right: 3px;
    bottom: 3px
}

.avatar-4xl .rounded-soft {
    border-radius: 1.02083rem!important
}

.avatar-5xl {
    height: 10.5rem;
    width: 10.5rem
}

.avatar-5xl .avatar-name {
    font-size: 3.5rem
}

.avatar-5xl .avatar-emoji {
    font-size: 10.5rem
}

.avatar-5xl[class*=status-]:before {
    border: 4px solid #fff;
    height: 32px;
    width: 32px;
    right: 8px;
    bottom: 8px
}

.avatar-5xl .rounded-soft {
    border-radius: 1.75rem!important
}

.avatar-profile {
    position: absolute;
    bottom: 0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%)
}

.overlay-icon {
    position: absolute;
    height: 50%;
    width: 100%;
    bottom: 0;
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer
}

.cover-image-file-input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    padding: .25rem .5rem;
    margin-left: 1rem;
    margin-top: 1rem;
    border-radius: .25rem;
    font-size: .83333rem;
    color: #fff;
    border: 1px solid #d8e2ef;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: rgba(0,0,0,.9)
}

.cover-image:hover .cover-image-file-input {
    opacity: 1;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.calendar {
    width: 50px;
    line-height: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: center;
    font-family: Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
    border-radius: .375rem
}

.calendar .calendar-month {
    background-color: #e63757;
    color: #fff;
    border-radius: .375rem .375rem 0 0;
    padding: .25rem;
    font-weight: 600
}

.calendar .calendar-day {
    font-size: 1.728rem;
    padding: .25rem;
    border: .125rem solid rgba(230,55,87,.3);
    border-top: 0;
    border-radius: 0 0 .375rem .375rem;
    color: #9da9bb;
    font-weight: 700
}

.navbar .dropdown-menu {
    overflow: visible;
    left: -.5625rem
}

.navbar .dropdown-menu:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 1rem;
    height: 1rem;
    background: #000;
    top: -.375rem;
    left: 1.8rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: inherit;
    border-radius: .125rem;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #f9fafd;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)
}

.navbar .dropdown-menu.dropdown-menu-right {
    left: auto;
    right: -.5625rem
}

.navbar .dropdown-menu.dropdown-menu-right:after {
    right: .95rem;
    left: auto
}

.navbar .dropdown-item {
    font-weight: 500
}

.navbar .dropdown-item:focus,.navbar .dropdown-item:hover {
    background-color: initial;
    color: #232e3c
}

.navbar-glass,.navbar-vertical .navbar-collapse {
    background-color: rgba(237,242,249,.96)
}

.navbar-vertical .safari .navbar-collapse,.safari .navbar-glass,.safari .navbar-vertical .navbar-collapse {
    box-shadow: 0 .5rem .5rem -.5rem transparent
}

.navbar-brand {
    font-weight: 800
}

.navbar-glass-shadow {
    box-shadow: 0 .5rem .5rem -.5rem rgba(0,0,0,.2)!important
}

.navbar-toggler-humburger-icon {
    height: 2.5rem;
    width: 2.5rem;
    padding: .3125rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 50%
}

.navbar-toggler-humburger-icon:focus,.navbar-toggler-humburger-icon:hover {
    background-color: #d8e2ef
}

.navbar-toggle-icon {
    position: relative;
    height: .125rem;
    width: 1.25rem;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: block;
    top: -.3125rem;
    padding-right: 0
}

.navbar-toggle-icon .toggle-line {
    display: block;
    height: 100%;
    border-radius: .25rem;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    background-color: #5e6e82
}

.navbar-toggle-icon:after,.navbar-toggle-icon:before {
    content: '';
    position: absolute;
    height: .125rem;
    background-color: #5e6e82;
    left: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: .25rem;
    width: 100%
}

.navbar-toggle-icon:before {
    top: .3125rem
}

.navbar-toggle-icon:after {
    top: .625rem
}

.search-box {
    position: relative
}

.search-box .search-input {
    width: 20rem;
    padding-left: 1.95rem;
    line-height: 2
}

.search-box .search-box-icon {
    z-index: 1020;
    top: 50%;
    left: .9rem;
    -webkit-transform: translateY(-48%);
    transform: translateY(-48%)
}

.shadow-bottom {
    box-shadow: 0 .5rem .5rem -.5rem rgba(0,0,0,.2)!important
}

.navbar-top {
    top: 0;
    z-index: 1020
}

.navbar-vertical {
    position: -ms-device-fixed!important;
    padding: 0;
    z-index: 1019;
    top: 4.3125rem;
    width: 100vw
}

.navbar-vertical .navbar-brand {
    display: none
}

.navbar-vertical .navbar-collapse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column
}

.navbar-vertical .navbar-collapse>* {
    width: 100%;
    overflow: hidden
}

.navbar-vertical .navbar-vertical-toggle {
    display: none
}

.navbar-vertical-toggle {
    box-shadow: 0px 0px 3px 0 #9da9bb;
}

.navbar-vertical .navbar-vertical-divider {
    padding-left: 1rem;
    padding-right: 1rem
}

.navbar-vertical .navbar-vertical-hr {
    border-color: #d8e2ef
}

.navbar-vertical .navbar-nav {
    width: 100%;
    font-size: .875rem;
    font-weight: 500;
    padding-bottom: 10px;
}

.navbar-vertical .navbar-nav .nav-link {
    color: #5e6e82;
    white-space: nowrap;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

@media(min-width: 992px) {
    .navbar-vertical .navbar-nav .nav-link {
        padding:.35rem 0
    }
}

.navbar-vertical .navbar-nav .nav-link:focus,.navbar-vertical .navbar-nav .nav-link:hover {
    color: #232e3c
}

.navbar-vertical .navbar-nav .nav-link:focus.dropdown-indicator:after,.navbar-vertical .navbar-nav .nav-link:hover.dropdown-indicator:after {
    border-color: #232e3c
}

.navbar-vertical .navbar-nav .nav-link-icon {
    width: 1.5rem;
    min-width: 1.5rem;
    font-size: 1rem
}

.navbar-vertical .navbar-nav .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column nowrap;
    font-size: .8125rem
}

.navbar-vertical .navbar-nav .nav .nav-item .nav-link {
    padding: .2rem 1.5rem
}

.navbar-vertical .navbar-nav .nav .nav-item:last-child {
    margin-bottom: .35rem
}

.navbar-vertical .navbar-nav .nav .nav .nav-item .nav-link {
    padding-left: 2.625rem
}

.navbar-vertical .navbar-nav .nav .nav .nav .nav-item .nav-link {
    padding-left: 4.125rem
}

.navbar-vertical.navbar-card,.navbar-vertical.navbar-inverted,.navbar-vertical.navbar-vibrant {
    padding-left: .9375rem;
    padding-right: .9375rem
}

.navbar-vertical.navbar-card .navbar-collapse,.navbar-vertical.navbar-inverted .navbar-collapse,.navbar-vertical.navbar-vibrant .navbar-collapse {
    border-radius: .375rem
}

.navbar-vertical.navbar-inverted .navbar-collapse {
    background-color: #232e3c
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item, .navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link.single{
  color: #f4f8ff!important;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link.single {
  padding-left: 0!important;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav{
  border-left: 1px solid #9da9bb;
  margin-left: 5px;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link.active{
  background: #9da9bb;
  color: #232e3c;
  border-radius: 4px;
  margin: 10px 5px;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link.active.single{
  padding-left: .5rem!important;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link {
    color: #9da9bb;
    padding-left: .5rem;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link.dropdown-indicator:after {
    border-color: #9da9bb
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link:focus,.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link:hover {
    color: #edf2f9
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link:focus.dropdown-indicator:after,.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link:hover.dropdown-indicator:after {
    border-color: #edf2f9
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item.active .nav-link {
    color: #edf2f9
}

.navbar-vertical.navbar-inverted .navbar-vertical-hr {
    border-color: rgba(255,255,255,.2)
}

.navbar-vertical.navbar-vibrant .navbar-collapse {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.navbar-vertical.navbar-vibrant .badge-soft-primary {
    color: #1c4f93;
    background-color: #d5e5fa
}

.navbar-vertical.navbar-vibrant .badge-soft-success {
    color: #00864e;
    background-color: #ccf6e4
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link {
    color: rgba(255,255,255,.75)
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link.dropdown-indicator:after {
    border-color: rgba(255,255,255,.75)
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link:focus,.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link:hover {
    color: #fff
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link:focus.dropdown-indicator:after,.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link:hover.dropdown-indicator:after {
    border-color: #fff
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item.active .nav-link {
    color: #fff
}

.navbar-vertical.navbar-vibrant .navbar-vertical-hr {
    border-color: rgba(255,255,255,.2)
}

.navbar-vertical.navbar-card .navbar-collapse {
    background-color: #fff;
    box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link {
    color: #5e6e82
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link.dropdown-indicator:after {
    border-color: #5e6e82
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link:focus,.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link:hover {
    color: #344050
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link:focus.dropdown-indicator:after,.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link:hover.dropdown-indicator:after {
    border-color: #344050
}

.navbar-vertical.navbar-card .navbar-nav .nav-item.active .nav-link {
    color: #2c7be5
}

.navbar-vertical.navbar-card .navbar-vertical-hr {
    border-color: #d8e2ef
}

.windows.chrome .navbar-vertical.navbar-inverted .navbar-collapse.scrollbar::-webkit-scrollbar-thumb {
    background-color: #b6c1d2
}

.windows.chrome .navbar-vertical.navbar-vibrant .navbar-collapse.scrollbar::-webkit-scrollbar-thumb {
    background-color: #b6c1d2
}

.windows.chrome .navbar-vertical.navbar-card .navbar-collapse.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(116,129,148,.3)
}

.windows.firefox .navbar-vertical.navbar-inverted .navbar-collapse.scrollbar {
    scrollbar-color: #b6c1d2 transparent
}

.windows.firefox .navbar-vertical.navbar-vibrant .navbar-collapse.scrollbar {
    scrollbar-color: #b6c1d2 transparent
}

.windows.firefox .navbar-vertical.navbar-card .navbar-collapse.scrollbar {
    scrollbar-color: rgba(116,129,148,.3) transparent
}

.ie .navbar-vertical.navbar-inverted .ps__rail-y .ps__thumb-y {
    background-color: #b6c1d2!important
}

.ie .navbar-vertical.navbar-vibrant .ps:not(.show) {
    overflow: visible!important
}

.ie .navbar-vertical.navbar-vibrant .ps__rail-y .ps__thumb-y {
    background-color: #b6c1d2!important
}

.ie .navbar-vertical.navbar-card .ps__rail-y .ps__thumb-y {
    background-color: rgba(116,129,148,.3)!important
}

@media(max-width: 1539.98px) and (min-width:1200px) and (max-width:1539.98px) {
    .navbar-vertical.navbar-expand-xxl {
        position:fixed;
        max-width: 1140px;
        width: 100%
    }
}

@media(max-width: 1539.98px) {
    .navbar-vertical.navbar-expand-xxl+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 1539.98px) and (min-width:1200px) and (max-width:1539.98px) {
    .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        width:100%;
        max-width: 1140px!important
    }
}

@media(max-width: 1539.98px) and (min-width:992px) and (max-width:1199.98px) {
    .navbar-vertical.navbar-expand-xxl {
        position:fixed;
        max-width: 960px;
        width: 100%
    }
}

@media(max-width: 1539.98px) {
    .navbar-vertical.navbar-expand-xxl+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 1539.98px) and (min-width:992px) and (max-width:1199.98px) {
    .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        width:100%;
        max-width: 960px!important
    }
}

@media(max-width: 1539.98px) and (min-width:768px) and (max-width:991.98px) {
    .navbar-vertical.navbar-expand-xxl {
        position:fixed;
        max-width: 720px;
        width: 100%
    }
}

@media(max-width: 1539.98px) {
    .navbar-vertical.navbar-expand-xxl+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 1539.98px) and (min-width:768px) and (max-width:991.98px) {
    .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        width:100%;
        max-width: 720px!important
    }
}

@media(max-width: 1539.98px) and (min-width:576px) and (max-width:767.98px) {
    .navbar-vertical.navbar-expand-xxl {
        position:fixed;
        max-width: 540px;
        width: 100%
    }
}

@media(max-width: 1539.98px) {
    .navbar-vertical.navbar-expand-xxl+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 1539.98px) and (min-width:576px) and (max-width:767.98px) {
    .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        width:100%;
        max-width: 540px!important
    }
}

@media(max-width: 1539.98px) {
    .container-fluid .navbar-vertical.navbar-expand-xxl,.container-lg .navbar-vertical.navbar-expand-xxl,.container-md .navbar-vertical.navbar-expand-xxl,.container-sm .navbar-vertical.navbar-expand-xxl,.container-xl .navbar-vertical.navbar-expand-xxl,.container-xxl .navbar-vertical.navbar-expand-xxl {
        width:100%;
        max-width: 100vw!important
    }

    .container-fluid .navbar-vertical.navbar-expand-xxl+.content .navbar-top,.container-lg .navbar-vertical.navbar-expand-xxl+.content .navbar-top,.container-md .navbar-vertical.navbar-expand-xxl+.content .navbar-top,.container-sm .navbar-vertical.navbar-expand-xxl+.content .navbar-top,.container-xl .navbar-vertical.navbar-expand-xxl+.content .navbar-top,.container-xxl .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        width: 100%;
        max-width: 100vw!important
    }
}

@media(min-width: 1540px) {
    .navbar-vertical.navbar-expand-xxl {
        position:fixed;
        width: 100%;
        top: 0;
        max-height: 100vh;
        display: block;
        max-width: 12.625rem
    }

    .navbar-vertical.navbar-expand-xxl .navbar-vertical-toggle {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-xxl .navbar-brand {
        display: block;
        text-align: center
    }

    .navbar-vertical.navbar-expand-xxl+.content {
        margin-left: 15.625rem
    }

    .navbar-vertical.navbar-expand-xxl+.content .navbar-top .navbar-brand,.navbar-vertical.navbar-expand-xxl+.content .navbar-top .navbar-toggler {
        display: none
    }

    .navbar-vertical.navbar-expand-xxl .navbar-collapse {
        position: relative;
        display: inline-block!important;
        width: 12.625rem;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        padding-top: .5rem;
        height: calc(100vh - 4.3125rem);
        margin-top: -.3125rem;
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-xxl .settings .custom-control-label {
        white-space: nowrap
    }

    .safari .navbar-vertical.navbar-expand-xxl .navbar-vertical .navbar-collapse {
        margin-top: .3125rem;
        padding-top: .5625rem
    }

    .navbar-vertical.navbar-expand-xxl .navbar-vertical-divider {
        padding: 0
    }

    .navbar-vertical.navbar-expand-xxl.navbar-card,.navbar-vertical.navbar-expand-xxl.navbar-inverted,.navbar-vertical.navbar-expand-xxl.navbar-vibrant {
        padding: 0;
        max-width: 14.5rem!important
    }

    .navbar-vertical.navbar-expand-xxl.navbar-card .toggle-icon-wrapper,.navbar-vertical.navbar-expand-xxl.navbar-inverted .toggle-icon-wrapper,.navbar-vertical.navbar-expand-xxl.navbar-vibrant .toggle-icon-wrapper {
        margin-left: 0;
        margin-right: 1.5625rem;
        padding-left: .3125rem
    }

    .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-collapse,.navbar-vertical.navbar-expand-xxl.navbar-inverted .navbar-collapse,.navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important;
        margin-left: 0!important;
        padding: 1rem!important;
        height: calc(100vh - 5.3125rem)
    }

    .navbar-vertical.navbar-expand-xxl.navbar-card+.content,.navbar-vertical.navbar-expand-xxl.navbar-inverted+.content,.navbar-vertical.navbar-expand-xxl.navbar-vibrant+.content {
        margin-left: 15.5rem
    }

    .navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-collapse.ps {
        overflow-y: auto!important
    }
}

@media(max-width: 1539.98px) {
    .navbar-vertical.navbar-expand-xxl {
        margin-left:-15px;
        margin-right: -15px
    }

    .navbar-vertical.navbar-expand-xxl+.content .navbar-top .navbar-toggler {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-xxl .navbar-nav {
        padding-left: 15px;
        padding-right: 15px
    }

    .navbar-vertical.navbar-expand-xxl .navbar-collapse.show {
        max-height: calc(100vh - 4.3125rem);
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-collapse.show,.navbar-vertical.navbar-expand-xxl.navbar-inverted .navbar-collapse.show,.navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-collapse.show {
        max-height: calc(100vh - 5.3125rem)
    }
}

@media(max-width: 1199.98px) and (min-width:992px) and (max-width:1199.98px) {
    .navbar-vertical.navbar-expand-xl {
        position:fixed;
        max-width: 960px;
        width: 100%
    }
}

@media(max-width: 1199.98px) {
    .navbar-vertical.navbar-expand-xl+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-xl+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 1199.98px) and (min-width:992px) and (max-width:1199.98px) {
    .navbar-vertical.navbar-expand-xl+.content .navbar-top {
        width:100%;
        max-width: 960px!important
    }
}

@media(max-width: 1199.98px) and (min-width:768px) and (max-width:991.98px) {
    .navbar-vertical.navbar-expand-xl {
        position:fixed;
        max-width: 720px;
        width: 100%
    }
}

@media(max-width: 1199.98px) {
    .navbar-vertical.navbar-expand-xl+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-xl+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 1199.98px) and (min-width:768px) and (max-width:991.98px) {
    .navbar-vertical.navbar-expand-xl+.content .navbar-top {
        width:100%;
        max-width: 720px!important
    }
}

@media(max-width: 1199.98px) and (min-width:576px) and (max-width:767.98px) {
    .navbar-vertical.navbar-expand-xl {
        position:fixed;
        max-width: 540px;
        width: 100%
    }
}

@media(max-width: 1199.98px) {
    .navbar-vertical.navbar-expand-xl+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-xl+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 1199.98px) and (min-width:576px) and (max-width:767.98px) {
    .navbar-vertical.navbar-expand-xl+.content .navbar-top {
        width:100%;
        max-width: 540px!important
    }
}

@media(max-width: 1199.98px) {
    .container-fluid .navbar-vertical.navbar-expand-xl,.container-lg .navbar-vertical.navbar-expand-xl,.container-md .navbar-vertical.navbar-expand-xl,.container-sm .navbar-vertical.navbar-expand-xl,.container-xl .navbar-vertical.navbar-expand-xl,.container-xxl .navbar-vertical.navbar-expand-xl {
        width:100%;
        max-width: 100vw!important
    }

    .container-fluid .navbar-vertical.navbar-expand-xl+.content .navbar-top,.container-lg .navbar-vertical.navbar-expand-xl+.content .navbar-top,.container-md .navbar-vertical.navbar-expand-xl+.content .navbar-top,.container-sm .navbar-vertical.navbar-expand-xl+.content .navbar-top,.container-xl .navbar-vertical.navbar-expand-xl+.content .navbar-top,.container-xxl .navbar-vertical.navbar-expand-xl+.content .navbar-top {
        width: 100%;
        max-width: 100vw!important
    }
}

@media(min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl {
        position:fixed;
        width: 100%;
        top: 0;
        max-height: 100vh;
        display: block;
        max-width: 12.625rem
    }

    .navbar-vertical.navbar-expand-xl .navbar-vertical-toggle {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-xl .navbar-brand {
        display: block;
        text-align: center
    }

    .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 15.625rem
    }

    .navbar-vertical.navbar-expand-xl+.content .navbar-top .navbar-brand,.navbar-vertical.navbar-expand-xl+.content .navbar-top .navbar-toggler {
        display: none
    }

    .navbar-vertical.navbar-expand-xl .navbar-collapse {
        position: relative;
        display: inline-block!important;
        width: 12.625rem;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        padding-top: .5rem;
        height: calc(100vh - 4.3125rem);
        /* margin-top: -.3125rem; */
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-xl .settings .custom-control-label {
        white-space: nowrap
    }

    .safari .navbar-vertical.navbar-expand-xl .navbar-vertical .navbar-collapse {
        margin-top: .3125rem;
        padding-top: .5625rem
    }

    .navbar-vertical.navbar-expand-xl .navbar-vertical-divider {
        padding: 0
    }

    .navbar-vertical.navbar-expand-xl.navbar-card,.navbar-vertical.navbar-expand-xl.navbar-inverted,.navbar-vertical.navbar-expand-xl.navbar-vibrant {
        padding: 0;
        max-width: 14.5rem!important
    }

    .navbar-vertical.navbar-expand-xl.navbar-card .toggle-icon-wrapper,.navbar-vertical.navbar-expand-xl.navbar-inverted .toggle-icon-wrapper,.navbar-vertical.navbar-expand-xl.navbar-vibrant .toggle-icon-wrapper {
        margin-left: 0;
        margin-right: 1.5625rem;
        padding-left: .3125rem
    }

    .navbar-vertical.navbar-expand-xl.navbar-card .navbar-collapse,.navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-collapse,.navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important;
        margin-left: 0!important;
        padding: 1rem!important;
        height: calc(100vh - 5.3125rem)
    }

    .navbar-vertical.navbar-expand-xl.navbar-card+.content,.navbar-vertical.navbar-expand-xl.navbar-inverted+.content,.navbar-vertical.navbar-expand-xl.navbar-vibrant+.content {
        margin-left: 15.5rem
    }

    .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-collapse.ps {
        overflow-y: auto!important
    }
}

@media(max-width: 1199.98px) {
    .navbar-vertical.navbar-expand-xl {
        margin-left:-15px;
        margin-right: -15px
    }

    .navbar-vertical.navbar-expand-xl+.content .navbar-top .navbar-toggler {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-xl .navbar-nav {
        padding-left: 15px;
        padding-right: 15px
    }

    .navbar-vertical.navbar-expand-xl .navbar-collapse.show {
        max-height: calc(100vh - 4.3125rem);
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-xl.navbar-card .navbar-collapse.show,.navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-collapse.show,.navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-collapse.show {
        max-height: calc(100vh - 5.3125rem)
    }
}

@media(max-width: 991.98px) and (min-width:768px) and (max-width:991.98px) {
    .navbar-vertical.navbar-expand-lg {
        position:fixed;
        max-width: 720px;
        width: 100%
    }
}

@media(max-width: 991.98px) {
    .navbar-vertical.navbar-expand-lg+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-lg+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 991.98px) and (min-width:768px) and (max-width:991.98px) {
    .navbar-vertical.navbar-expand-lg+.content .navbar-top {
        width:100%;
        max-width: 720px!important
    }
}

@media(max-width: 991.98px) and (min-width:576px) and (max-width:767.98px) {
    .navbar-vertical.navbar-expand-lg {
        position:fixed;
        max-width: 540px;
        width: 100%
    }
}

@media(max-width: 991.98px) {
    .navbar-vertical.navbar-expand-lg+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-lg+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 991.98px) and (min-width:576px) and (max-width:767.98px) {
    .navbar-vertical.navbar-expand-lg+.content .navbar-top {
        width:100%;
        max-width: 540px!important
    }
}

@media(max-width: 991.98px) {
    .container-fluid .navbar-vertical.navbar-expand-lg,.container-lg .navbar-vertical.navbar-expand-lg,.container-md .navbar-vertical.navbar-expand-lg,.container-sm .navbar-vertical.navbar-expand-lg,.container-xl .navbar-vertical.navbar-expand-lg,.container-xxl .navbar-vertical.navbar-expand-lg {
        width:100%;
        max-width: 100vw!important
    }

    .container-fluid .navbar-vertical.navbar-expand-lg+.content .navbar-top,.container-lg .navbar-vertical.navbar-expand-lg+.content .navbar-top,.container-md .navbar-vertical.navbar-expand-lg+.content .navbar-top,.container-sm .navbar-vertical.navbar-expand-lg+.content .navbar-top,.container-xl .navbar-vertical.navbar-expand-lg+.content .navbar-top,.container-xxl .navbar-vertical.navbar-expand-lg+.content .navbar-top {
        width: 100%;
        max-width: 100vw!important
    }
}

@media(min-width: 992px) {
    .navbar-vertical.navbar-expand-lg {
        position:fixed;
        width: 100%;
        top: 0;
        max-height: 100vh;
        display: block;
        max-width: 12.625rem
    }

    .navbar-vertical.navbar-expand-lg .navbar-vertical-toggle {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-lg .navbar-brand {
        display: block;
        text-align: center
    }

    .navbar-vertical.navbar-expand-lg+.content {
        margin-left: 15.625rem
    }

    .navbar-vertical.navbar-expand-lg+.content .navbar-top .navbar-brand,.navbar-vertical.navbar-expand-lg+.content .navbar-top .navbar-toggler {
        display: none
    }

    .navbar-vertical.navbar-expand-lg .navbar-collapse {
        position: relative;
        display: inline-block!important;
        width: 12.625rem;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        padding-top: .5rem;
        height: calc(100vh - 4.3125rem);
        margin-top: -.3125rem;
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-lg .settings .custom-control-label {
        white-space: nowrap
    }

    .safari .navbar-vertical.navbar-expand-lg .navbar-vertical .navbar-collapse {
        margin-top: .3125rem;
        padding-top: .5625rem
    }

    .navbar-vertical.navbar-expand-lg .navbar-vertical-divider {
        padding: 0
    }

    .navbar-vertical.navbar-expand-lg.navbar-card,.navbar-vertical.navbar-expand-lg.navbar-inverted,.navbar-vertical.navbar-expand-lg.navbar-vibrant {
        padding: 0;
        max-width: 14.5rem!important
    }

    .navbar-vertical.navbar-expand-lg.navbar-card .toggle-icon-wrapper,.navbar-vertical.navbar-expand-lg.navbar-inverted .toggle-icon-wrapper,.navbar-vertical.navbar-expand-lg.navbar-vibrant .toggle-icon-wrapper {
        margin-left: 0;
        margin-right: 1.5625rem;
        padding-left: .3125rem
    }

    .navbar-vertical.navbar-expand-lg.navbar-card .navbar-collapse,.navbar-vertical.navbar-expand-lg.navbar-inverted .navbar-collapse,.navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important;
        margin-left: 0!important;
        padding: 1rem!important;
        height: calc(100vh - 5.3125rem)
    }

    .navbar-vertical.navbar-expand-lg.navbar-card+.content,.navbar-vertical.navbar-expand-lg.navbar-inverted+.content,.navbar-vertical.navbar-expand-lg.navbar-vibrant+.content {
        margin-left: 15.5rem
    }

    .navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-collapse.ps {
        overflow-y: auto!important
    }
}

@media(max-width: 991.98px) {
    .navbar-vertical.navbar-expand-lg {
        margin-left:-15px;
        margin-right: -15px
    }

    .navbar-vertical.navbar-expand-lg+.content .navbar-top .navbar-toggler {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav {
        padding-left: 15px;
        padding-right: 15px
    }

    .navbar-vertical.navbar-expand-lg .navbar-collapse.show {
        max-height: calc(100vh - 4.3125rem);
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-lg.navbar-card .navbar-collapse.show,.navbar-vertical.navbar-expand-lg.navbar-inverted .navbar-collapse.show,.navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-collapse.show {
        max-height: calc(100vh - 5.3125rem)
    }
}

@media(max-width: 767.98px) and (min-width:576px) and (max-width:767.98px) {
    .navbar-vertical.navbar-expand-md {
        position:fixed;
        max-width: 540px;
        width: 100%
    }
}

@media(max-width: 767.98px) {
    .navbar-vertical.navbar-expand-md+.content {
        padding-top:4.3125rem
    }

    .navbar-vertical.navbar-expand-md+.content .navbar-top {
        position: fixed;
        top: 0
    }
}

@media(max-width: 767.98px) and (min-width:576px) and (max-width:767.98px) {
    .navbar-vertical.navbar-expand-md+.content .navbar-top {
        width:100%;
        max-width: 540px!important
    }
}

@media(max-width: 767.98px) {
    .container-fluid .navbar-vertical.navbar-expand-md,.container-lg .navbar-vertical.navbar-expand-md,.container-md .navbar-vertical.navbar-expand-md,.container-sm .navbar-vertical.navbar-expand-md,.container-xl .navbar-vertical.navbar-expand-md,.container-xxl .navbar-vertical.navbar-expand-md {
        width:100%;
        max-width: 100vw!important
    }

    .container-fluid .navbar-vertical.navbar-expand-md+.content .navbar-top,.container-lg .navbar-vertical.navbar-expand-md+.content .navbar-top,.container-md .navbar-vertical.navbar-expand-md+.content .navbar-top,.container-sm .navbar-vertical.navbar-expand-md+.content .navbar-top,.container-xl .navbar-vertical.navbar-expand-md+.content .navbar-top,.container-xxl .navbar-vertical.navbar-expand-md+.content .navbar-top {
        width: 100%;
        max-width: 100vw!important
    }
}

@media(min-width: 768px) {
    .navbar-vertical.navbar-expand-md {
        position:fixed;
        width: 100%;
        top: 0;
        max-height: 100vh;
        display: block;
        max-width: 12.625rem
    }

    .navbar-vertical.navbar-expand-md .navbar-vertical-toggle {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-md .navbar-brand {
        display: block;
        text-align: center
    }

    .navbar-vertical.navbar-expand-md+.content {
        margin-left: 15.625rem
    }

    .navbar-vertical.navbar-expand-md+.content .navbar-top .navbar-brand,.navbar-vertical.navbar-expand-md+.content .navbar-top .navbar-toggler {
        display: none
    }

    .navbar-vertical.navbar-expand-md .navbar-collapse {
        position: relative;
        display: inline-block!important;
        width: 12.625rem;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        padding-top: .5rem;
        height: calc(100vh - 4.3125rem);
        margin-top: -.3125rem;
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-md .settings .custom-control-label {
        white-space: nowrap
    }

    .safari .navbar-vertical.navbar-expand-md .navbar-vertical .navbar-collapse {
        margin-top: .3125rem;
        padding-top: .5625rem
    }

    .navbar-vertical.navbar-expand-md .navbar-vertical-divider {
        padding: 0
    }

    .navbar-vertical.navbar-expand-md.navbar-card,.navbar-vertical.navbar-expand-md.navbar-inverted,.navbar-vertical.navbar-expand-md.navbar-vibrant {
        padding: 0;
        max-width: 14.5rem!important
    }

    .navbar-vertical.navbar-expand-md.navbar-card .toggle-icon-wrapper,.navbar-vertical.navbar-expand-md.navbar-inverted .toggle-icon-wrapper,.navbar-vertical.navbar-expand-md.navbar-vibrant .toggle-icon-wrapper {
        margin-left: 0;
        margin-right: 1.5625rem;
        padding-left: .3125rem
    }

    .navbar-vertical.navbar-expand-md.navbar-card .navbar-collapse,.navbar-vertical.navbar-expand-md.navbar-inverted .navbar-collapse,.navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important;
        margin-left: 0!important;
        padding: 1rem!important;
        height: calc(100vh - 5.3125rem)
    }

    .navbar-vertical.navbar-expand-md.navbar-card+.content,.navbar-vertical.navbar-expand-md.navbar-inverted+.content,.navbar-vertical.navbar-expand-md.navbar-vibrant+.content {
        margin-left: 15.5rem
    }

    .navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-collapse.ps {
        overflow-y: auto!important
    }
}

@media(max-width: 767.98px) {
    .navbar-vertical.navbar-expand-md {
        margin-left:-15px;
        margin-right: -15px
    }

    .navbar-vertical.navbar-expand-md+.content .navbar-top .navbar-toggler {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-md .navbar-nav {
        padding-left: 15px;
        padding-right: 15px
    }

    .navbar-vertical.navbar-expand-md .navbar-collapse.show {
        max-height: calc(100vh - 4.3125rem);
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-md.navbar-card .navbar-collapse.show,.navbar-vertical.navbar-expand-md.navbar-inverted .navbar-collapse.show,.navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-collapse.show {
        max-height: calc(100vh - 5.3125rem)
    }
}

@media(max-width: 575.98px) {
    .container-fluid .navbar-vertical.navbar-expand-sm,.container-lg .navbar-vertical.navbar-expand-sm,.container-md .navbar-vertical.navbar-expand-sm,.container-sm .navbar-vertical.navbar-expand-sm,.container-xl .navbar-vertical.navbar-expand-sm,.container-xxl .navbar-vertical.navbar-expand-sm {
        width:100%;
        max-width: 100vw!important
    }

    .container-fluid .navbar-vertical.navbar-expand-sm+.content .navbar-top,.container-lg .navbar-vertical.navbar-expand-sm+.content .navbar-top,.container-md .navbar-vertical.navbar-expand-sm+.content .navbar-top,.container-sm .navbar-vertical.navbar-expand-sm+.content .navbar-top,.container-xl .navbar-vertical.navbar-expand-sm+.content .navbar-top,.container-xxl .navbar-vertical.navbar-expand-sm+.content .navbar-top {
        width: 100%;
        max-width: 100vw!important
    }
}

@media(min-width: 576px) {
    .navbar-vertical.navbar-expand-sm {
        position:fixed;
        width: 100%;
        top: 0;
        max-height: 100vh;
        display: block;
        max-width: 12.625rem
    }

    .navbar-vertical.navbar-expand-sm .navbar-vertical-toggle {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-sm .navbar-brand {
        display: block;
        text-align: center
    }

    .navbar-vertical.navbar-expand-sm+.content {
        margin-left: 15.625rem
    }

    .navbar-vertical.navbar-expand-sm+.content .navbar-top .navbar-brand,.navbar-vertical.navbar-expand-sm+.content .navbar-top .navbar-toggler {
        display: none
    }

    .navbar-vertical.navbar-expand-sm .navbar-collapse {
        position: relative;
        display: inline-block!important;
        width: 12.625rem;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        padding-top: .5rem;
        height: calc(100vh - 4.3125rem);
        margin-top: -.3125rem;
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-sm .settings .custom-control-label {
        white-space: nowrap
    }

    .safari .navbar-vertical.navbar-expand-sm .navbar-vertical .navbar-collapse {
        margin-top: .3125rem;
        padding-top: .5625rem
    }

    .navbar-vertical.navbar-expand-sm .navbar-vertical-divider {
        padding: 0
    }

    .navbar-vertical.navbar-expand-sm.navbar-card,.navbar-vertical.navbar-expand-sm.navbar-inverted,.navbar-vertical.navbar-expand-sm.navbar-vibrant {
        padding: 0;
        max-width: 14.5rem!important
    }

    .navbar-vertical.navbar-expand-sm.navbar-card .toggle-icon-wrapper,.navbar-vertical.navbar-expand-sm.navbar-inverted .toggle-icon-wrapper,.navbar-vertical.navbar-expand-sm.navbar-vibrant .toggle-icon-wrapper {
        margin-left: 0;
        margin-right: 1.5625rem;
        padding-left: .3125rem
    }

    .navbar-vertical.navbar-expand-sm.navbar-card .navbar-collapse,.navbar-vertical.navbar-expand-sm.navbar-inverted .navbar-collapse,.navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important;
        margin-left: 0!important;
        padding: 1rem!important;
        height: calc(100vh - 5.3125rem)
    }

    .navbar-vertical.navbar-expand-sm.navbar-card+.content,.navbar-vertical.navbar-expand-sm.navbar-inverted+.content,.navbar-vertical.navbar-expand-sm.navbar-vibrant+.content {
        margin-left: 15.5rem
    }

    .navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-collapse.ps {
        overflow-y: auto!important
    }
}

@media(max-width: 575.98px) {
    .navbar-vertical.navbar-expand-sm {
        margin-left:-15px;
        margin-right: -15px
    }

    .navbar-vertical.navbar-expand-sm+.content .navbar-top .navbar-toggler {
        display: -webkit-box;
        display: flex
    }

    .navbar-vertical.navbar-expand-sm .navbar-nav {
        padding-left: 15px;
        padding-right: 15px
    }

    .navbar-vertical.navbar-expand-sm .navbar-collapse.show {
        max-height: calc(100vh - 4.3125rem);
        overflow-y: auto
    }

    .navbar-vertical.navbar-expand-sm.navbar-card .navbar-collapse.show,.navbar-vertical.navbar-expand-sm.navbar-inverted .navbar-collapse.show,.navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-collapse.show {
        max-height: calc(100vh - 5.3125rem)
    }
}

@media(max-width: 575.98px) {
    .navbar-vertical {
        position:fixed;
        width: 100%;
        max-width: 100vw!important
    }

    .content {
        padding-top: 4.3125rem
    }
}

.toggle-icon-wrapper {
    margin-left: -.75rem;
    padding-left: .125rem;
    margin-right: 1.25rem
}

@media(min-width: 1540px) {
    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl {
        z-index:1030;
        width: 3.125rem;
        height: 100vh;
        background-color: transparent
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-collapse {
        width: 3.125rem!important;
        padding-right: .9375rem;
        padding-left: .9375rem;
        margin-left: -.9375rem;
        overflow: hidden
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .dropdown-indicator:after,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-link .badge,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-link-text,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .settings {
        opacity: 0;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-toggle-icon {
        padding-right: .3125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-toggle-icon:after {
        width: 75%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-toggle-icon:before {
        width: 50%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-divider {
        padding-left: 0;
        padding-right: .25rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl+.content {
        margin-left: 3.125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl+.content .navbar-top {
        padding-left: 13.5rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-card+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-inverted+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-vibrant+.content {
        margin-left: 4.375rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-card+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-inverted+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-vibrant+.content .navbar-top {
        padding-left: 12.125rem
    }

    .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xxl .collapse.show,.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xxl .navbar-collapse {
        display: none
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl {
        width: 12.625rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .navbar-collapse {
        width: 12.625rem!important;
        box-shadow: 10px 0 10px -9px rgba(0,0,0,.2);
        overflow: auto
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-collapse {
        box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .dropdown-indicator:after,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .nav-link .badge,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .nav-link-text,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .settings {
        opacity: 1
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .navbar-vertical-divider {
        padding-right: 0!important
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-card,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-inverted,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-vibrant {
        width: 14.5rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-inverted .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important
    }
}

@media(min-width: 1200px) {
    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl {
        z-index:1030;
        width: 3.125rem;
        height: 100vh;
        background-color: transparent
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-collapse {
        width: 3.325rem!important;
        padding-right: .9375rem;
        padding-left: .9375rem;
        margin-left: -.9375rem;
        overflow: hidden
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .dropdown-indicator:after,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-link .badge,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-link-text,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .settings {
        opacity: 0;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-toggle-icon {
        padding-right: .3125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-toggle-icon:after {
        width: 75%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-toggle-icon:before {
        width: 50%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-divider {
        padding-left: 0;
        padding-right: .25rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 3.125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl+.content .navbar-top {
        padding-left: 13.5rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-card+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-inverted+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-vibrant+.content {
        margin-left: 4.375rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-card+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-inverted+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-vibrant+.content .navbar-top {
        padding-left: 12.125rem
    }

    .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xl .collapse.show,.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xl .navbar-collapse {
        display: none
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl {
        width: 12.625rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .navbar-collapse {
        width: 12.625rem!important;
        box-shadow: 10px 0 10px -9px rgba(0,0,0,.2);
        overflow: auto
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-card .navbar-collapse {
        box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .dropdown-indicator:after,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .nav-link .badge,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .nav-link-text,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .settings {
        opacity: 1
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .navbar-vertical-divider {
        padding-right: 0!important
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-card,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-inverted,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-vibrant {
        width: 14.5rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-card .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important
    }
}

@media(min-width: 992px) {
    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg {
        z-index:1030;
        width: 3.125rem;
        height: 100vh;
        background-color: transparent
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-collapse {
        width: 3.125rem!important;
        padding-right: .9375rem;
        padding-left: .9375rem;
        margin-left: -.9375rem;
        overflow: hidden
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .dropdown-indicator:after,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-link .badge,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-link-text,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .settings {
        opacity: 0;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-toggle-icon {
        padding-right: .3125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-toggle-icon:after {
        width: 75%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-toggle-icon:before {
        width: 50%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-divider {
        padding-left: 0;
        padding-right: .25rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg+.content {
        margin-left: 3.125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg+.content .navbar-top {
        padding-left: 13.5rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-card+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-inverted+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-vibrant+.content {
        margin-left: 4.375rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-card+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-inverted+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-vibrant+.content .navbar-top {
        padding-left: 12.125rem
    }

    .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-lg .collapse.show,.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-lg .navbar-collapse {
        display: none
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg {
        width: 12.625rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .navbar-collapse {
        width: 12.625rem!important;
        box-shadow: 10px 0 10px -9px rgba(0,0,0,.2);
        overflow: auto
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-card .navbar-collapse {
        box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .dropdown-indicator:after,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .nav-link .badge,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .nav-link-text,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .settings {
        opacity: 1
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .navbar-vertical-divider {
        padding-right: 0!important
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-card,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-inverted,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-vibrant {
        width: 14.5rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-card .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-inverted .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important
    }
}

@media(min-width: 768px) {
    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md {
        z-index:1030;
        width: 3.125rem;
        height: 100vh;
        background-color: transparent
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-collapse {
        width: 3.125rem!important;
        padding-right: .9375rem;
        padding-left: .9375rem;
        margin-left: -.9375rem;
        overflow: hidden
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .dropdown-indicator:after,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-link .badge,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-link-text,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .settings {
        opacity: 0;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-toggle-icon {
        padding-right: .3125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-toggle-icon:after {
        width: 75%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-toggle-icon:before {
        width: 50%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-divider {
        padding-left: 0;
        padding-right: .25rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md+.content {
        margin-left: 3.125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md+.content .navbar-top {
        padding-left: 13.5rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-card+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-inverted+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-vibrant+.content {
        margin-left: 4.375rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-card+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-inverted+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-vibrant+.content .navbar-top {
        padding-left: 12.125rem
    }

    .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-md .collapse.show,.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-md .navbar-collapse {
        display: none
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md {
        width: 12.625rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .navbar-collapse {
        width: 12.625rem!important;
        box-shadow: 10px 0 10px -9px rgba(0,0,0,.2);
        overflow: auto
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-card .navbar-collapse {
        box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .dropdown-indicator:after,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .nav-link .badge,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .nav-link-text,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .settings {
        opacity: 1
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .navbar-vertical-divider {
        padding-right: 0!important
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-card,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-inverted,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-vibrant {
        width: 14.5rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-card .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-inverted .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important
    }
}

@media(min-width: 576px) {
    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm {
        z-index:1030;
        width: 3.125rem;
        height: 100vh;
        background-color: transparent
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-collapse {
        width: 3.125rem!important;
        padding-right: .9375rem;
        padding-left: .9375rem;
        margin-left: -.9375rem;
        overflow: hidden
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .dropdown-indicator:after,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-link .badge,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-link-text,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .settings {
        opacity: 0;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-toggle-icon {
        padding-right: .3125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-toggle-icon:after {
        width: 75%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-toggle-icon:before {
        width: 50%
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-divider {
        padding-left: 0;
        padding-right: .25rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm+.content {
        margin-left: 3.125rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm+.content .navbar-top {
        padding-left: 13.5rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-card+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-inverted+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-vibrant+.content {
        margin-left: 4.375rem
    }

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-card+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-inverted+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-vibrant+.content .navbar-top {
        padding-left: 12.125rem
    }

    .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-sm .collapse.show,.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-sm .navbar-collapse {
        display: none
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm {
        width: 12.625rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .navbar-collapse {
        width: 12.625rem!important;
        box-shadow: 10px 0 10px -9px rgba(0,0,0,.2);
        overflow: auto
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-card .navbar-collapse {
        box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .dropdown-indicator:after,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .nav-link .badge,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .nav-link-text,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .settings {
        opacity: 1
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .navbar-vertical-divider {
        padding-right: 0!important
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-card,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-inverted,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-vibrant {
        width: 14.5rem
    }

    .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-card .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-inverted .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-collapse {
        width: 14.5rem!important
    }
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs {
    z-index: 1030;
    width: 3.125rem;
    height: 100vh;
    background-color: transparent
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-collapse {
    width: 3.125rem!important;
    padding-right: .9375rem;
    padding-left: .9375rem;
    margin-left: -.9375rem;
    overflow: hidden
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .dropdown-indicator:after,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-link .badge,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-link-text,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .settings {
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-toggle-icon {
    padding-right: .3125rem
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-toggle-icon:after {
    width: 75%
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-toggle-icon:before {
    width: 50%
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-divider {
    padding-left: 0;
    padding-right: .25rem
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs+.content {
    margin-left: 3.125rem
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs+.content .navbar-top {
    padding-left: 13.5rem
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-card+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-inverted+.content,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-vibrant+.content {
    margin-left: 4.375rem
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-card+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-inverted+.content .navbar-top,.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-vibrant+.content .navbar-top {
    padding-left: 12.125rem
}

.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xs .collapse.show,.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xs .navbar-collapse {
    display: none
}

.navbar-vertical-collapsed-hover .navbar-collapse {
    box-shadow: 7px 10px 20px 0px #00000045!important;
}
.navbar-vertical-collapsed .navbar-nav .nav-item .nav-link-icon {
    text-align: center;
    color: #9da9bb!important;
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs {
    width: 12.625rem
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .navbar-collapse {
    width: 12.625rem!important;
    box-shadow: 10px 0 10px -9px rgba(0,0,0,.2);
    overflow: auto
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-card .navbar-collapse {
    box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .dropdown-indicator:after,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .nav-link .badge,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .nav-link-text,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .settings {
    opacity: 1
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .navbar-vertical-divider {
    padding-right: 0!important
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-card,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-inverted,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-vibrant {
    width: 14.5rem
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-card .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-inverted .navbar-collapse,.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-vibrant .navbar-collapse {
    width: 14.5rem!important
}

.navbar-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    font-size: .83333rem;
    font-weight: 600;
    margin-left: -15px;
    margin-right: -15px
}

.navbar-top .navbar-nav-icons .dropdown-menu {
    position: absolute
}

.navbar-top .navbar-collapse {
    overflow: auto;
    max-height: calc(100vh - 4.3125rem);
    margin: 0 -.75rem;
    padding: 0 .75rem;
    -webkit-box-ordinal-group: 2;
    order: 1
}

.navbar-top .navbar-toggler {
    margin-left: -.625rem
}

.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after,.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
    display: none
}

.dropdown-menu-card {
    padding: 0
}

.dropdown-menu-card .card {
    border: 0
}

.navbar-toggler {
    border: 0;
    padding: 0
}

.card-notification {
    min-width: 20rem
}

@media(max-width: 575.98px) {
    .card-notification {
        min-width:16rem
    }
}

.settings-popover {
    position: relative;
    padding: 0;
    height: 30px;
    width: 30px
}

.navbar-standard {
    font-size: .83333rem;
    font-weight: 600
}

.navbar-standard .navbar-collapse {
    overflow-y: auto;
    max-height: calc(100vh - 57px)
}

.edge .navbar-top,.ie .navbar-top {
    position: relative
}

@media(min-width: 576px) {
    .navbar-expand-sm.navbar-top .navbar-collapse {
        max-height:none;
        margin: 0;
        padding: 0;
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .navbar-expand-sm.navbar .max-h-dropdown {
        max-height: 75vh
    }

    .navbar-expand-sm.navbar-standard .navbar-collapse {
        max-height: none
    }

    .navbar-expand-sm.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after,.navbar-expand-sm.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
        display: block
    }

    .navbar.navbar-expand-sm:not(.navbar-vertical) .navbar-collapse {
        overflow: visible
    }
}

@media(min-width: 768px) {
    .navbar-expand-md.navbar-top .navbar-collapse {
        max-height:none;
        margin: 0;
        padding: 0;
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .navbar-expand-md.navbar .max-h-dropdown {
        max-height: 75vh
    }

    .navbar-expand-md.navbar-standard .navbar-collapse {
        max-height: none
    }

    .navbar-expand-md.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after,.navbar-expand-md.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
        display: block
    }

    .navbar.navbar-expand-md:not(.navbar-vertical) .navbar-collapse {
        overflow: visible
    }
}

@media(min-width: 992px) {
    .navbar-expand-lg.navbar-top .navbar-collapse {
        max-height:none;
        margin: 0;
        padding: 0;
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .navbar-expand-lg.navbar .max-h-dropdown {
        max-height: 75vh
    }

    .navbar-expand-lg.navbar-standard .navbar-collapse {
        max-height: none
    }

    .navbar-expand-lg.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after,.navbar-expand-lg.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
        display: block
    }

    .navbar.navbar-expand-lg:not(.navbar-vertical) .navbar-collapse {
        overflow: visible
    }
}

@media(min-width: 1200px) {
    .navbar-expand-xl.navbar-top .navbar-collapse {
        max-height:none;
        margin: 0;
        padding: 0;
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .navbar-expand-xl.navbar .max-h-dropdown {
        max-height: 75vh
    }

    .navbar-expand-xl.navbar-standard .navbar-collapse {
        max-height: none
    }

    .navbar-expand-xl.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after,.navbar-expand-xl.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
        display: block
    }

    .navbar.navbar-expand-xl:not(.navbar-vertical) .navbar-collapse {
        overflow: visible
    }
}

@media(min-width: 1540px) {
    .navbar-expand-xxl.navbar-top .navbar-collapse {
        max-height:none;
        margin: 0;
        padding: 0;
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .navbar-expand-xxl.navbar .max-h-dropdown {
        max-height: 75vh
    }

    .navbar-expand-xxl.navbar-standard .navbar-collapse {
        max-height: none
    }

    .navbar-expand-xxl.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after,.navbar-expand-xxl.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
        display: block
    }

    .navbar.navbar-expand-xxl:not(.navbar-vertical) .navbar-collapse {
        overflow: visible
    }
}

@media(max-width: 575.98px) {
    .navbar-top {
        position:fixed;
        width: 100%;
        max-width: 100vw!important
    }
}

@media(min-width: 768px) {
    .navbar-card-components {
        min-width:20rem
    }

    .navbar-card-auth {
        min-width: 21rem
    }

    .navbar-card-pages {
        min-width: 29.6875rem
    }
}

@media(min-width: 992px) {
    .navbar-card-login {
        min-width:22rem
    }
}

@media(min-width: 1200px) {
    .navbar-card-components {
        min-width:43.5625rem
    }
}

@media(min-width: 1540px) {
    .navbar-card-auth {
        min-width:40.375rem
    }
}

.badge-soft-primary {
    color: #1c4f93;
    background-color: #d5e5fa
}

.badge-soft-primary[href]:focus,.badge-soft-primary[href]:hover {
    text-decoration: none;
    color: #1c4f93;
    background-color: #a7c9f5
}

.badge-soft-secondary {
    color: #7d899b;
    background-color: #e3e6ea
}

.badge-soft-secondary[href]:focus,.badge-soft-secondary[href]:hover {
    text-decoration: none;
    color: #7d899b;
    background-color: #c6ccd4
}

.badge-soft-success {
    color: #00864e;
    background-color: #ccf6e4
}

.badge-soft-success[href]:focus,.badge-soft-success[href]:hover {
    text-decoration: none;
    color: #00864e;
    background-color: #a1eecd
}

.badge-soft-info {
    color: #1978a2;
    background-color: #d4f2ff
}

.badge-soft-info[href]:focus,.badge-soft-info[href]:hover {
    text-decoration: none;
    color: #1978a2;
    background-color: #a1e3ff
}

.badge-soft-warning {
    color: #c46632;
    background-color: #fde6d8
}

.badge-soft-warning[href]:focus,.badge-soft-warning[href]:hover {
    text-decoration: none;
    color: #c46632;
    background-color: #fbc7a7
}

.badge-soft-danger {
    color: #932338;
    background-color: #fad7dd
}

.badge-soft-danger[href]:focus,.badge-soft-danger[href]:hover {
    text-decoration: none;
    color: #932338;
    background-color: #f4aab6
}

.badge-soft-light {
    color: #9fa0a2;
    background-color: #fefeff
}

.badge-soft-light[href]:focus,.badge-soft-light[href]:hover {
    text-decoration: none;
    color: #9fa0a2;
    background-color: #cbcbff
}

.badge-soft-dark {
    color: #070f19;
    background-color: #ced1d4
}

.badge-soft-dark[href]:focus,.badge-soft-dark[href]:hover {
    text-decoration: none;
    color: #070f19;
    background-color: #b3b8bc
}

.card {
    box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)
}

.card-link {
    font-size: .83333rem;
    font-weight: 600
}

.card-title {
    font-weight: 600;
    font-size: 1.2rem
}

.card-body:last-child {
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.card-notification .card-footer,.card-notification .card-header {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.card-notification .list-group-flush .list-group-item {
    padding: 0
}

.card-notification .list-group-flush .list-group-item .notification-flush {
    padding: .5rem 1rem
}

.card-notification .list-group-flush .list-group-title {
    background-color: #f9fafd;
    font-weight: 600;
    font-size: .69444rem;
    color: #748194;
    padding: .25rem 1.25rem
}

.p-card,.pl-card,.px-card {
    padding-left: 1.25rem!important
}

.p-card,.pr-card,.px-card {
    padding-right: 1.25rem!important
}

.p-card,.pt-card,.py-card {
    padding-top: 1rem!important
}

.p-card,.pb-card,.py-card {
    padding-bottom: 1rem!important
}

.mt-card {
    margin-top: 1.25rem!important
}

.mr-card {
    margin-right: 1.25rem!important
}

.notification {
    color: #232e3c;
    text-decoration: none;
    display: -webkit-box;
    display: flex;
    font-size: .83333rem;
    font-family: open sans,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.notification:not(.notification-flush) {
    border: 1px solid #f9fafd;
    background-color: #fff;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    border-radius: .375rem;
    padding: 1rem
}

.notification:focus,.notification:hover {
    color: #0b1727;
    text-decoration: none
}

.notification-body {
    margin-bottom: .25rem
}

.notification-time {
    margin-bottom: 0;
    color: #748194;
    font-size: .83333rem
}

.notification-indicator {
    position: relative
}

.notification-indicator::before {
    position: absolute;
    content: '';
    right: .125rem;
    top: .5rem;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    border: 2px solid #edf2f9
}

.notification-indicator-number {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    right: .125rem;
    top: .505rem;
    height: 1rem;
    width: 1rem;
    font-size: .67rem;
    color: #fff;
    font-weight: 700
}

.notification-indicator-primary::before {
    background-color: #2c7be5
}

.notification-indicator-warning::before {
    background-color: #f5803e
}

.notification-indicator-fill::before {
    border: 0
}

.btn.disabled,.btn:disabled {
    cursor: not-allowed
}

.btn-reveal-trigger:focus .btn-reveal,.btn-reveal-trigger:hover .btn-reveal {
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.07),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-reveal-trigger:focus .btn-reveal:active,.btn-reveal-trigger:focus .btn-reveal:focus,.btn-reveal-trigger:focus .btn-reveal:hover,.btn-reveal-trigger:hover .btn-reveal:active,.btn-reveal-trigger:hover .btn-reveal:focus,.btn-reveal-trigger:hover .btn-reveal:hover {
    color: #0b1727!important
}

.btn-reveal-trigger .btn-reveal:active,.btn-reveal-trigger .btn-reveal:active:focus,.btn-reveal-trigger .btn-reveal:focus {
    box-shadow: none;
    color: #0b1727!important;
    background-color: #e6e6e6;
    border-color: #dfdfdf
}

.btn-reveal-trigger .dropdown-toggle:after {
    display: none
}

.btn-falcon-primary {
    color: #2c7be5!important;
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.07),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-primary:hover {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-primary.focus,.btn-falcon-primary:focus {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-primary.disabled,.btn-falcon-primary:disabled {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-primary:not(:disabled):not(.disabled).active,.btn-falcon-primary:not(:disabled):not(.disabled):active,.show>.btn-falcon-primary.dropdown-toggle {
    color: #4d5969;
    background-color: #d5e5fa;
    border-color: #d5e5fa
}

.btn-falcon-primary:not(:disabled):not(.disabled).active:focus,.btn-falcon-primary:not(:disabled):not(.disabled):active:focus,.show>.btn-falcon-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-primary:focus:not(.disabled),.btn-falcon-primary:hover:not(.disabled) {
    color: #1966cc!important;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-primary:active {
    box-shadow: none;
    color: #1966cc!important
}

.btn-falcon-success {
    color: #00d27a!important;
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.07),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-success:hover {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-success.focus,.btn-falcon-success:focus {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-success.disabled,.btn-falcon-success:disabled {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-success:not(:disabled):not(.disabled).active,.btn-falcon-success:not(:disabled):not(.disabled):active,.show>.btn-falcon-success.dropdown-toggle {
    color: #4d5969;
    background-color: #ccf6e4;
    border-color: #ccf6e4
}

.btn-falcon-success:not(:disabled):not(.disabled).active:focus,.btn-falcon-success:not(:disabled):not(.disabled):active:focus,.show>.btn-falcon-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-success:focus:not(.disabled),.btn-falcon-success:hover:not(.disabled) {
    color: #00a761!important;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-success:active {
    box-shadow: none;
    color: #00a761!important
}

.btn-falcon-info {
    color: #27bcfd!important;
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.07),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-info:hover {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-info.focus,.btn-falcon-info:focus {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-info.disabled,.btn-falcon-info:disabled {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-info:not(:disabled):not(.disabled).active,.btn-falcon-info:not(:disabled):not(.disabled):active,.show>.btn-falcon-info.dropdown-toggle {
    color: #4d5969;
    background-color: #d4f2ff;
    border-color: #d4f2ff
}

.btn-falcon-info:not(:disabled):not(.disabled).active:focus,.btn-falcon-info:not(:disabled):not(.disabled):active:focus,.show>.btn-falcon-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-info:focus:not(.disabled),.btn-falcon-info:hover:not(.disabled) {
    color: #02acf6!important;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-info:active {
    box-shadow: none;
    color: #02acf6!important
}

.btn-falcon-warning {
    color: #f5803e!important;
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.07),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-warning:hover {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-warning.focus,.btn-falcon-warning:focus {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-warning.disabled,.btn-falcon-warning:disabled {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-warning:not(:disabled):not(.disabled).active,.btn-falcon-warning:not(:disabled):not(.disabled):active,.show>.btn-falcon-warning.dropdown-toggle {
    color: #4d5969;
    background-color: #fde6d8;
    border-color: #fde6d8
}

.btn-falcon-warning:not(:disabled):not(.disabled).active:focus,.btn-falcon-warning:not(:disabled):not(.disabled):active:focus,.show>.btn-falcon-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-warning:focus:not(.disabled),.btn-falcon-warning:hover:not(.disabled) {
    color: #f36515!important;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-warning:active {
    box-shadow: none;
    color: #f36515!important
}

.btn-falcon-danger {
    color: #e63757!important;
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.07),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-danger:hover {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-danger.focus,.btn-falcon-danger:focus {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-danger.disabled,.btn-falcon-danger:disabled {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-danger:not(:disabled):not(.disabled).active,.btn-falcon-danger:not(:disabled):not(.disabled):active,.show>.btn-falcon-danger.dropdown-toggle {
    color: #4d5969;
    background-color: #fad7dd;
    border-color: #fad7dd
}

.btn-falcon-danger:not(:disabled):not(.disabled).active:focus,.btn-falcon-danger:not(:disabled):not(.disabled):active:focus,.show>.btn-falcon-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-danger:focus:not(.disabled),.btn-falcon-danger:hover:not(.disabled) {
    color: #d71b3d!important;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-danger:active {
    box-shadow: none;
    color: #d71b3d!important
}

.btn-falcon-default {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.07),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-default:hover {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-default.focus,.btn-falcon-default:focus {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-default.disabled,.btn-falcon-default:disabled {
    color: #4d5969;
    background-color: #fff;
    border-color: #fff
}

.btn-falcon-default:not(:disabled):not(.disabled).active,.btn-falcon-default:not(:disabled):not(.disabled):active,.show>.btn-falcon-default.dropdown-toggle {
    color: #4d5969;
    background-color: #e6e6e6;
    border-color: #dfdfdf
}

.btn-falcon-default:not(:disabled):not(.disabled).active:focus,.btn-falcon-default:not(:disabled):not(.disabled):active:focus,.show>.btn-falcon-default.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(228,230,233,.5)
}

.btn-falcon-default:focus:not(.disabled),.btn-falcon-default:hover:not(.disabled) {
    color: #010305!important;
    box-shadow: 0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)
}

.btn-falcon-default:active {
    box-shadow: none;
    color: #010305!important
}

.btn-outline-primary:active {
    background-color: #1b6edc!important;
    border-color: #1b6edc!important
}

.btn-outline-secondary:active {
    background-color: #687487!important;
    border-color: #687487!important
}

.btn-outline-success:active {
    background-color: #00b96b!important;
    border-color: #00b96b!important
}

.btn-outline-info:active {
    background-color: #0eb4fd!important;
    border-color: #0eb4fd!important
}

.btn-outline-warning:active {
    background-color: #f47026!important;
    border-color: #f47026!important
}

.btn-outline-danger:active {
    background-color: #e32044!important;
    border-color: #e32044!important
}

.btn-outline-light:active {
    background-color: #e6eaf7!important;
    border-color: #e6eaf7!important
}

.btn-outline-dark:active {
    background-color: #050b13!important;
    border-color: #050b13!important
}

.btn-outline-facebook {
    color: #3c5a99;
    border-color: #3c5a99
}

.btn-outline-facebook:hover {
    color: #fff;
    background-color: #3c5a99;
    border-color: #3c5a99
}

.btn-outline-facebook.focus,.btn-outline-facebook:focus {
    box-shadow: 0 0 0 0 rgba(60,90,153,.5)
}

.btn-outline-facebook.disabled,.btn-outline-facebook:disabled {
    color: #3c5a99;
    background-color: transparent
}

.btn-outline-facebook:not(:disabled):not(.disabled).active,.btn-outline-facebook:not(:disabled):not(.disabled):active,.show>.btn-outline-facebook.dropdown-toggle {
    color: #fff;
    background-color: #3c5a99;
    border-color: #3c5a99
}

.btn-outline-facebook:not(:disabled):not(.disabled).active:focus,.btn-outline-facebook:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(60,90,153,.5)
}

.btn-outline-twitter {
    color: #1da1f2;
    border-color: #1da1f2
}

.btn-outline-twitter:hover {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2
}

.btn-outline-twitter.focus,.btn-outline-twitter:focus {
    box-shadow: 0 0 0 0 rgba(29,161,242,.5)
}

.btn-outline-twitter.disabled,.btn-outline-twitter:disabled {
    color: #1da1f2;
    background-color: transparent
}

.btn-outline-twitter:not(:disabled):not(.disabled).active,.btn-outline-twitter:not(:disabled):not(.disabled):active,.show>.btn-outline-twitter.dropdown-toggle {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2
}

.btn-outline-twitter:not(:disabled):not(.disabled).active:focus,.btn-outline-twitter:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(29,161,242,.5)
}

.btn-outline-google-plus {
    color: #dd4b39;
    border-color: #dd4b39
}

.btn-outline-google-plus:hover {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39
}

.btn-outline-google-plus.focus,.btn-outline-google-plus:focus {
    box-shadow: 0 0 0 0 rgba(221,75,57,.5)
}

.btn-outline-google-plus.disabled,.btn-outline-google-plus:disabled {
    color: #dd4b39;
    background-color: transparent
}

.btn-outline-google-plus:not(:disabled):not(.disabled).active,.btn-outline-google-plus:not(:disabled):not(.disabled):active,.show>.btn-outline-google-plus.dropdown-toggle {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39
}

.btn-outline-google-plus:not(:disabled):not(.disabled).active:focus,.btn-outline-google-plus:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-google-plus.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(221,75,57,.5)
}

.btn-intro-collapse .less {
    display: none
}

.btn-intro-collapse[aria-expanded=true] .less {
    display: inline
}

.btn-intro-collapse[aria-expanded=true] .full {
    display: none
}

.btn-navbar-top,.btn-navbar-vertical {
    border-color: #b6c1d2
}

.btn-navbar-top.active,.btn-navbar-vertical.active {
    border-color: #2c7be5;
    background-color: #2c7be5;
    color: #fff
}

.btn-theme-dark,.btn-theme-default {
    width: 50%;
    padding: 0
}

.btn-theme-dark.focus,.btn-theme-dark:focus,.btn-theme-default.focus,.btn-theme-default:focus {
    box-shadow: none
}

.btn-theme-dark .custom-control-label,.btn-theme-default .custom-control-label {
    cursor: pointer
}

.btn-theme-dark .hover-overlay,.btn-theme-default .hover-overlay {
    border: 3px solid transparent;
    position: relative;
    overflow: hidden;
    border-radius: .375rem;
    display: block
}

.btn-theme-dark .hover-overlay:after,.btn-theme-default .hover-overlay:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 1;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.btn-theme-dark:focus .hover-overlay:after,.btn-theme-dark:hover .hover-overlay:after,.btn-theme-default:focus .hover-overlay:after,.btn-theme-default:hover .hover-overlay:after {
    background: rgba(0,0,0,.2)
}

.btn-theme-dark.active .hover-overlay,.btn-theme-default.active .hover-overlay {
    border: 3px solid #00d27a
}

.btn-theme-default {
    margin-right: .5rem
}

.btn-theme-dark {
    margin-left: .5rem
}

.btn-group-navbar-style .btn {
    border-radius: .25rem!important
}

.btn-group-navbar-style .btn label:first-child {
    background-color: #f9fafd;
    border: 3px solid #d8e2ef;
    border-radius: .375rem
}

.btn-group-navbar-style .btn.active label:first-child {
    border-color: #00d27a
}

.btn-group-navbar-style .btn.focus,.btn-group-navbar-style .btn:focus {
    box-shadow: none!important
}

.btn-group-navbar-style .btn.disabled label {
    cursor: not-allowed
}

.code-block {
    padding: 0;
    overflow: auto
}

.code-block pre[class*=language-] {
    padding: 1.6rem;
    border: 0;
    margin: 0;
    border-radius: 0
}

.contains-anchor a {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity
}

.contains-anchor:focus a,.contains-anchor:hover a {
    opacity: 1;
    text-decoration: none
}

.token.constant,.token.deleted,.token.property,.token.symbol,.token.tag {
    color: #e63757
}

.token.punctuation {
    color: #b6c1d2
}

.components-nav {
    font-family: Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
    font-size: .85rem
}

.component-example {
    font-family: Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol
}

.component-example [class^=border] {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    margin: .75rem;
    background-color: #f9fafd
}

.border-component [class^=border],.border-component [class^=rounded-] {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin: .25rem
}

.border-component [class^=border] {
    background-color: #edf2f9
}

.border-component [class^=rounded-] {
    background-color: #344050
}

.grid-component [class^=col] {
    border: 1px solid #b6c1d2;
    text-align: center;
    margin-bottom: 10px;
    color: #5e6e82;
    padding: .5rem;
    font-size: .83333rem
}

.dropdown-menu {
    font-size: .83333rem;
    overflow: hidden;
    border-radius: .375rem;
    box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)
}

.dropdown-header {
    padding: .25rem 1rem;
    text-transform: uppercase;
    font-size: .69444rem;
    font-weight: 600;
    letter-spacing: .04em
}

.dropdown-item {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.dropdown-caret-none::after {
    display: none!important
}

.dropdown-menu-small {
    min-width: 7rem
}

.dropdown-indicator {
    position: relative
}

.dropdown-indicator:after {
    content: '';
    display: block;
    position: absolute;
    right: 5px;
    height: .4rem;
    width: .4rem;
    border-right: 1px solid #5e6e82;
    border-bottom: 1px solid #5e6e82;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform
}

.dropdown-indicator[aria-expanded=true]:after {
    -webkit-transform: translateY(-50%) rotate(225deg);
    transform: translateY(-50%) rotate(225deg)
}

.dropdown-md {
    min-width: 15.625rem
}

label {
    font-size: .83333rem;
    font-weight: 700;
    letter-spacing: .02em
}

.custom-control-label {
    line-height: 1.45rem
}

.input-spin-none::-webkit-inner-spin-button,.input-spin-none::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.input-spin-none[type=number] {
    -moz-appearance: textfield
}

.custom-radio-success .custom-control-input:not(:disabled):active~.custom-control-label::before {
    background-color: rgba(0,210,122,.2);
    border-color: rgba(0,210,122,.2)
}

.custom-radio-success .custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 0 0 .2rem rgba(0,210,122,.25)
}

.custom-radio-success .custom-control-input:checked~.custom-control-label:before {
    background-color: #00d27a;
    border-color: #00d27a
}

.hoverbox {
    position: relative;
    overflow: hidden
}

.hoverbox .hoverbox-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    margin: 0!important;
    display: -webkit-box;
    display: flex
}

.hoverbox .hoverbox-primary-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%
}

.hoverbox:focus .hover-box-content-initial,.hoverbox:hover .hover-box-content-initial {
    opacity: 0
}

.hoverbox:focus .hoverbox-content,.hoverbox:hover .hoverbox-content {
    opacity: 1
}

.hoverbox:focus .as-hoverbox-content,.hoverbox:hover .as-hoverbox-content {
    z-index: 1
}

.hoverbox-content-gradient {
    background: -webkit-gradient(linear,left top,left bottom,from(transparent),to(#000));
    background: -webkit-linear-gradient(transparent,#000);
    background: linear-gradient(transparent,#000)
}

.fit-cover {
    object-fit: cover
}

.z-index--1 {
    z-index: -1
}

.z-index-0 {
    z-index: 0
}

.z-index-1 {
    z-index: 1
}

.z-index-2 {
    z-index: 2
}

.z-index-10 {
    z-index: 10
}

.sticky-sidebar {
    top: 85px
}

.circle-dashed {
    border: 1px dashed #2c7be5;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem
}

.outline-none:focus,.outline-none:hover {
    outline: 0;
    box-shadow: none
}

.hover-text-decoration-none:focus,.hover-text-decoration-none:hover {
    text-decoration: none
}

.resize-none {
    resize: none
}

.l-unset {
    left: unset!important
}

.img-flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
}

.transition-base {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.vertical-line:after {
    position: absolute;
    content: '';
    height: 75%;
    width: 1px;
    background: #d8e2ef;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.vertical-line.vertical-line-400:after {
    background-color: #b6c1d2
}

.opacity-25 {
    opacity: .25
}

.opacity-50 {
    opacity: .5
}

.opacity-75 {
    opacity: .75
}

.opacity-85 {
    opacity: .85
}

.tooltip {
    line-height: 1.3;
    font-weight: 600
}

.transition-none {
    -webkit-transition: none!important;
    transition: none!important
}

.table-dashboard-th-nowrap thead th {
    white-space: nowrap!important
}

.caret-4-asc,.caret-4-desc,.order-4,.order-4:after {
    border-right: .25rem solid transparent;
    border-left: .25rem solid transparent;
    margin-left: .5rem;
    display: inline-block;
    vertical-align: middle
}

.order-4 {
    border-bottom: .25rem solid #b6c1d2;
    border-top: 0;
    -webkit-transform: translateY(-70%);
    transform: translateY(-70%);
    position: relative
}

.order-4:after {
    content: '';
    position: absolute;
    left: -.75rem;
    top: .35rem;
    border-top: .25rem solid #b6c1d2;
    border-bottom: 0
}

.caret-4-asc {
    border-bottom: .25rem solid #b6c1d2;
    border-top: 0
}

.caret-4-desc {
    border-top: .25rem solid #b6c1d2;
    border-bottom: 0
}

.sortable,[data-row-selection] {
    cursor: pointer
}

.sortable:focus,[data-row-selection]:focus {
    outline: 0
}

.slick-slider:focus .slick-arrow,.slick-slider:hover .slick-arrow {
    opacity: .5
}

.slick-slider .slick-arrow {
    border-radius: 3.125rem;
    width: 3rem;
    height: 3rem;
    background-color: #fff!important;
    -webkit-transition: opacity .4s ease-in-out;
    transition: opacity .4s ease-in-out;
    box-shadow: .125rem .125rem .25rem rgba(0,0,0,.1);
    color: #5e6e82;
    opacity: 0;
    z-index: 999
}

.slick-slider .slick-arrow:before {
    display: none
}

.slick-slider .slick-arrow:focus,.slick-slider .slick-arrow:hover {
    opacity: 1
}

.slick-slider .slick-next:after,.slick-slider .slick-prev:after {
    content: '';
    position: absolute;
    height: .5rem;
    width: .5rem;
    left: 50%;
    top: 50%;
    background-color: transparent;
    border-right: 1px solid #000;
    border-top: 1px solid #000
}

.slick-slider .slick-next:after {
    -webkit-transform: translate3d(-60%,-50%,0) rotate(45deg);
    transform: translate3d(-60%,-50%,0) rotate(45deg)
}

.slick-slider .slick-prev:after {
    -webkit-transform: translate3d(-40%,-50%,0) rotate(-135deg);
    transform: translate3d(-40%,-50%,0) rotate(-135deg)
}

.slick-slider.slick-slider-arrow-inner .slick-next {
    right: 1rem
}

.slick-slider.slick-slider-arrow-inner .slick-prev {
    left: 1rem
}

.Toastify__toast {
    min-height: 0!important;
    border-radius: .25rem!important;
    padding: .3125rem 1rem!important;
    overflow: hidden;
    font-size: .875rem
}

.Toastify__toast .Toastify__close-button {
    padding: .3125rem 0!important
}

.Toastify__toast .Toastify__progress-bar {
    height: .125rem!important
}

.Toastify__toast--info {
    background-color: #27bcfd!important
}

.Toastify__toast--success {
    background-color: #00d27a!important
}

.Toastify__toast--error {
    background-color: #e63757!important
}

.Toastify__toast--default {
    background-color: #f9fafd!important;
    color: #4d5969!important
}

.Toastify__toast-body {
    padding: .5rem 0!important
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    50% {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    50% {
        opacity: 1
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

@keyframes fadeOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

.animate {
    -webkit-animation-duration: .8s;
    animation-duration: .8s
}

.quill .ql-snow {
    border-color: #edf2f9!important;
    border-left-width: 0!important;
    border-right-width: 0!important
}

.quill .ql-snow .ql-picker {
    color: #748194
}

.quill .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: 0;
    left: auto
}

.quill .ql-snow .ql-picker-label {
    color: #748194;
    border: none!important
}

.quill .ql-snow .ql-stroke {
    stroke: #748194
}

.quill .ql-snow .ql-fill {
    fill: #748194
}

.quill .ql-snow .ql-active,.quill .ql-snow .ql-selected {
    color: #2c7be5!important
}

.quill .ql-snow .ql-editor {
    padding: 1rem 1.25rem
}

.quill .ql-snow.ql-toolbar {
    padding-left: 0
}

.quill .ql-snow.ql-toolbar .ql-formats:first-child {
    padding-left: calc(1.25rem - 8px)
}

.react-select__control {
    border: 0!important;
    border-radius: 0!important;
    box-shadow: none!important;
    background-color: #fff!important
}

.react-select__control .react-select__value-container {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.react-select__control .react-select__placeholder {
    color: #b6c1d2;
    margin: 0
}

.react-select__control:focus,.react-select__control:hover {
    outline: 0!important;
    border: 0!important;
    border-radius: 0!important;
    box-shadow: none!important
}

.react-select__control .react-select__multi-value {
    background-color: #edf2f9!important;
    border-radius: .25rem
}

.react-select__menu .react-select__option {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.react-select__menu .react-select__option:focus,.react-select__menu .react-select__option:hover {
    color: #fff!important
}

.react-select__menu {
    background-color: #fff!important
}

.react-select__option {
    background-color: #fff!important;
    color: #0b1727!important
}

.react-select__option:focus,.react-select__option:hover {
    background-color: #2c7be5!important
}

.react-select__indicator-separator {
    background-color: #d8e2ef!important
}

.react-select__indicator svg {
    color: #d8e2ef!important
}

.progress-circle {
    height: 15.625rem;
    position: relative;
    font-family: Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol
}

.progress-circle .progressbar-text {
    text-align: center;
    color: #0b1727!important;
    margin-bottom: 0;
    line-height: 1
}

.progress-circle .progressbar-text span {
    font-size: 1rem;
    display: block
}

.progress-circle .progressbar-text .value {
    font-weight: 600;
    font-size: 2.98598rem
}

.progress-circle .progressbar-text b {
    font-size: 2.0736rem;
    font-weight: 600
}

.progress-circle svg {
    height: 100%;
    display: block
}

.progress-line {
    position: relative;
    font-family: Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol
}

.progress-line .progressbar-text {
    position: absolute;
    width: 100%;
    color: #0b1727;
    left: 0;
    top: -1rem;
    padding: 0;
    margin: 0
}

.progress-line .progressbar-text .value {
    right: 0;
    position: absolute
}

.progress-circle-dashboard {
    height: 150px;
    width: 150px
}

.progress-circle-dashboard .progressbar-text {
    color: #9da9bb!important
}

.progress-circle-dashboard .progressbar-text .value {
    font-size: 1.728rem;
    font-weight: 500!important
}

.progress-circle-dashboard .progressbar-text .value b {
    font-size: 1.728rem
}

.plyr__control--overlaid,.plyr__control--overlaid:focus,.plyr__control--overlaid:hover {
    background-color: #e63757!important
}

.plyr--video .plyr__controls .plyr__control.plyr__tab-focus,.plyr--video .plyr__controls .plyr__control:hover,.plyr--video .plyr__controls .plyr__control[aria-expanded=true],.plyr__menu__container label.plyr__control input[type=radio]:checked+span {
    background-color: #e63757!important
}

.plyr--full-ui input[type=range] {
    color: #e63757!important
}

.rdtPicker {
    background-color: #fff;
    border-color: #d8e2ef
}

.rdtPicker th {
    border-color: #d8e2ef
}

.rdtPicker thead tr:first-child th:hover {
    background-color: #f9fafd
}

.rdtPicker .rdtCounter .rdtBtn:hover,.rdtPicker .rdtTimeToggle:hover,.rdtPicker td.rdtDay:hover,.rdtPicker td.rdtHour:hover,.rdtPicker td.rdtMinute:hover,.rdtPicker td.rdtMonth:hover,.rdtPicker td.rdtSecond:hover,.rdtPicker td.rdtYear:hover {
    background-color: #f9fafd
}

.leaflet-bar {
    box-shadow: 0 7px 14px 0 rgba(59,65,94,.1),0 3px 6px 0 rgba(0,0,0,.07)!important
}

.marker-cluster-small {
    background-color: rgba(44,123,229,.2)!important
}

.marker-cluster-small div {
    margin: 5px;
    background-color: #2c7be5!important;
    color: #fff
}

.marker-cluster-medium {
    background-color: rgba(245,128,62,.2)!important
}

.marker-cluster-medium div {
    margin: 5px;
    background-color: #f5803e!important;
    color: #fff
}

.marker-cluster-large {
    background-color: rgba(0,210,122,.2)!important
}

.marker-cluster-large div {
    margin: 5px;
    background-color: #00d27a!important;
    color: #fff
}

.leaflet-popup-content-wrapper {
    box-shadow: 0 1rem 4rem rgba(0,0,0,.175)!important;
    border-radius: .25rem!important
}

.leaflet-popup-close-button {
    right: 3px!important;
    top: 3px!important
}

.leaflet-marker-icon {
    height: auto!important;
    width: auto!important
}

.emoji-mart .section,.emoji-mart section {
    padding: 0!important
}

.icon-group {
    display: -webkit-box;
    display: flex
}

.icon-group .icon-item:not(:last-child) {
    margin-right: .5rem
}

.icon-item {
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #5e6e82;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    height: 2.5rem;
    width: 2.5rem;
    border: 0;
    font-size: .875rem;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)
}

.icon-item:focus,.icon-item:hover {
    background-color: #f9fafd
}

.icon-item.icon-item-lg {
    height: 2.75rem;
    width: 2.75rem
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-3.125rem);
        transform: translateX(-3.125rem)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-3.125rem);
        transform: translateX(-3.125rem)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes fadeOutRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(3.125rem);
        transform: translateX(3.125rem)
    }
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(3.125rem);
        transform: translateX(3.125rem)
    }
}

.fancy-tab {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column
}

.fancy-tab .nav-bar {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    position: relative;
    border-bottom: 2px solid #f9fafd
}

.fancy-tab .nav-bar .tab-indicator {
    content: '';
    position: absolute;
    display: block;
    bottom: -.125rem;
    height: .125rem;
    background-color: #2c7be5;
    -webkit-transition: left .4s ease-in-out,right .2s ease-in-out;
    transition: left .4s ease-in-out,right .2s ease-in-out
}

.fancy-tab .nav-bar .tab-indicator.transition-reverse {
    -webkit-transition: left .2s ease-in-out,right .4s ease-in-out;
    transition: left .2s ease-in-out,right .4s ease-in-out
}

.fancy-tab .nav-bar.nav-bar-right {
    -webkit-box-pack: end;
    justify-content: flex-end
}

.fancy-tab .nav-bar.nav-bar-center {
    -webkit-box-pack: center;
    justify-content: center
}

.fancy-tab .nav-bar .nav-bar-item {
    padding: .5rem 1.8rem;
    font-size: .875rem;
    line-height: 1rem;
    font-family: Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
    display: inline-block;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent
}

.fancy-tab .nav-bar .nav-bar-item.active {
    color: #2c7be5
}

.fancy-tab .tab-contents {
    margin-top: 1.875rem;
    position: relative
}

.fancy-tab .tab-contents .tab-content {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.fancy-tab .tab-contents .tab-content:not(.active) {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 1;
    -webkit-animation: fadeOutRight .25s ease forwards;
    animation: fadeOutRight .25s ease forwards
}

.fancy-tab .tab-contents .tab-content.active {
    -webkit-animation: fadeInLeft .5s ease forwards;
    animation: fadeInLeft .5s ease forwards
}

.table tfoot>tr>th:first-child,.table thead>tr>th:first-child,.table tr td:first-child,.table tr th:first-child {
    padding-left: 1.25rem
}

.white-space-nowrap {
    width: 1px;
    white-space: nowrap
}

th {
    font-weight: 600
}

td .dropdown-toggle:after {
    display: none
}

.table-dashboard {
    overflow: hidden
}

.table-dashboard th {
    border-bottom-width: 1px!important
}

.h5,h5 {
    margin-bottom: .25rem
}

.dropcap::first-letter {
    font-size: 3em;
    float: left;
    line-height: .92;
    margin-right: .375rem
}

.ls {
    letter-spacing: .04em
}

.ls-2 {
    letter-spacing: .25em
}

.bullet-inside {
    list-style-position: inside
}

.style-check li {
    position: relative;
    list-style-type: none;
    padding-left: .25rem
}

.style-check li:before {
    content: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZGF0YS1mYS1wcm9jZXNzZWQ9IiIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJjaGVjayIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hlY2sgZmEtdy0xNCIgc3R5bGU9ImZvbnQtc2l6ZTogNDhweDsiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQxMy41MDUgOTEuOTUxTDEzMy40OSAzNzEuOTY2bC05OC45OTUtOTguOTk1Yy00LjY4Ni00LjY4Ni0xMi4yODQtNC42ODYtMTYuOTcxIDBMNi4yMTEgMjg0LjI4NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDExOC43OTQgMTE4Ljc5NGM0LjY4NiA0LjY4NiAxMi4yODQgNC42ODYgMTYuOTcxIDBsMjk5LjgxMy0yOTkuODEzYzQuNjg2LTQuNjg2IDQuNjg2LTEyLjI4NCAwLTE2Ljk3MWwtMTEuMzE0LTExLjMxNGMtNC42ODYtNC42ODYtMTIuMjg0LTQuNjg2LTE2Ljk3IDB6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+);
    padding-right: .1875rem;
    position: absolute;
    top: .0625rem;
    left: -1rem;
    background-repeat: no-repeat;
    width: 1rem
}

.hr-short {
    width: 4.2rem
}

.blockquote-content {
    font-style: italic;
    position: relative
}

.blockquote-content:before {
    position: absolute;
    left: -3rem;
    top: -.5rem;
    line-height: 1;
    content: "“";
    display: inline-block;
    color: #d8e2ef;
    font-size: 2.98598rem
}

.blockquote-footer::before {
    -webkit-transform: translateY(.125rem);
    transform: translateY(.125rem);
    font-weight: 400;
    display: inline-block
}

.cursor-pointer {
    cursor: pointer
}

.pointer-event-none {
    pointer-events: none
}

.bg-holder {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    will-change: transform,opacity,filter;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    z-index: 0
}

.bg-holder.bg-right {
    left: auto;
    right: 0
}

.bg-holder.overlay:before {
    position: absolute;
    content: '';
    background: rgba(0,0,0,.25);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.bg-holder.overlay-primary:before {
    background: rgba(44,123,229,.4)
}

.bg-holder.overlay-info:before {
    background: rgba(39,188,253,.4)
}

.bg-holder.overlay-success:before {
    background: rgba(0,210,122,.4)
}

.bg-holder.overlay-warning:before {
    background: rgba(245,128,62,.4)
}

.bg-holder.overlay-danger:before {
    background: rgba(230,55,87,.4)
}

.bg-holder.overlay-0:before {
    background: rgba(0,0,0,.7)
}

.bg-holder.overlay-1:before {
    background: rgba(0,0,0,.55)
}

.bg-holder.overlay-2:before {
    background: rgba(0,0,0,.4)
}

.bg-holder .bg-video {
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    min-width: 100%
}

.bg-holder .bg-youtube {
    position: absolute!important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.bg-fixed {
    background-attachment: fixed
}

.has-parallax {
    position: relative;
    overflow: hidden
}

.bg-glass {
    background-color: rgba(255,255,255,.9)
}

.bg-100 {
    background-color: #f9fafd!important
}

a.bg-100:focus,a.bg-100:hover,button.bg-100:focus,button.bg-100:hover {
    background-color: #d3daf0!important
}

.bg-200 {
    background-color: #edf2f9!important
}

a.bg-200:focus,a.bg-200:hover,button.bg-200:focus,button.bg-200:hover {
    background-color: #c7d6ec!important
}

.bg-300 {
    background-color: #d8e2ef!important
}

a.bg-300:focus,a.bg-300:hover,button.bg-300:focus,button.bg-300:hover {
    background-color: #b4c7e0!important
}

.bg-400 {
    background-color: #b6c1d2!important
}

a.bg-400:focus,a.bg-400:hover,button.bg-400:focus,button.bg-400:hover {
    background-color: #96a6bf!important
}

.bg-500 {
    background-color: #9da9bb!important
}

a.bg-500:focus,a.bg-500:hover,button.bg-500:focus,button.bg-500:hover {
    background-color: #7f8fa6!important
}

.bg-600 {
    background-color: #748194!important
}

a.bg-600:focus,a.bg-600:hover,button.bg-600:focus,button.bg-600:hover {
    background-color: #5d6878!important
}

.bg-700 {
    background-color: #5e6e82!important
}

a.bg-700:focus,a.bg-700:hover,button.bg-700:focus,button.bg-700:hover {
    background-color: #495564!important
}

.bg-800 {
    background-color: #4d5969!important
}

a.bg-800:focus,a.bg-800:hover,button.bg-800:focus,button.bg-800:hover {
    background-color: #37404c!important
}

.bg-900 {
    background-color: #344050!important
}

a.bg-900:focus,a.bg-900:hover,button.bg-900:focus,button.bg-900:hover {
    background-color: #202731!important
}

.bg-black {
    background-color: #000!important
}

a.bg-black:focus,a.bg-black:hover,button.bg-black:focus,button.bg-black:hover {
    background-color: #000!important
}

.bg-1000 {
    background-color: #232e3c!important
}

a.bg-1000:focus,a.bg-1000:hover,button.bg-1000:focus,button.bg-1000:hover {
    background-color: #10151c!important
}

.bg-1100 {
    background-color: #0b1727!important
}

a.bg-1100:focus,a.bg-1100:hover,button.bg-1100:focus,button.bg-1100:hover {
    background-color: #000!important
}

.bg-white {
    background-color: #fff!important
}

a.bg-white:focus,a.bg-white:hover,button.bg-white:focus,button.bg-white:hover {
    background-color: #e6e6e6!important
}

.bg-facebook {
    background-color: #3c5a99!important
}

a.bg-facebook:focus,a.bg-facebook:hover,button.bg-facebook:focus,button.bg-facebook:hover {
    background-color: #2e4474!important
}

.bg-twitter {
    background-color: #1da1f2!important
}

a.bg-twitter:focus,a.bg-twitter:hover,button.bg-twitter:focus,button.bg-twitter:hover {
    background-color: #0c85d0!important
}

.bg-google-plus {
    background-color: #dd4b39!important
}

a.bg-google-plus:focus,a.bg-google-plus:hover,button.bg-google-plus:focus,button.bg-google-plus:hover {
    background-color: #c23321!important
}

.bg-linkedin {
    background-color: #0077b5!important
}

a.bg-linkedin:focus,a.bg-linkedin:hover,button.bg-linkedin:focus,button.bg-linkedin:hover {
    background-color: #005582!important
}

.bg-card {
    background-size: contain;
    background-position: right;
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
}

[dir=rtl] .bg-card {
    background-position: left;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.bg-gradient {
    background-image: -webkit-linear-gradient(135deg,#014ba7,#0183d0);
    background-image: linear-gradient(-45deg,#014ba7,#0183d0);
    background-position: center
}

.bg-card-gradient {
    background-image: -webkit-linear-gradient(135deg,#1970e2,#4695ff);
    background-image: linear-gradient(-45deg,#1970e2,#4695ff);
    background-position: center
}

.bg-auth-circle-shape,.bg-auth-circle-shape-2 {
    display: none
}

@media(min-width: 992px) {
    .bg-auth-circle-shape,.bg-auth-circle-shape-2 {
        display:block
    }
}

.bg-auth-circle-shape {
    position: absolute;
    right: -140px;
    top: -82px
}

.bg-auth-circle-shape-2 {
    position: absolute;
    left: -100px;
    bottom: -39px
}

.bg-auth-card-shape {
    background-position: 0 100%
}

@media(min-width: 768px) {
    .bg-auth-card-shape {
        background-position:0 133%
    }
}

.bg-circle-shape {
    position: relative;
    overflow: hidden;
    background-color: #4695ff
}

.bg-circle-shape:after,.bg-circle-shape:before {
    position: absolute;
    content: '';
    border-radius: 50%
}

.bg-circle-shape:after {
    height: 250px;
    width: 115%;
    background-image: -webkit-linear-gradient(135deg,#1970e2,#4695ff);
    background-image: linear-gradient(-45deg,#1970e2,#4695ff);
    left: 32%;
    top: -188%
}

.bg-circle-shape:before {
    height: 332%;
    width: 45%;
    background-image: -webkit-linear-gradient(135deg,#1970e2,#4695ff);
    background-image: linear-gradient(-45deg,#1970e2,#4695ff);
    left: -9%;
    top: 9px
}

.modal-shape-header {
    position: relative;
    overflow: hidden;
    background-color: #4494ff
}

.modal-shape-header:after,.modal-shape-header:before {
    position: absolute;
    content: '';
    border-radius: 50%
}

.modal-shape-header:after {
    height: 463px;
    width: 155%;
    background-image: -webkit-linear-gradient(135deg,#4c98ff,#0051bb);
    background-image: linear-gradient(-45deg,#4c98ff,#0051bb);
    right: 23%;
    top: -357%
}

.modal-shape-header:before {
    height: 289%;
    width: 45%;
    background-image: -webkit-linear-gradient(135deg,#1970e2,#4695ff);
    background-image: linear-gradient(-45deg,#1970e2,#4695ff);
    right: -10%;
    top: 40px
}

.bg-soft-primary {
    background-color: #e6effc!important
}

a.bg-soft-primary:focus,a.bg-soft-primary:hover,button.bg-soft-primary:focus,button.bg-soft-primary:hover {
    background-color: #b8d2f7!important
}

.bg-soft-secondary {
    background-color: #eef0f2!important
}

a.bg-soft-secondary:focus,a.bg-soft-secondary:hover,button.bg-soft-secondary:focus,button.bg-soft-secondary:hover {
    background-color: #d1d7dc!important
}

.bg-soft-success {
    background-color: #e0faef!important
}

a.bg-soft-success:focus,a.bg-soft-success:hover,button.bg-soft-success:focus,button.bg-soft-success:hover {
    background-color: #b4f3d8!important
}

.bg-soft-info {
    background-color: #e5f7ff!important
}

a.bg-soft-info:focus,a.bg-soft-info:hover,button.bg-soft-info:focus,button.bg-soft-info:hover {
    background-color: #b2e7ff!important
}

.bg-soft-warning {
    background-color: #fef0e8!important
}

a.bg-soft-warning:focus,a.bg-soft-warning:hover,button.bg-soft-warning:focus,button.bg-soft-warning:hover {
    background-color: #fcd0b7!important
}

.bg-soft-danger {
    background-color: #fce7eb!important
}

a.bg-soft-danger:focus,a.bg-soft-danger:hover,button.bg-soft-danger:focus,button.bg-soft-danger:hover {
    background-color: #f6bac5!important
}

.bg-soft-light {
    background-color: #fefeff!important
}

a.bg-soft-light:focus,a.bg-soft-light:hover,button.bg-soft-light:focus,button.bg-soft-light:hover {
    background-color: #cbcbff!important
}

.bg-soft-dark {
    background-color: #e2e3e5!important
}

a.bg-soft-dark:focus,a.bg-soft-dark:hover,button.bg-soft-dark:focus,button.bg-soft-dark:hover {
    background-color: #c7c9cd!important
}

.border {
    border: 1px solid #d8e2ef!important
}

.border-top {
    border-top: 1px solid #d8e2ef!important
}

.border-right {
    border-right: 1px solid #d8e2ef!important
}

.border-bottom {
    border-bottom: 1px solid #d8e2ef!important
}

.border-left {
    border-left: 1px solid #d8e2ef!important
}

.border-x {
    border-left: 1px solid #d8e2ef!important;
    border-right: 1px solid #d8e2ef!important
}

.border-y {
    border-top: 1px solid #d8e2ef!important;
    border-bottom: 1px solid #d8e2ef!important
}

.border-dashed {
    border: 1px dashed #d8e2ef!important
}

.border-dashed-top {
    border-top: 1px dashed #d8e2ef!important
}

.border-dashed-right {
    border-right: 1px dashed #d8e2ef!important
}

.border-dashed-bottom {
    border-bottom: 1px dashed #d8e2ef!important
}

.border-dashed-left {
    border-left: 1px dashed #d8e2ef!important
}

.border-dashed-x {
    border-left: 1px dashed #d8e2ef!important;
    border-right: 1px dashed #d8e2ef!important
}

.border-dashed-y {
    border-top: 1px dashed #d8e2ef!important;
    border-bottom: 1px dashed #d8e2ef!important
}

.border-0 {
    border-width: 0!important
}

.border-top-0 {
    border-top-width: 0!important
}

.border-right-0 {
    border-right-width: 0!important
}

.border-bottom-0 {
    border-bottom-width: 0!important
}

.border-left-0 {
    border-left-width: 0!important
}

.border-x-0 {
    border-left-width: 0!important;
    border-right-width: 0!important
}

.border-y-0 {
    border-top-width: 0!important;
    border-bottom-width: 0!important
}

.rounded {
    border-radius: .25rem!important
}

.rounded-top {
    border-top-left-radius: .25rem!important;
    border-top-right-radius: .25rem!important
}

.rounded-right {
    border-top-right-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-left {
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-soft {
    border-radius: .375rem!important
}

.rounded-soft-top {
    border-top-left-radius: .375rem!important;
    border-top-right-radius: .375rem!important
}

.rounded-soft-right {
    border-top-right-radius: .375rem!important;
    border-bottom-right-radius: .375rem!important
}

.rounded-soft-bottom {
    border-bottom-right-radius: .375rem!important;
    border-bottom-left-radius: .375rem!important
}

.rounded-soft-left {
    border-top-left-radius: .375rem!important;
    border-bottom-left-radius: .375rem!important
}

.rounded-capsule {
    border-radius: 3.125rem!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-top-0 {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important
}

.rounded-right-0 {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important
}

.rounded-bottom-0 {
    border-bottom-right-radius: 0!important;
    border-bottom-left-radius: 0!important
}

.rounded-left-0 {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important
}

@media(min-width: 576px) {
    .border-sm {
        border:1px solid #d8e2ef!important
    }

    .border-sm-top {
        border-top: 1px solid #d8e2ef!important
    }

    .border-sm-right {
        border-right: 1px solid #d8e2ef!important
    }

    .border-sm-bottom {
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-sm-left {
        border-left: 1px solid #d8e2ef!important
    }

    .border-sm-x {
        border-left: 1px solid #d8e2ef!important;
        border-right: 1px solid #d8e2ef!important
    }

    .border-sm-y {
        border-top: 1px solid #d8e2ef!important;
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-sm-dashed {
        border: 1px dashed #d8e2ef!important
    }

    .border-sm-dashed-top {
        border-top: 1px dashed #d8e2ef!important
    }

    .border-sm-dashed-right {
        border-right: 1px dashed #d8e2ef!important
    }

    .border-sm-dashed-bottom {
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-sm-dashed-left {
        border-left: 1px dashed #d8e2ef!important
    }

    .border-sm-dashed-x {
        border-left: 1px dashed #d8e2ef!important;
        border-right: 1px dashed #d8e2ef!important
    }

    .border-sm-dashed-y {
        border-top: 1px dashed #d8e2ef!important;
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-sm-0 {
        border-width: 0!important
    }

    .border-sm-top-0 {
        border-top-width: 0!important
    }

    .border-sm-right-0 {
        border-right-width: 0!important
    }

    .border-sm-bottom-0 {
        border-bottom-width: 0!important
    }

    .border-sm-left-0 {
        border-left-width: 0!important
    }

    .border-sm-x-0 {
        border-left-width: 0!important;
        border-right-width: 0!important
    }

    .border-sm-y-0 {
        border-top-width: 0!important;
        border-bottom-width: 0!important
    }

    .rounded-sm {
        border-radius: .25rem!important
    }

    .rounded-sm-top {
        border-top-left-radius: .25rem!important;
        border-top-right-radius: .25rem!important
    }

    .rounded-sm-right {
        border-top-right-radius: .25rem!important;
        border-bottom-right-radius: .25rem!important
    }

    .rounded-sm-bottom {
        border-bottom-right-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-sm-left {
        border-top-left-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-sm-soft {
        border-radius: .375rem!important
    }

    .rounded-soft-sm-top {
        border-top-left-radius: .375rem!important;
        border-top-right-radius: .375rem!important
    }

    .rounded-soft-sm-right {
        border-top-right-radius: .375rem!important;
        border-bottom-right-radius: .375rem!important
    }

    .rounded-soft-sm-bottom {
        border-bottom-right-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-soft-sm-left {
        border-top-left-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-sm-capsule {
        border-radius: 3.125rem!important
    }

    .rounded-sm-circle {
        border-radius: 50%!important
    }

    .rounded-sm-top-0 {
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important
    }

    .rounded-sm-right-0 {
        border-top-right-radius: 0!important;
        border-bottom-right-radius: 0!important
    }

    .rounded-sm-bottom-0 {
        border-bottom-right-radius: 0!important;
        border-bottom-left-radius: 0!important
    }

    .rounded-sm-left-0 {
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important
    }
}

@media(min-width: 768px) {
    .border-md {
        border:1px solid #d8e2ef!important
    }

    .border-md-top {
        border-top: 1px solid #d8e2ef!important
    }

    .border-md-right {
        border-right: 1px solid #d8e2ef!important
    }

    .border-md-bottom {
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-md-left {
        border-left: 1px solid #d8e2ef!important
    }

    .border-md-x {
        border-left: 1px solid #d8e2ef!important;
        border-right: 1px solid #d8e2ef!important
    }

    .border-md-y {
        border-top: 1px solid #d8e2ef!important;
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-md-dashed {
        border: 1px dashed #d8e2ef!important
    }

    .border-md-dashed-top {
        border-top: 1px dashed #d8e2ef!important
    }

    .border-md-dashed-right {
        border-right: 1px dashed #d8e2ef!important
    }

    .border-md-dashed-bottom {
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-md-dashed-left {
        border-left: 1px dashed #d8e2ef!important
    }

    .border-md-dashed-x {
        border-left: 1px dashed #d8e2ef!important;
        border-right: 1px dashed #d8e2ef!important
    }

    .border-md-dashed-y {
        border-top: 1px dashed #d8e2ef!important;
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-md-0 {
        border-width: 0!important
    }

    .border-md-top-0 {
        border-top-width: 0!important
    }

    .border-md-right-0 {
        border-right-width: 0!important
    }

    .border-md-bottom-0 {
        border-bottom-width: 0!important
    }

    .border-md-left-0 {
        border-left-width: 0!important
    }

    .border-md-x-0 {
        border-left-width: 0!important;
        border-right-width: 0!important
    }

    .border-md-y-0 {
        border-top-width: 0!important;
        border-bottom-width: 0!important
    }

    .rounded-md {
        border-radius: .25rem!important
    }

    .rounded-md-top {
        border-top-left-radius: .25rem!important;
        border-top-right-radius: .25rem!important
    }

    .rounded-md-right {
        border-top-right-radius: .25rem!important;
        border-bottom-right-radius: .25rem!important
    }

    .rounded-md-bottom {
        border-bottom-right-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-md-left {
        border-top-left-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-md-soft {
        border-radius: .375rem!important
    }

    .rounded-soft-md-top {
        border-top-left-radius: .375rem!important;
        border-top-right-radius: .375rem!important
    }

    .rounded-soft-md-right {
        border-top-right-radius: .375rem!important;
        border-bottom-right-radius: .375rem!important
    }

    .rounded-soft-md-bottom {
        border-bottom-right-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-soft-md-left {
        border-top-left-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-md-capsule {
        border-radius: 3.125rem!important
    }

    .rounded-md-circle {
        border-radius: 50%!important
    }

    .rounded-md-top-0 {
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important
    }

    .rounded-md-right-0 {
        border-top-right-radius: 0!important;
        border-bottom-right-radius: 0!important
    }

    .rounded-md-bottom-0 {
        border-bottom-right-radius: 0!important;
        border-bottom-left-radius: 0!important
    }

    .rounded-md-left-0 {
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important
    }
}

@media(min-width: 992px) {
    .border-lg {
        border:1px solid #d8e2ef!important
    }

    .border-lg-top {
        border-top: 1px solid #d8e2ef!important
    }

    .border-lg-right {
        border-right: 1px solid #d8e2ef!important
    }

    .border-lg-bottom {
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-lg-left {
        border-left: 1px solid #d8e2ef!important
    }

    .border-lg-x {
        border-left: 1px solid #d8e2ef!important;
        border-right: 1px solid #d8e2ef!important
    }

    .border-lg-y {
        border-top: 1px solid #d8e2ef!important;
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-lg-dashed {
        border: 1px dashed #d8e2ef!important
    }

    .border-lg-dashed-top {
        border-top: 1px dashed #d8e2ef!important
    }

    .border-lg-dashed-right {
        border-right: 1px dashed #d8e2ef!important
    }

    .border-lg-dashed-bottom {
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-lg-dashed-left {
        border-left: 1px dashed #d8e2ef!important
    }

    .border-lg-dashed-x {
        border-left: 1px dashed #d8e2ef!important;
        border-right: 1px dashed #d8e2ef!important
    }

    .border-lg-dashed-y {
        border-top: 1px dashed #d8e2ef!important;
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-lg-0 {
        border-width: 0!important
    }

    .border-lg-top-0 {
        border-top-width: 0!important
    }

    .border-lg-right-0 {
        border-right-width: 0!important
    }

    .border-lg-bottom-0 {
        border-bottom-width: 0!important
    }

    .border-lg-left-0 {
        border-left-width: 0!important
    }

    .border-lg-x-0 {
        border-left-width: 0!important;
        border-right-width: 0!important
    }

    .border-lg-y-0 {
        border-top-width: 0!important;
        border-bottom-width: 0!important
    }

    .rounded-lg {
        border-radius: .25rem!important
    }

    .rounded-lg-top {
        border-top-left-radius: .25rem!important;
        border-top-right-radius: .25rem!important
    }

    .rounded-lg-right {
        border-top-right-radius: .25rem!important;
        border-bottom-right-radius: .25rem!important
    }

    .rounded-lg-bottom {
        border-bottom-right-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-lg-left {
        border-top-left-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-lg-soft {
        border-radius: .375rem!important
    }

    .rounded-soft-lg-top {
        border-top-left-radius: .375rem!important;
        border-top-right-radius: .375rem!important
    }

    .rounded-soft-lg-right {
        border-top-right-radius: .375rem!important;
        border-bottom-right-radius: .375rem!important
    }

    .rounded-soft-lg-bottom {
        border-bottom-right-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-soft-lg-left {
        border-top-left-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-lg-capsule {
        border-radius: 3.125rem!important
    }

    .rounded-lg-circle {
        border-radius: 50%!important
    }

    .rounded-lg-top-0 {
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important
    }

    .rounded-lg-right-0 {
        border-top-right-radius: 0!important;
        border-bottom-right-radius: 0!important
    }

    .rounded-lg-bottom-0 {
        border-bottom-right-radius: 0!important;
        border-bottom-left-radius: 0!important
    }

    .rounded-lg-left-0 {
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important
    }
}

@media(min-width: 1200px) {
    .border-xl {
        border:1px solid #d8e2ef!important
    }

    .border-xl-top {
        border-top: 1px solid #d8e2ef!important
    }

    .border-xl-right {
        border-right: 1px solid #d8e2ef!important
    }

    .border-xl-bottom {
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-xl-left {
        border-left: 1px solid #d8e2ef!important
    }

    .border-xl-x {
        border-left: 1px solid #d8e2ef!important;
        border-right: 1px solid #d8e2ef!important
    }

    .border-xl-y {
        border-top: 1px solid #d8e2ef!important;
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-xl-dashed {
        border: 1px dashed #d8e2ef!important
    }

    .border-xl-dashed-top {
        border-top: 1px dashed #d8e2ef!important
    }

    .border-xl-dashed-right {
        border-right: 1px dashed #d8e2ef!important
    }

    .border-xl-dashed-bottom {
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-xl-dashed-left {
        border-left: 1px dashed #d8e2ef!important
    }

    .border-xl-dashed-x {
        border-left: 1px dashed #d8e2ef!important;
        border-right: 1px dashed #d8e2ef!important
    }

    .border-xl-dashed-y {
        border-top: 1px dashed #d8e2ef!important;
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-xl-0 {
        border-width: 0!important
    }

    .border-xl-top-0 {
        border-top-width: 0!important
    }

    .border-xl-right-0 {
        border-right-width: 0!important
    }

    .border-xl-bottom-0 {
        border-bottom-width: 0!important
    }

    .border-xl-left-0 {
        border-left-width: 0!important
    }

    .border-xl-x-0 {
        border-left-width: 0!important;
        border-right-width: 0!important
    }

    .border-xl-y-0 {
        border-top-width: 0!important;
        border-bottom-width: 0!important
    }

    .rounded-xl {
        border-radius: .25rem!important
    }

    .rounded-xl-top {
        border-top-left-radius: .25rem!important;
        border-top-right-radius: .25rem!important
    }

    .rounded-xl-right {
        border-top-right-radius: .25rem!important;
        border-bottom-right-radius: .25rem!important
    }

    .rounded-xl-bottom {
        border-bottom-right-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-xl-left {
        border-top-left-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-xl-soft {
        border-radius: .375rem!important
    }

    .rounded-soft-xl-top {
        border-top-left-radius: .375rem!important;
        border-top-right-radius: .375rem!important
    }

    .rounded-soft-xl-right {
        border-top-right-radius: .375rem!important;
        border-bottom-right-radius: .375rem!important
    }

    .rounded-soft-xl-bottom {
        border-bottom-right-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-soft-xl-left {
        border-top-left-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-xl-capsule {
        border-radius: 3.125rem!important
    }

    .rounded-xl-circle {
        border-radius: 50%!important
    }

    .rounded-xl-top-0 {
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important
    }

    .rounded-xl-right-0 {
        border-top-right-radius: 0!important;
        border-bottom-right-radius: 0!important
    }

    .rounded-xl-bottom-0 {
        border-bottom-right-radius: 0!important;
        border-bottom-left-radius: 0!important
    }

    .rounded-xl-left-0 {
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important
    }
}

@media(min-width: 1540px) {
    .border-xxl {
        border:1px solid #d8e2ef!important
    }

    .border-xxl-top {
        border-top: 1px solid #d8e2ef!important
    }

    .border-xxl-right {
        border-right: 1px solid #d8e2ef!important
    }

    .border-xxl-bottom {
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-xxl-left {
        border-left: 1px solid #d8e2ef!important
    }

    .border-xxl-x {
        border-left: 1px solid #d8e2ef!important;
        border-right: 1px solid #d8e2ef!important
    }

    .border-xxl-y {
        border-top: 1px solid #d8e2ef!important;
        border-bottom: 1px solid #d8e2ef!important
    }

    .border-xxl-dashed {
        border: 1px dashed #d8e2ef!important
    }

    .border-xxl-dashed-top {
        border-top: 1px dashed #d8e2ef!important
    }

    .border-xxl-dashed-right {
        border-right: 1px dashed #d8e2ef!important
    }

    .border-xxl-dashed-bottom {
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-xxl-dashed-left {
        border-left: 1px dashed #d8e2ef!important
    }

    .border-xxl-dashed-x {
        border-left: 1px dashed #d8e2ef!important;
        border-right: 1px dashed #d8e2ef!important
    }

    .border-xxl-dashed-y {
        border-top: 1px dashed #d8e2ef!important;
        border-bottom: 1px dashed #d8e2ef!important
    }

    .border-xxl-0 {
        border-width: 0!important
    }

    .border-xxl-top-0 {
        border-top-width: 0!important
    }

    .border-xxl-right-0 {
        border-right-width: 0!important
    }

    .border-xxl-bottom-0 {
        border-bottom-width: 0!important
    }

    .border-xxl-left-0 {
        border-left-width: 0!important
    }

    .border-xxl-x-0 {
        border-left-width: 0!important;
        border-right-width: 0!important
    }

    .border-xxl-y-0 {
        border-top-width: 0!important;
        border-bottom-width: 0!important
    }

    .rounded-xxl {
        border-radius: .25rem!important
    }

    .rounded-xxl-top {
        border-top-left-radius: .25rem!important;
        border-top-right-radius: .25rem!important
    }

    .rounded-xxl-right {
        border-top-right-radius: .25rem!important;
        border-bottom-right-radius: .25rem!important
    }

    .rounded-xxl-bottom {
        border-bottom-right-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-xxl-left {
        border-top-left-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }

    .rounded-xxl-soft {
        border-radius: .375rem!important
    }

    .rounded-soft-xxl-top {
        border-top-left-radius: .375rem!important;
        border-top-right-radius: .375rem!important
    }

    .rounded-soft-xxl-right {
        border-top-right-radius: .375rem!important;
        border-bottom-right-radius: .375rem!important
    }

    .rounded-soft-xxl-bottom {
        border-bottom-right-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-soft-xxl-left {
        border-top-left-radius: .375rem!important;
        border-bottom-left-radius: .375rem!important
    }

    .rounded-xxl-capsule {
        border-radius: 3.125rem!important
    }

    .rounded-xxl-circle {
        border-radius: 50%!important
    }

    .rounded-xxl-top-0 {
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important
    }

    .rounded-xxl-right-0 {
        border-top-right-radius: 0!important;
        border-bottom-right-radius: 0!important
    }

    .rounded-xxl-bottom-0 {
        border-bottom-right-radius: 0!important;
        border-bottom-left-radius: 0!important
    }

    .rounded-xxl-left-0 {
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important
    }
}

.overflow-hidden[class*=rounded] {
    -webkit-mask-image: -webkit-radial-gradient(#fff,#000);
    mask-image: radial-gradient(#fff,#000)
}

.border-primary {
    border-color: #2c7be5!important
}

.border-secondary {
    border-color: #748194!important
}

.border-success {
    border-color: #00d27a!important
}

.border-info {
    border-color: #27bcfd!important
}

.border-warning {
    border-color: #f5803e!important
}

.border-danger {
    border-color: #e63757!important
}

.border-light {
    border-color: #f9fafd!important
}

.border-dark {
    border-color: #0b1727!important
}

.border-100 {
    border-color: #f9fafd!important
}

.border-200 {
    border-color: #edf2f9!important
}

.border-300 {
    border-color: #d8e2ef!important
}

.border-400 {
    border-color: #b6c1d2!important
}

.border-500 {
    border-color: #9da9bb!important
}

.border-600 {
    border-color: #748194!important
}

.border-700 {
    border-color: #5e6e82!important
}

.border-800 {
    border-color: #4d5969!important
}

.border-900 {
    border-color: #344050!important
}

.border-black {
    border-color: #000!important
}

.border-1000 {
    border-color: #232e3c!important
}

.border-1100 {
    border-color: #0b1727!important
}

.border-white {
    border-color: #fff!important
}

.border-2x {
    border-width: 2px!important
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.w-auto {
    width: auto!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.h-auto {
    height: auto!important
}

.min-vh-100 {
    min-height: 100vh
}

.min-vh-75 {
    min-height: 75vh
}

.min-vh-50 {
    min-height: 50vh
}

.min-vh-25 {
    min-height: 25vh
}

.max-vh-100 {
    max-height: 100vh
}

.max-vh-75 {
    max-height: 75vh
}

.max-vh-50 {
    max-height: 50vh
}

.max-vh-25 {
    max-height: 25vh
}

.max-vh-100 {
    max-height: 100vh
}

.max-w-75 {
    max-width: 75%!important
}

.max-w-50 {
    max-width: 50%!important
}

.max-w-25 {
    max-width: 25%!important
}

.vh-100 {
    height: 100vh
}

.vh-75 {
    height: 75vh
}

.vh-50 {
    height: 50vh
}

.vh-25 {
    height: 25vh
}

@media(min-width: 576px) {
    .w-sm-25 {
        width:25%!important
    }

    .w-sm-50 {
        width: 50%!important
    }

    .w-sm-75 {
        width: 75%!important
    }

    .w-sm-100 {
        width: 100%!important
    }

    .w-sm-auto {
        width: auto!important
    }

    .h-sm-25 {
        height: 25%!important
    }

    .h-sm-50 {
        height: 50%!important
    }

    .h-sm-75 {
        height: 75%!important
    }

    .h-sm-100 {
        height: 100%!important
    }

    .h-sm-auto {
        height: auto!important
    }

    .min-vh-sm-100 {
        min-height: 100vh
    }

    .min-vh-sm-75 {
        min-height: 75vh
    }

    .min-vh-sm-50 {
        min-height: 50vh
    }

    .min-vh-sm-25 {
        min-height: 25vh
    }

    .max-vh-sm-100 {
        max-height: 100vh
    }

    .max-vh-sm-75 {
        max-height: 75vh
    }

    .max-vh-sm-50 {
        max-height: 50vh
    }

    .max-vh-sm-25 {
        max-height: 25vh
    }

    .max-vh-sm-100 {
        max-height: 100vh
    }

    .max-w-sm-75 {
        max-width: 75%!important
    }

    .max-w-sm-50 {
        max-width: 50%!important
    }

    .max-w-sm-25 {
        max-width: 25%!important
    }

    .vh-sm-100 {
        height: 100vh
    }

    .vh-sm-75 {
        height: 75vh
    }

    .vh-sm-50 {
        height: 50vh
    }

    .vh-sm-25 {
        height: 25vh
    }
}

@media(min-width: 768px) {
    .w-md-25 {
        width:25%!important
    }

    .w-md-50 {
        width: 50%!important
    }

    .w-md-75 {
        width: 75%!important
    }

    .w-md-100 {
        width: 100%!important
    }

    .w-md-auto {
        width: auto!important
    }

    .h-md-25 {
        height: 25%!important
    }

    .h-md-50 {
        height: 50%!important
    }

    .h-md-75 {
        height: 75%!important
    }

    .h-md-100 {
        height: 100%!important
    }

    .h-md-auto {
        height: auto!important
    }

    .min-vh-md-100 {
        min-height: 100vh
    }

    .min-vh-md-75 {
        min-height: 75vh
    }

    .min-vh-md-50 {
        min-height: 50vh
    }

    .min-vh-md-25 {
        min-height: 25vh
    }

    .max-vh-md-100 {
        max-height: 100vh
    }

    .max-vh-md-75 {
        max-height: 75vh
    }

    .max-vh-md-50 {
        max-height: 50vh
    }

    .max-vh-md-25 {
        max-height: 25vh
    }

    .max-vh-md-100 {
        max-height: 100vh
    }

    .max-w-md-75 {
        max-width: 75%!important
    }

    .max-w-md-50 {
        max-width: 50%!important
    }

    .max-w-md-25 {
        max-width: 25%!important
    }

    .vh-md-100 {
        height: 100vh
    }

    .vh-md-75 {
        height: 75vh
    }

    .vh-md-50 {
        height: 50vh
    }

    .vh-md-25 {
        height: 25vh
    }
}

@media(min-width: 992px) {
    .w-lg-25 {
        width:25%!important
    }

    .w-lg-50 {
        width: 50%!important
    }

    .w-lg-75 {
        width: 75%!important
    }

    .w-lg-100 {
        width: 100%!important
    }

    .w-lg-auto {
        width: auto!important
    }

    .h-lg-25 {
        height: 25%!important
    }

    .h-lg-50 {
        height: 50%!important
    }

    .h-lg-75 {
        height: 75%!important
    }

    .h-lg-100 {
        height: 100%!important
    }

    .h-lg-auto {
        height: auto!important
    }

    .min-vh-lg-100 {
        min-height: 100vh
    }

    .min-vh-lg-75 {
        min-height: 75vh
    }

    .min-vh-lg-50 {
        min-height: 50vh
    }

    .min-vh-lg-25 {
        min-height: 25vh
    }

    .max-vh-lg-100 {
        max-height: 100vh
    }

    .max-vh-lg-75 {
        max-height: 75vh
    }

    .max-vh-lg-50 {
        max-height: 50vh
    }

    .max-vh-lg-25 {
        max-height: 25vh
    }

    .max-vh-lg-100 {
        max-height: 100vh
    }

    .max-w-lg-75 {
        max-width: 75%!important
    }

    .max-w-lg-50 {
        max-width: 50%!important
    }

    .max-w-lg-25 {
        max-width: 25%!important
    }

    .vh-lg-100 {
        height: 100vh
    }

    .vh-lg-75 {
        height: 75vh
    }

    .vh-lg-50 {
        height: 50vh
    }

    .vh-lg-25 {
        height: 25vh
    }
}

@media(min-width: 1200px) {
    .w-xl-25 {
        width:25%!important
    }

    .w-xl-50 {
        width: 50%!important
    }

    .w-xl-75 {
        width: 75%!important
    }

    .w-xl-100 {
        width: 100%!important
    }

    .w-xl-auto {
        width: auto!important
    }

    .h-xl-25 {
        height: 25%!important
    }

    .h-xl-50 {
        height: 50%!important
    }

    .h-xl-75 {
        height: 75%!important
    }

    .h-xl-100 {
        height: 100%!important
    }

    .h-xl-auto {
        height: auto!important
    }

    .min-vh-xl-100 {
        min-height: 100vh
    }

    .min-vh-xl-75 {
        min-height: 75vh
    }

    .min-vh-xl-50 {
        min-height: 50vh
    }

    .min-vh-xl-25 {
        min-height: 25vh
    }

    .max-vh-xl-100 {
        max-height: 100vh
    }

    .max-vh-xl-75 {
        max-height: 75vh
    }

    .max-vh-xl-50 {
        max-height: 50vh
    }

    .max-vh-xl-25 {
        max-height: 25vh
    }

    .max-vh-xl-100 {
        max-height: 100vh
    }

    .max-w-xl-75 {
        max-width: 75%!important
    }

    .max-w-xl-50 {
        max-width: 50%!important
    }

    .max-w-xl-25 {
        max-width: 25%!important
    }

    .vh-xl-100 {
        height: 100vh
    }

    .vh-xl-75 {
        height: 75vh
    }

    .vh-xl-50 {
        height: 50vh
    }

    .vh-xl-25 {
        height: 25vh
    }
}

@media(min-width: 1540px) {
    .w-xxl-25 {
        width:25%!important
    }

    .w-xxl-50 {
        width: 50%!important
    }

    .w-xxl-75 {
        width: 75%!important
    }

    .w-xxl-100 {
        width: 100%!important
    }

    .w-xxl-auto {
        width: auto!important
    }

    .h-xxl-25 {
        height: 25%!important
    }

    .h-xxl-50 {
        height: 50%!important
    }

    .h-xxl-75 {
        height: 75%!important
    }

    .h-xxl-100 {
        height: 100%!important
    }

    .h-xxl-auto {
        height: auto!important
    }

    .min-vh-xxl-100 {
        min-height: 100vh
    }

    .min-vh-xxl-75 {
        min-height: 75vh
    }

    .min-vh-xxl-50 {
        min-height: 50vh
    }

    .min-vh-xxl-25 {
        min-height: 25vh
    }

    .max-vh-xxl-100 {
        max-height: 100vh
    }

    .max-vh-xxl-75 {
        max-height: 75vh
    }

    .max-vh-xxl-50 {
        max-height: 50vh
    }

    .max-vh-xxl-25 {
        max-height: 25vh
    }

    .max-vh-xxl-100 {
        max-height: 100vh
    }

    .max-w-xxl-75 {
        max-width: 75%!important
    }

    .max-w-xxl-50 {
        max-width: 50%!important
    }

    .max-w-xxl-25 {
        max-width: 25%!important
    }

    .vh-xxl-100 {
        height: 100vh
    }

    .vh-xxl-75 {
        height: 75vh
    }

    .vh-xxl-50 {
        height: 50vh
    }

    .vh-xxl-25 {
        height: 25vh
    }
}

.min-w-0 {
    min-width: 0
}

.max-w-100 {
    max-width: 100%
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: -webkit-sticky!important;
    position: sticky!important
}

@media(min-width: 576px) {
    .position-sm-static {
        position:static!important
    }

    .position-sm-relative {
        position: relative!important
    }

    .position-sm-absolute {
        position: absolute!important
    }

    .position-sm-fixed {
        position: fixed!important
    }

    .position-sm-sticky {
        position: -webkit-sticky!important;
        position: sticky!important
    }
}

@media(min-width: 768px) {
    .position-md-static {
        position:static!important
    }

    .position-md-relative {
        position: relative!important
    }

    .position-md-absolute {
        position: absolute!important
    }

    .position-md-fixed {
        position: fixed!important
    }

    .position-md-sticky {
        position: -webkit-sticky!important;
        position: sticky!important
    }
}

@media(min-width: 992px) {
    .position-lg-static {
        position:static!important
    }

    .position-lg-relative {
        position: relative!important
    }

    .position-lg-absolute {
        position: absolute!important
    }

    .position-lg-fixed {
        position: fixed!important
    }

    .position-lg-sticky {
        position: -webkit-sticky!important;
        position: sticky!important
    }
}

@media(min-width: 1200px) {
    .position-xl-static {
        position:static!important
    }

    .position-xl-relative {
        position: relative!important
    }

    .position-xl-absolute {
        position: absolute!important
    }

    .position-xl-fixed {
        position: fixed!important
    }

    .position-xl-sticky {
        position: -webkit-sticky!important;
        position: sticky!important
    }
}

@media(min-width: 1540px) {
    .position-xxl-static {
        position:static!important
    }

    .position-xxl-relative {
        position: relative!important
    }

    .position-xxl-absolute {
        position: absolute!important
    }

    .position-xxl-fixed {
        position: fixed!important
    }

    .position-xxl-sticky {
        position: -webkit-sticky!important;
        position: sticky!important
    }
}

.sticky-top {
    z-index: 1015
}

.sticky-bottom {
    z-index: 1019;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0
}

.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
    position: relative
}

.a-0,.ripple,.t-0 {
    top: 0
}

.a-0,.b-0,.ripple {
    bottom: 0
}

.a-0,.l-0,.ripple {
    left: 0
}

.a-0,.r-0,.ripple {
    right: 0
}

.absolute-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0)
}

.absolute-vertical-center {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

@media(min-width: 576px) {
    .absolute-sm-centered {
        position:absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%,-50%,0);
        transform: translate3d(-50%,-50%,0)
    }
}

.fs--2 {
    font-size: .69444rem
}

.fs--1 {
    font-size: .83333rem
}

.fs-0 {
    font-size: 1rem
}

.fs-1 {
    font-size: 1.2rem
}

.fs-2 {
    font-size: 1.44rem
}

.fs-3 {
    font-size: 1.728rem
}

.fs-4 {
    font-size: 2.0736rem
}

.fs-5 {
    font-size: 2.48832rem
}

.fs-6 {
    font-size: 2.98598rem
}

.fs-7 {
    font-size: 3.58318rem
}

.fs-8 {
    font-size: 4.29982rem
}

@media(min-width: 576px) {
    .fs-sm--2 {
        font-size:.69444rem
    }

    .fs-sm--1 {
        font-size: .83333rem
    }

    .fs-sm-0 {
        font-size: 1rem
    }

    .fs-sm-1 {
        font-size: 1.2rem
    }

    .fs-sm-2 {
        font-size: 1.44rem
    }

    .fs-sm-3 {
        font-size: 1.728rem
    }

    .fs-sm-4 {
        font-size: 2.0736rem
    }

    .fs-sm-5 {
        font-size: 2.48832rem
    }

    .fs-sm-6 {
        font-size: 2.98598rem
    }

    .fs-sm-7 {
        font-size: 3.58318rem
    }

    .fs-sm-8 {
        font-size: 4.29982rem
    }
}

@media(min-width: 768px) {
    .fs-md--2 {
        font-size:.69444rem
    }

    .fs-md--1 {
        font-size: .83333rem
    }

    .fs-md-0 {
        font-size: 1rem
    }

    .fs-md-1 {
        font-size: 1.2rem
    }

    .fs-md-2 {
        font-size: 1.44rem
    }

    .fs-md-3 {
        font-size: 1.728rem
    }

    .fs-md-4 {
        font-size: 2.0736rem
    }

    .fs-md-5 {
        font-size: 2.48832rem
    }

    .fs-md-6 {
        font-size: 2.98598rem
    }

    .fs-md-7 {
        font-size: 3.58318rem
    }

    .fs-md-8 {
        font-size: 4.29982rem
    }
}

@media(min-width: 992px) {
    .fs-lg--2 {
        font-size:.69444rem
    }

    .fs-lg--1 {
        font-size: .83333rem
    }

    .fs-lg-0 {
        font-size: 1rem
    }

    .fs-lg-1 {
        font-size: 1.2rem
    }

    .fs-lg-2 {
        font-size: 1.44rem
    }

    .fs-lg-3 {
        font-size: 1.728rem
    }

    .fs-lg-4 {
        font-size: 2.0736rem
    }

    .fs-lg-5 {
        font-size: 2.48832rem
    }

    .fs-lg-6 {
        font-size: 2.98598rem
    }

    .fs-lg-7 {
        font-size: 3.58318rem
    }

    .fs-lg-8 {
        font-size: 4.29982rem
    }
}

@media(min-width: 1200px) {
    .fs-xl--2 {
        font-size:.69444rem
    }

    .fs-xl--1 {
        font-size: .83333rem
    }

    .fs-xl-0 {
        font-size: 1rem
    }

    .fs-xl-1 {
        font-size: 1.2rem
    }

    .fs-xl-2 {
        font-size: 1.44rem
    }

    .fs-xl-3 {
        font-size: 1.728rem
    }

    .fs-xl-4 {
        font-size: 2.0736rem
    }

    .fs-xl-5 {
        font-size: 2.48832rem
    }

    .fs-xl-6 {
        font-size: 2.98598rem
    }

    .fs-xl-7 {
        font-size: 3.58318rem
    }

    .fs-xl-8 {
        font-size: 4.29982rem
    }
}

@media(min-width: 1540px) {
    .fs-xxl--2 {
        font-size:.69444rem
    }

    .fs-xxl--1 {
        font-size: .83333rem
    }

    .fs-xxl-0 {
        font-size: 1rem
    }

    .fs-xxl-1 {
        font-size: 1.2rem
    }

    .fs-xxl-2 {
        font-size: 1.44rem
    }

    .fs-xxl-3 {
        font-size: 1.728rem
    }

    .fs-xxl-4 {
        font-size: 2.0736rem
    }

    .fs-xxl-5 {
        font-size: 2.48832rem
    }

    .fs-xxl-6 {
        font-size: 2.98598rem
    }

    .fs-xxl-7 {
        font-size: 3.58318rem
    }

    .fs-xxl-8 {
        font-size: 4.29982rem
    }
}

.fsp-75 {
    font-size: 75%
}

.font-weight-thin {
    font-weight: 100!important
}

.font-weight-extra-light {
    font-weight: 200!important
}

.font-weight-medium {
    font-weight: 500!important
}

.font-weight-semi-bold {
    font-weight: 600!important
}

.font-weight-extra-bold {
    font-weight: 800!important
}

.font-weight-black {
    font-weight: 900!important
}

.text-underline {
    text-decoration: underline;
    -webkit-text-decoration-skip: ink;
    text-decoration-skip-ink: auto
}

.text-smallcaps {
    font-variant: small-caps
}

.text-superscript {
    vertical-align: super
}

.text-word-break {
    word-break: break-word
}

.text-sans-serif {
    font-family: Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol
}

.text-base {
    font-family: open sans,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol
}

.text-100 {
    color: #f9fafd!important
}

a.text-100:focus,a.text-100:hover {
    color: #c0caea!important
}

.text-200 {
    color: #edf2f9!important
}

a.text-200:focus,a.text-200:hover {
    color: #b4c9e6!important
}

.text-300 {
    color: #d8e2ef!important
}

a.text-300:focus,a.text-300:hover {
    color: #a2bad9!important
}

.text-400 {
    color: #b6c1d2!important
}

a.text-400:focus,a.text-400:hover {
    color: #8799b5!important
}

.text-500 {
    color: #9da9bb!important
}

a.text-500:focus,a.text-500:hover {
    color: #70819c!important
}

.text-600 {
    color: #748194!important
}

a.text-600:focus,a.text-600:hover {
    color: #525b6a!important
}

.text-700 {
    color: #5e6e82!important
}

a.text-700:focus,a.text-700:hover {
    color: #3e4856!important
}

.text-800 {
    color: #4d5969!important
}

a.text-800:focus,a.text-800:hover {
    color: #2d343d!important
}

.text-900 {
    color: #344050!important
}

a.text-900:focus,a.text-900:hover {
    color: #161b22!important
}

.text-black {
    color: #000!important
}

a.text-black:focus,a.text-black:hover {
    color: #000!important
}

.text-1000 {
    color: #232e3c!important
}

a.text-1000:focus,a.text-1000:hover {
    color: #07090c!important
}

.text-1100 {
    color: #0b1727!important
}

a.text-1100:focus,a.text-1100:hover {
    color: #000!important
}

.text-white {
    color: #fff!important
}

a.text-white:focus,a.text-white:hover {
    color: #d9d9d9!important
}

.text-facebook {
    color: #3c5a99
}

.text-google-plus {
    color: #dd4b39
}

.text-twitter {
    color: #1da1f2
}

.text-linkedin {
    color: #0077b5
}

.text-youtube {
    color: red
}

.fs-error {
    font-size: 7rem
}

@media(min-width: 576px) {
    .fs-error {
        font-size:10rem
    }
}

.flex-center {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center
}

.flex-between-center {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between
}

.flex-end-center {
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center
}

.line-height-1 {
    line-height: 1
}

.line-height-0 {
    line-height: 0
}

.hover-actions {
    display: none;
    position: absolute
}

.hover-actions.show {
    display: -webkit-box;
    display: flex
}

.hover-actions.show+.hover-hide {
    display: none
}

.hover-actions-trigger {
    position: relative
}

.hover-actions-trigger .inbox-link {
    color: inherit;
    text-decoration: none
}

.hover-actions-trigger:focus .hover-actions,.hover-actions-trigger:hover .hover-actions {
    z-index: 1;
    display: -webkit-box;
    display: flex
}

.hover-actions-trigger:focus .hover-hide,.hover-actions-trigger:hover .hover-hide {
    display: none
}

.hover-shadow:focus,.hover-shadow:hover {
    box-shadow: 0 0 .5rem 0 rgba(0,0,0,.1)
}

.hover-100:focus,.hover-100:hover {
    background-color: #f9fafd!important
}

.hover-200:focus,.hover-200:hover {
    background-color: #edf2f9!important
}

.hover-300:focus,.hover-300:hover {
    background-color: #d8e2ef!important
}

.hover-400:focus,.hover-400:hover {
    background-color: #b6c1d2!important
}

.hover-500:focus,.hover-500:hover {
    background-color: #9da9bb!important
}

.hover-600:focus,.hover-600:hover {
    background-color: #748194!important
}

.hover-700:focus,.hover-700:hover {
    background-color: #5e6e82!important
}

.hover-800:focus,.hover-800:hover {
    background-color: #4d5969!important
}

.hover-900:focus,.hover-900:hover {
    background-color: #344050!important
}

.hover-black:focus,.hover-black:hover {
    background-color: #000!important
}

.hover-1000:focus,.hover-1000:hover {
    background-color: #232e3c!important
}

.hover-1100:focus,.hover-1100:hover {
    background-color: #0b1727!important
}

.hover-white:focus,.hover-white:hover {
    background-color: #fff!important
}

.img-landing-banner {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    overflow: hidden;
    -webkit-transform: translateY(-3rem);
    transform: translateY(-3rem);
    margin-bottom: -28rem;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease,-webkit-transform .4s ease;
    position: relative;
    box-shadow: 0 1rem 4rem rgba(0,0,0,.175);
    display: block
}

@media(min-width: 1200px) and (max-width:1539.98px) {
    .img-landing-banner {
        margin-bottom:-18rem
    }
}

@media(min-width: 992px) and (max-width:1199.98px) {
    .img-landing-banner {
        margin-bottom:-20rem
    }
}

.img-landing-banner:after {
    content: '';
    position: absolute;
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),to(rgba(0,0,0,.8)));
    background: -webkit-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,.8));
    background: linear-gradient(180deg,rgba(0,0,0,0),rgba(0,0,0,.8));
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70%;
    -webkit-transition: opacity .6s;
    transition: opacity .6s
}

.img-landing-banner:focus,.img-landing-banner:hover {
    -webkit-transform: translateY(-5rem);
    transform: translateY(-5rem)
}

.img-landing-banner:focus:after,.img-landing-banner:hover:after {
    opacity: 0
}

@media(max-width: 575.98px) {
    .landing-cta-img {
        width:100%;
        height: auto
    }
}

.btn-back-to-top {
    background-color: #0b1727;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-top-right-radius: .25rem;
    left: 50%;
    top: -7.5rem;
    -webkit-transform: translate3d(-50%,-50%,0) rotate(-45deg);
    transform: translate3d(-50%,-50%,0) rotate(-45deg);
    height: 2.5rem;
    width: 2.5rem
}

.card-span {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.card-span .card-span-img {
    position: absolute;
    left: 50%;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center
}

.card-span:focus,.card-span:hover {
    -webkit-transform: translateY(-.2rem);
    transform: translateY(-.2rem);
    box-shadow: 0 1rem 4rem rgba(0,0,0,.175)
}

.radio-select .custom-control-label {
    cursor: pointer
}

.radio-select-content {
    border: 1px solid #edf2f9;
    color: #9da9bb;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: block;
    padding: 1rem;
    font-weight: 400;
    border-radius: .25rem;
    margin-top: .5rem
}

.radio-select-link {
    color: inherit
}

.radio-select .custom-control-input:checked+.custom-control-label .radio-select-content {
    border: 1px solid #00d27a;
    color: #0b1727
}

.file-thumbnail {
    height: 2.25rem;
    width: 2.25rem
}

.dot {
    width: .625rem;
    height: .625rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: .5rem
}

.theme-wizard .nav-link {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    text-align: center;
    color: #748194;
    display: block
}

.theme-wizard .nav-link .nav-item-circle {
    color: #9da9bb;
    background-color: #fff;
    border: 2px solid #d8e2ef;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    line-height: 36px;
    padding: 0;
    text-align: center;
    align-self: center;
    display: block;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.theme-wizard .nav-link.active {
    color: #2c7be5
}

.theme-wizard .nav-link.active .nav-item-circle {
    background-color: #2c7be5!important;
    color: #fff;
    border-color: #2c7be5
}

.theme-wizard .nav-link.done {
    color: #00d27a
}

.theme-wizard .nav-link.done .nav-item-circle {
    background-color: #00d27a!important;
    color: #fff;
    border-color: #00d27a
}

.theme-wizard .nav-link.done .nav-item-circle .check-icon {
    display: inline-block
}

.theme-wizard .nav-link.done .nav-item-circle-parent:before {
    background-color: #00d27a
}

.theme-wizard .nav-link .nav-item-circle .check-icon {
    display: none
}

.theme-wizard .nav-link .nav-item-circle-parent {
    display: block
}

.theme-wizard .nav-link .nav-item-circle-parent:before {
    content: '';
    height: 2px;
    width: 101%;
    position: absolute;
    left: 51%;
    top: 50%;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
    background: #d8e2ef
}

.theme-wizard .nav-item:not(:first-child) .active .nav-item-circle-parent:after {
    content: '';
    width: 50%;
    position: absolute;
    left: -1px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 2px;
    background-color: #00d27a
}

.theme-wizard .nav-item-circle-parent {
    position: relative;
    padding: 0 1rem
}

.theme-wizard .nav-item-circle {
    margin: 0 auto;
    z-index: 1;
    position: relative
}

.theme-wizard .nav-item {
    display: -webkit-box;
    display: flex;
    flex-basis: auto;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center
}

.theme-wizard .nav-item:first-child .nav-item-circle-parent:before,.theme-wizard .nav-item:last-child .nav-item-circle-parent:before {
    width: 50%
}

.theme-wizard .nav-item:first-child .nav-item-circle-parent:before {
    -webkit-transform: translate3d(0,-50%,0);
    transform: translate3d(0,-50%,0)
}

.theme-wizard .nav-item:last-child .nav-item-circle-parent:before {
    left: 0;
    -webkit-transform: translate3d(0,-50%,0);
    transform: translate3d(0,-50%,0)
}

.theme-wizard .pager {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between
}

.theme-wizard .wizard-lottie-wrapper {
    height: 1px;
    position: relative;
    padding-bottom: 190px;
    overflow: hidden
}

.theme-wizard .wizard-lottie-wrapper .wizard-lottie {
    width: 340px;
    -webkit-transform: translateY(-73%);
    transform: translateY(-73%)
}

.TrackY {
    width: 7px!important;
    background-color: transparent!important
}

.TrackY .ScrollbarsCustom-ThumbY {
    background-color: rgba(116,129,148,.3)!important
}

.TrackX {
    height: 7px!important;
    background-color: transparent!important
}

.TrackX .ScrollbarsCustom-ThumbX {
    background-color: rgba(116,129,148,.3)!important
}

.contacts-list .ScrollbarsCustom-Wrapper {
    right: 0!important
}

.scrollbar {
    scrollbar-color: rgba(116,129,148,.3) transparent;
    scrollbar-width: thin;
    overflow: auto
}

.scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    background-color: transparent
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #b6c1d2;
}
.scrollbar::-webkit-scrollbar-track {
    background-color: transparent!important;
}

.firefox .dropcap:first-letter {
    margin-top: .175em
}

.edge .bg-holder .bg-video {
    height: 100%;
    width: auto
}

@media screen and (-ms-high-contrast:active),(-ms-high-contrast:none) {
    img {
        max-width: 100%
    }

    .card-img-top {
        height: 100%;
        overflow: hidden
    }

    .hoverbox-content img {
        width: 100%
    }

    .min-vh-100 {
        height: 100vh
    }

    .customer-name-column {
        min-width: 11.25rem
    }
}

.card-chat {
    height: calc(100vh - 4.3125rem - 10px);
    z-index: 1
}

.card-chat .card-body {
    position: relative;
    overflow: hidden
}

.card-chat-pane {
    height: calc(100% - 2.063rem);
    position: relative;
    overflow: hidden
}

.card-chat-content {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    height: 100%
}

.chat-sidebar {
    position: absolute;
    height: 100%;
    z-index: 11;
    top: 0;
    left: -100%;
    width: 100%;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    overflow: hidden
}

.contacts-list {
    height: calc(100% - 2rem);
    border-right: 1px solid #d8e2ef;
    overflow-y: auto;
    width: 100%
}

.chat-contact {
    -webkit-box-align: center;
    align-items: center;
    padding: 1rem;
    height: 4.188rem;
    cursor: pointer;
    border-top: 1px solid transparent;
    background-color: #fff
}

.chat-contact:after {
    position: absolute;
    content: '';
    height: 100%;
    width: .25rem;
    left: 0;
    top: 0
}

.chat-contact:first-child {
    border-top: 0!important
}

.chat-contact:focus:after,.chat-contact:hover:after {
    background-color: #00d27a
}

.chat-contact.active:after {
    background-color: #00d27a
}

.chat-contact.active+.chat-contact,.chat-contact.active:not(:first-child) {
    border-top: 1px solid #d8e2ef
}

.chat-contact-body {
    position: relative;
    min-width: 0
}

.chat-contact-title {
    font-weight: 400
}

.message-time {
    color: #b6c1d2
}

.chat-contact-content {
    font-size: .83333rem;
    color: #748194;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.unread-message {
    color: #000
}

.unread-message .chat-contact-content,.unread-message .chat-contact-title {
    font-weight: 600;
    color: #000
}

.unread-message .message-time {
    color: #2c7be5
}

.contacts-search-wrapper {
    position: relative;
    border-top: 1px solid #d8e2ef;
    border-right: 1px solid #d8e2ef;
    background-color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 2.063rem
}

.chat-contacts-search {
    padding-left: 2.12rem
}

.chat-contacts-search:focus,.chat-contacts-search:hover {
    box-shadow: none
}

.contacts-search-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #b6c1d2;
    font-size: .83333rem;
    left: 1rem
}

.chat-content-header {
    padding: 1rem;
    border-bottom: 1px solid #d8e2ef
}

.chat-content-body {
    height: calc(100% - 4.25rem)
}

.chat-content-body .scroll-content {
    position: relative
}

.chat-content-scroll-area {
    height: 100%;
    padding-bottom: 1rem;
    overflow-y: auto
}

.chat-message {
    max-width: calc(100% - 7rem);
    display: inline-block;
    position: relative
}

.message-settings {
    position: absolute;
    bottom: -.9375rem;
    border: 1px solid #edf2f9;
    background-color: #f9fafd;
    border-radius: 50%;
    height: 1.875rem;
    width: 1.875rem;
    text-align: center
}

.message-settings-left {
    right: -.9375rem
}

.message-settings-right {
    left: -.9375rem
}

.conversation-info {
    position: absolute;
    width: 15rem;
    right: -100%;
    background-color: #f9fafd;
    border-left: 1px solid #d8e2ef;
    z-index: 10;
    top: 4.25rem;
    height: calc(100% - 4.25rem);
    -webkit-transition: .5s;
    transition: .5s
}

.conversation-info.show {
    right: 0
}

.conversation-info .conversation-info-icon {
    width: 1.25rem;
    font-size: 1rem
}

.chat-file-upload {
    position: absolute;
    right: 5.5rem;
    z-index: 2;
    top: .125rem;
    color: #9da9bb
}

.chat-file-upload:focus,.chat-file-upload:hover {
    color: #748194
}

.emoji-icon {
    position: absolute;
    top: 8px;
    right: 64px;
    cursor: pointer;
    color: #9da9bb
}

.emoji-icon:focus,.emoji-icon:hover {
    color: #748194!important
}

.btn-message-submit {
    position: absolute;
    right: 3.938rem;
    top: .1875rem
}

.btn-send {
    position: absolute;
    top: .125rem;
    z-index: 2;
    right: 1rem
}

.btn-send {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    color: #9da9bb
}

.chat-editor-area {
    border-top: 1px solid #d8e2ef;
    position: absolute;
    bottom: 0;
    width: 100%
}

.chat-editor-area .emojionearea-editor {
    min-height: 2rem;
    padding-right: 7rem
}

.chat-editor-area .emojionearea-button {
    right: 3.75rem
}

@media(min-width: 576px) {
    .contacts-list {
        height:calc(100% - 2.063rem)
    }

    .conversation-scroll-area {
        height: calc(100% - 2.188rem)
    }

    .card-chat {
        height: calc(100vh - 4.3125rem - 63.8px)
    }
}

@media(min-width: 768px) {
    .chat-sidebar {
        position:relative;
        height: 100%;
        top: 0;
        left: 0!important;
        width: auto;
        -webkit-transition: none;
        transition: none
    }
}

@media(min-width: 992px) {
    .chat-sidebar {
        -webkit-box-flex:0;
        flex: 0 0 17.5rem;
        max-width: 17.5rem
    }
}

@media(min-width: 1540px) {
    .chat-sidebar {
        -webkit-box-flex:0;
        flex: 0 0 21.88rem;
        max-width: 21.88rem
    }
}

.safari .contacts-list {
    height: calc(100% - 2.063rem)
}

.safari .contacts-search-wrapper {
    height: 2.125rem
}

.ie .chat-contact {
    height: 4.25rem
}

.ie .chat-gallery {
    width: calc(100% - 7rem)
}

.ie .conversation-info {
    top: 4.313rem
}

.edge .chat-contact {
    height: 4.25rem
}

.edge .conversation-info {
    top: 4.313rem
}

.chat-option-hover {
    -webkit-transition: .2s;
    transition: .2s
}

.chat-option-hover:focus,.chat-option-hover:hover {
    color: #2c7be5
}

.btn-accordion {
    padding: .25rem 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    font-size: .83333rem;
    font-weight: 500
}

.btn-accordion[aria-expanded=true] [data-prefix=fas] {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.accordion-icon {
    -webkit-transition: -webkit-transform .1s linear;
    transition: -webkit-transform .1s linear;
    transition: transform .1s linear;
    transition: transform .1s linear,-webkit-transform .1s linear;
    color: #344050
}

[aria-expanded=true] .accordion-icon {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.icon-spin {
    -webkit-animation: icon-spin 6s cubic-bezier(.15,1.15,.65,1) infinite;
    animation: icon-spin 6s cubic-bezier(.15,1.15,.65,1) infinite
}

.settings-popover .d-flex {
    -webkit-transform-origin: center;
    transform-origin: center;
    transform-box: fill-box
}

.ripple {
    position: absolute
}

.ripple .fa-spin {
    -webkit-animation-duration: 3s;
    animation-duration: 3s
}

.ripple:after,.ripple:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 3px solid rgba(0,210,122,.4);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: -1;
    -webkit-animation: ripple 2s ease infinite;
    animation: ripple 2s ease infinite
}

.ripple:before {
    border: 5px solid rgba(0,210,122,.3);
    -webkit-animation-delay: 5s;
    animation-delay: 5s
}

@-webkit-keyframes ripple {
    0% {
        height: 16px;
        width: 16px
    }

    100% {
        height: 50px;
        width: 50px;
        border-color: transparent
    }
}

@keyframes ripple {
    0% {
        height: 16px;
        width: 16px
    }

    100% {
        height: 50px;
        width: 50px;
        border-color: transparent
    }
}

@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.modal-fixed-right {
    padding-right: 0!important
}

.modal-fixed-right .modal-dialog {
    margin: 0;
    margin-left: auto;
    margin-right: initial;
    max-width: 350px
}

.modal-fixed-right.fade .modal-dialog-vertical {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.modal-fixed-right.show .modal-dialog-vertical {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.modal-header-settings {
    min-height: 69px;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border: none;
    border-radius: 0;
    padding: .5rem 1.25rem;
    background-image: -webkit-linear-gradient(135deg,#4695ff,#1970e2);
    background-image: linear-gradient(-45deg,#4695ff,#1970e2);
    overflow: hidden
}

.modal-header-settings:after,.modal-header-settings:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    height: 12.5rem;
    width: 12.5rem;
    background-image: -webkit-linear-gradient(45deg,#318aff,#247cef);
    background-image: linear-gradient(45deg,#318aff,#247cef)
}

.modal-header-settings:after {
    left: 5.125rem;
    top: 1.188rem
}

.modal-header-settings:before {
    right: -6.25rem;
    top: -7.938rem
}

.modal-header-settings .close {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    opacity: .75;
    padding-top: .75rem;
    margin-top: 0;
    margin-right: .25rem;
    outline: 0;
    font-weight: 300
}

.modal-header-settings .close:focus:not(.disabled),.modal-header-settings .close:focus:not(:disabled),.modal-header-settings .close:hover:not(.disabled),.modal-header-settings .close:hover:not(:disabled) {
    opacity: 1
}

.modal-header-settings .modal-title {
    z-index: 1
}

.theme-modal {
    border: 0
}

.kanban-container {
    padding-bottom: .25rem;
    white-space: nowrap
}

.kanban-column {
    display: inline-block!important;
    width: 22.1875rem;
    white-space: normal;
    vertical-align: top;
    margin-right: 1rem
}

.kanban-column:not(.form-added) .add-card-form {
    display: none
}

.kanban-column:not(:first-child) {
    margin-left: -.375rem
}

.kanban-column-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background-color: #f9fafd;
    padding: .5rem 1.25rem;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
}

.kanban-column-footer {
    background-color: #f9fafd;
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem;
    padding: .5rem 1.25rem
}

.kanban-items-container {
    outline: 0;
    padding: .5rem 1.25rem;
    max-height: calc(100vh - 14.9375rem);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f9fafd
}

.kanban-items-container .kanban-collapse:first-child,.kanban-items-container .kanban-item:first-child {
    margin-top: 0!important
}

.kanban-item {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    margin-top: 1rem
}

.kanban-item-card {
    border: 0;
    color: #344050;
    font-size: .83333rem;
    background-color: #fff;
    border-radius: .375rem;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)
}

.kanban-item-footer {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: .69444rem;
    margin-top: 1rem;
    color: #748194
}

.form-added .kanban-items-container {
    max-height: calc(100vh - 12.125rem);
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.form-added .kanban-column-footer {
    display: none
}

.btn-add-card:focus,.btn-add-card:hover {
    background-color: #edf2f9
}

.bg-attachment {
    position: relative;
    height: 3.5625rem;
    width: 6.25rem;
    border-radius: .25rem;
    overflow: hidden;
    background: #d8e2ef;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #748194
}

.bg-attachment .icon-play {
    position: relative;
    color: #edf2f9;
    z-index: 111
}

.bg-attachment:focus,.bg-attachment:hover {
    color: #5e6e82!important
}

.bg-attachment:focus .icon-play,.bg-attachment:hover .icon-play {
    color: #d8e2ef
}

.avatar-group .avatar {
    vertical-align: top
}

.draggable--is-dragging * {
    cursor: -webkit-grab!important;
    cursor: grab!important
}

.draggable-source--is-dragging {
    background-color: #edf2f9!important;
    box-shadow: none!important;
    border-radius: .375rem
}

.draggable-source--is-dragging * {
    opacity: 0
}

.draggable-mirror {
    z-index: 1100
}

.draggable-mirror .kanban-item-card {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg)
}

button.close-circle {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #edf2f9;
    text-shadow: none;
    border-radius: 50%;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    opacity: 1
}

button.close-circle:focus,button.close-circle:hover {
    opacity: 1!important;
    background-color: #d8e2ef
}

.icon-title {
    width: 1.25rem;
    text-align: center;
    margin-right: 1rem
}

.draggable--original:first-child+.kanban-item,div[data-collapse]+.kanban-item {
    margin-top: 0
}

.nav-link-card-details {
    background-color: #f9fafd;
    color: #5e6e82;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: .25rem;
    margin-bottom: .25rem
}

.nav-link-card-details:focus,.nav-link-card-details:hover {
    background-color: #edf2f9;
    color: #5e6e82
}

.ipad .kanban-items-container,.mobile.chrome .kanban-items-container,.mobile.safari .kanban-items-container {
    max-height: calc(100vh - 18.4375rem)
}

.ipad .form-added .kanban-items-container,.mobile.chrome .form-added .kanban-items-container,.mobile.safari .form-added .kanban-items-container {
    max-height: calc(100vh - 15.625rem)
}

.notice {
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 0!important;
    z-index: -1;
    border: 0;
    width: 100%;
    max-width: 100%;
    box-shadow: 0 1rem 4rem rgba(0,0,0,.175);
    border-radius: 0
}

.notice.show {
    z-index: 1040
}

.notice .close {
    text-shadow: none!important
}
body.login { background-image: url(/login-bg.jpg); background-size: 100%; background-repeat: no-repeat; background-color: #f7f7f7!important;}
.login-wrapper {margin: 5% 10%; width: fit-content;}
amplify-authenticator, .login-header {display: none;}
.login-header { margin: 0px 0 30px;}
body.login amplify-authenticator, body.login .login-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
}
.brand {color: #2c7be5; font-size: 2rem; font-weight: 800;}
/* .vnav {background-color: transparent;} */
.navbar-brand {font-size: 1.5rem;}
.vnav .nav-link {font-size: 1.2rem!important; font-weight: 500!important; padding-left: .5rem; padding-right: .5rem;}
.navbar-vertical-collapsed .navbar-collapse .nav-item .nav-link {display: none;}
.navbar-vertical-collapsed.navbar-vertical-collapsed-hover .navbar-collapse .nav-item .nav-link {display: block;}
.navbar-vertical-collapsed .navbar-collapse .nav-item {margin-top: 5px;}
.rdt_TableCol {font-weight: 800!important;}
.rdt_TableCol > div > div {white-space: break-spaces!important; line-height: 16px!important;font-weight: 800!important;}
.rdt_TableRow .btn-sm {padding-left: .45rem!important; padding-right: .45rem!important;}
/* .rdt_TableRow {min-height: inherit; padding: 8px 0;} */
.modal-25w { width: 22%; max-width: none!important; }
.modal-65w, .w-65 { width: 65%; max-width: none!important; }
.modal-70w, .w-70 { width: 70%; max-width: none!important; }
.modal-75w, .w-75 { width: 75%; max-width: none!important; }
.modal-80w { width: 80%; max-width: none!important; }
.modal-85w { width: 85%; max-width: none!important; }
.modal-90w { width: 90%; max-width: none!important; }
.page {margin-top: 3px;}
.page-modal + .modal-backdrop {z-index: 9991;}
.page-modal.page-2, .alert-modal {z-index: 9991;}
.page-modal.page-3, .alert-modal {z-index: 9999;}
.page-modal {height: 100vh;}
.page-modal .modal-dialog {position: absolute; right: 0; top: 0; margin: 0;}
.page-modal.reverse .modal-dialog {position: absolute; left: -22%; top: 0; margin: 0; right: initial;}
.page-modal .modal-content {height: 93vh; border-radius: 0; height: 100vh;}
.page-modal .modal-content { background-color: #f1f3f4}
.page-modal .modal-content .modal-body {overflow-y: auto;}
.page-modal .modal-header, .page-modal .modal-footer {background-color: white;}
.page-modal.modal.fade .modal-dialog { transform: translateX(180px); }
.page-modal.reverse.modal.fade .modal-dialog { transform: translateX(0%); }
.page-modal.modal.show .modal-dialog { transform: none!important; }
.page-modal.reverse.modal.show .modal-dialog { transform: translateX(100%)!important; }
.page-modal .cdNum {font-weight: 800; font-size: 18px;}
.page-modal .cdName {font-weight: 400; font-size: 18px;}
.modal-footer {padding: 0.3rem .75rem!important; box-shadow: 0px -5px 5px 0px #cacaca4f; z-index: 9999;}
.tab-content {padding: 10px 0;}
.page-height {max-height: calc(100vh - 87px);}
.page-height .rdt_Table {max-height: calc(100vh - 277px)!important}
.page-height.no-table-header .rdt_Table {max-height: calc(100vh - 234px)!important}
.page-height .rdt_ExpanderRow .rdt_Table {max-height: calc(100vh - 618px)!important;}
.page-height.page-ctrl {max-height: calc(100vh - 200px);}
.page-height.page-ctrl-2 {max-height: calc(100vh);}
.page-height.page-ctrl .rdt_Table {max-height: calc(100vh - 345px)!important}
.page-height.page-ctrl-2 .rdt_Table {max-height: calc(100vh - 250px)!important}
.page-height.page-ctrl-2.extanded-filter .rdt_Table {max-height: calc(100vh - 300px)!important}
.page-height.page-ctrl-2 .rdt_Pagination {margin-bottom: -15px!important;}
.page .rows-total {position: absolute; bottom: 15px;}
.page-modal .rdt_TableBody {max-height: calc(100vh - 307px);}
.page-modal .tab-content {padding-bottom: 0px; max-height: calc(100vh - 120px); overflow-y: auto;}
.page-modal .has-footer .tab-content {max-height: calc(100vh - 157px)!important;}
.page-modal .tab-content .card {margin-bottom: 1rem!important;}
.multiselect { max-height: 310px; overflow-y: auto; }
.multiselect .list-group-item, .narrow .list-group-item {padding: .4rem 1.25rem!important; font-size: 13.5px;}
.att.list-group { max-height: calc(100vh - 368px); overflow-y: auto;}
.bg-transit {transition: all 0.5s ease;}
.bg-patent {background: #ffffff;}
.bg-mark {background: rgb(213,238,255);}
.bg-other {background: rgb(255,249,213);}
.bg-sent {background-color: #A7D2FF;}
.bg-received {background-color: rgb(253 191 113);}
.color-sent {color: #A7D2FF;}
.color-received {color: rgb(253 191 113);}
.color-sent:hover {background-color: #A7D2FF; color: white;}
.color-received:hover {background-color: rgb(253 191 113); color: white;}
.card .card-body {transition: height 0.3s ease-in;}
.badge {height: fit-content;}
.btn.xs {width: 20px; height: 20px; padding: 0; font-size: 12px;}
.semi-bold {font-weight: 600;}
.c-legend {width: 20px;height: 20px;display: inline-block;}
.rem-cmp { background-color: #e5e5e5;}
.select-btn {position: relative; top: 36px;}
.line-btn {position: relative; top: 25px;}
.rdt_ExpanderRow {background-color: #f9fafd!important;}
.floating-alert {display: none; position: fixed; width: max-content; right: 3%; top: 0px; transition: top 0.2s;}
.floating-alert.in {display: block; top: 5px; transition: top 0.2s; z-index: 9999;}
.side-alert {position: absolute; width: max-content; right: -100%; z-index: 9999; transition: right 0.3s ease-in; top: 10px;}
.side-alert.in {right: -7px; transition: right 0.3s ease-in; display: block;}
.side-alert.out {right: -100%; transition: right 0.3s ease-out;}
.add-menu h5 {font-size: 1.1rem;}
.add-link { -webkit-box-align: center; align-items: center; border-radius: 3px; box-sizing: border-box; cursor: pointer; font-weight: 600;
            background-color: rgb(255, 255, 255); fill: rgb(255, 255, 255); text-decoration: none; padding: 10px 8px; width: auto;}
.add-link a {color: #232e3c; text-decoration: none;}
.add-link a:hover { text-decoration: none!important;}
.add-link:hover { background-color: rgb(210 213 218); color: rgb(23, 43, 77); fill: rgb(235, 236, 240); text-decoration: none;}
.btn-light-border { border-color: #0000000d; }
.btns-menu:hover {background: #ffffff!important; cursor: default}
.valid {border-color: #198754; box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 25%);}
/* width */
::-webkit-scrollbar {width: 8px; height: 8px;}
/* Track */
::-webkit-scrollbar-track {background: #f1f1f1; }
/* Handle */
::-webkit-scrollbar-thumb {background: #777777; border-radius: 10px;}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {background: #888; }


@media(min-height: 720px) {
    .login-wrapper {margin: 2% 10%!important;}
}
@media(max-height: 760px) {
    .page-height .rdt_ExpanderRow .rdt_Table {max-height: calc(100vh - 28rem)!important;}
}
@media(max-width: 768px) {
    .login-wrapper {margin: 0 auto!important;}
}


.wrapper {
    position: relative;
    /* margin: 40px auto; */
    background: #d5e5fa;
  }

  .wrapper, .wrapper * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .wrapper {
    width: 15px;
    height: 15px;
  }

  .wrapper .pie {
    width: 50%;
    height: 100%;
    transform-origin: 100% 50%;
    position: absolute;
    background: #d8d8d8;
    border: 2px solid rgba(0,0,0,0.5);
  }

  .wrapper .spinner {
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
    z-index: 200;
    border-right: none;
    animation: rota 5s linear infinite;
  }

  .wrapper:hover .spinner,
  .wrapper:hover .filler,
  .wrapper:hover .mask {
    animation-play-state: running;
  }

  .wrapper .filler {
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    left: 50%;
    opacity: 0;
    z-index: 100;
    animation: opa 5s steps(1, end) infinite reverse;
    border-left: none;
  }

  .wrapper .mask {
    width: 50%;
    height: 100%;
    position: absolute;
    background: inherit;
    opacity: 1;
    z-index: 300;
    animation: opa 5s steps(1, end) infinite;
  }

  @keyframes rota {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes opa {
    0% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
  }